export const PAYMENT_DESC = [
	{ id: 0, desc: "We notice you're interested in accessing our premium functionality. This is available exclusively to our Premium subscribers." },
	{
		id: 1,
		desc: "We notice you're interested in accessing our Community functionality which allows for you to connect with Mentors who can provide invaluable guidance and support throughout your startup journey. This is currently available exclusively to our Premium subscribers.",
	},
	{
		id: 2,
		desc: "We notice you're interested in accessing our Messaging functionality which allows for you to directly message Mentors and Investors on the StartupOS platform. This is currently available exclusively to our Premium subscribers.",
	},
	{
		id: 3,
		desc: "We notice you're interested in accessing our Meeting functionality which allows for you to schedule meetings with Mentors that can provide invaluable guidance and support throughout your startup journey. This is currently available exclusively to our Premium subscribers.",
	},
];

export const FREE = {
	id: 1,
	title: "Basic Plan",
	description: "For startups exploring the platform and accessing foundational resources to grow their business.",
	amountMonth: "Free",
	amountYear: "Free",
	showPerTag: false,
	subscriptionButton: "Free Access",
	subscriptionButtonActive: false,
	items: [
		{
			title: "Marketplace Partner Offers: ",
			description: "Access exclusive deals for essential back-office solutions like legal, accounting, and branding.",
		},
		{ title: "AI PowerUp Library: ", description: "Learn by Doing with exercises designed to help you master startup fundamentals." },
		{ title: "StartupOS Network Access: ", description: "Connect with a network of founders, mentors, and advisors." },
		{ title: "Startup Grid Dashboard: ", description: "Monitor key metrics, track performance, and benchmark your progress." },
	],
};

export const PREMIUM = {
	id: 2,
	title: "Premium Plan",
	description: "For startups actively raising capital, offering advanced tools and exclusive support to accelerate funding efforts.",
	amountMonth: "$99",
	amountYear: "$950",
	showPerTag: true,
	subscriptionButton: "Subscribe Now",
	subscriptionButtonActive: true,
	items: [
		{ title: "Everything included in the Basic Plan, Plus...", description: "" },
		{
			title: "Unlimited AI-Powered Pitch Deck Analyses: ",
			description: "Optimize your deck with unlimited expert insights to improve investor readiness.",
		},
		{
			title: "Exclusive Founder Community Access: ",
			description: "Join an invite-only community of high-performing founders, mentors, and investors.",
		},
		{
			title: "Weekly Office Hours with StartupOS: ",
			description: "Get personalized guidance and problem-solving strategies in live sessions with our team.",
		},
	],
};

export const PITCHDECK = {
	id: "pitch_deck",
	title: "AI Pitch Deck Analysis",
	description: "For startups startups looking to quickly analyze their pitch deck and assess fundraising readiness.",
	amountMonth: "$49",
	amountYear: "$49",
	showPerTag: false,
	showOneTime: true,
	subscriptionButton: "One Time Analysis",
	subscriptionButtonActive: true,
	items: [
		{
			title: "Slide-by-slide analysis of your pitch deck",
			description: "",
		},
		{ title: "Powered by AI, receive an assessment of your decks readiness to attract funding.", description: "" },
		{ title: "Tailored tips to improve critical elements of your deck.", description: "" },
		{ title: "Insights based on 20+ years of expertise in startup pitching and fundraising.", description: "" },
	],
};

export const PAYMENT_PLANS = [FREE, PREMIUM];
