import React, { useState, useEffect, useContext, Suspense, useRef, Fragment } from "react";
import { Box, Button, CircularProgress, Container } from "@mui/material";
import HeroCard from "./components/cards/HeroCard/HeroCard";
import services from "../../services";
import { USER_TYPES } from "../../utils/userTypes";
import ScheduleMeetingDialog from "../../components/Networking/ScheduleMeetingDialog";
import { useDispatch } from "react-redux";
import { setNetworkingDashboardData } from "../../modules/actions/networkingActions";
import MessageDialog from "../../components/Networking/MessageDialog";
import ROUTES_PATH from "../../constants/routes";
import { useHistory } from "react-router-dom";
import PowerPackDetails from "./PowerPackDetails";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import { BreadcrumbsComponent } from "../../components/Breadcrumbs";
import BoltPDAnalysisComposition from "./compositions/bolt-ai-pd-analysis";

// PowerUPContext to provide state and methods to sub-components
export const PowerPackContext = React.createContext();

export const PowerPackController = () => {
	// State and methods for controlling sub-components
	const currentPath = window.location.pathname;
	const parts = currentPath.split("/");
	const packId = parts[parts.length - 1];
	const [packDetail, setPackDetail] = useState({});
	const [powerUpList, setPowerUpList] = useState([]);
	const [topMentorList, setTopMentorList] = useState([]);
	const [openScheduleMeetingDialog, setOpenScheduleMeetingDialog] = useState({ open: false, userInfo: null, type: null });
	const [powerPackLoader, setPowerPackLoader] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		getPackDetailInfoById(packId);
		getTopMentorList();
	}, []);

	const getPackDetailInfoById = async (packId) => {
		try {
			setPowerPackLoader(true);
			const packDetails = await services.getPowerUpPackDetail(packId);
			const packItemsInfo = await services.getToDosList(packId);
			if (packDetails) {
				setPowerPackLoader(false);
			}
			setPowerUpList(packItemsInfo?.data?.data);
			setPackDetail(packDetails?.data?.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getTopMentorList = async () => {
		try {
			const response = await services.getNetworkMatchApi({
				page: 1,
				perPage: 5,
				userTypes: USER_TYPES.MENTOR_USER,
			});
			setTopMentorList(response?.data?.data?.models);
		} catch (error) {
			throw error?.response?.data ?? { error: "Unable to successfully retrieve top mentor list." };
		}
	};
	const handleCloseScheduleMeetingDialog = () => {
		setOpenScheduleMeetingDialog({ open: false, userInfo: null, type: null });
	};

	const onCalendarClick = (selectedUser) => {
		setOpenScheduleMeetingDialog({ open: true, userInfo: selectedUser, type: selectedUser?.userTypes?.[0] });
	};
	const allMentorRedirection = () => {
		handleMixPanel("Mentors Clicked");
		dispatch(
			setNetworkingDashboardData({
				currentTab: USER_TYPES.MENTOR_USER,
			})
		);
		history.push(ROUTES_PATH.NETWORK);
	};

	const onMessageClick = async (mentorDetails) => {
		dispatch(
			setNetworkingDashboardData({
				messageWithMatch: mentorDetails,
				openMessageDialog: true,
			})
		);
	};

	const PowerPackContainer = () => {
		return (
			<Container sx={{ p: 3 }}>
				<Box pb={4}>
					<BreadcrumbsComponent
						bedcrumDetails={[
							{ title: "Home", route: "/" },
							{ title: packDetail?.powerUpPackName || "", route: "" },
						]}
					/>
				</Box>

				{typeof packId == "string" && packId?.startsWith("pitch-deck-analysis-by-bolt-ai") ? (
					<BoltPDAnalysisComposition />
				) : (
					<Fragment>
						<HeroCard />
						<Box
							sx={{
								my: "2.5rem",
							}}
						>
							<PowerPackDetails />
						</Box>
					</Fragment>
				)}
			</Container>
		);
	};

	return (
		<PowerPackContext.Provider
			value={{
				packDetail,
				powerUpList,
				packId,
				topMentorList,
				onCalendarClick,
				onMessageClick,
				allMentorRedirection,
			}}
		>
			{powerPackLoader ? (
				<Box
					sx={(theme) => ({
						display: "flex",
						justifyContent: "center",
					})}
				>
					<CircularProgress
						sx={(theme) => ({
							height: "80px",
							width: "80px",
							color: theme.palette.primary.primaryPurple,
						})}
					/>
				</Box>
			) : (
				<PowerPackContainer />
			)}
			{openScheduleMeetingDialog?.open && openScheduleMeetingDialog?.userInfo && (
				<ScheduleMeetingDialog
					open={openScheduleMeetingDialog?.open}
					title="Community"
					handleClose={handleCloseScheduleMeetingDialog}
					userInfo={openScheduleMeetingDialog?.userInfo}
					withUserType={openScheduleMeetingDialog?.type}
				/>
			)}
		</PowerPackContext.Provider>
	);
};
