import { DialogTitle, Dialog, IconButton, Typography, Divider, DialogContent, DialogActions, Slide, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment } from "react";
import theme from "../../constants/Theme";
import { Card as PowerUpCard } from "../../containers/PowerPack/components/cards/Card";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const NoTransition = React.forwardRef(function Transition(props, ref) {
	return <Box direction="left" ref={ref} {...props} />;
});
export default function CommonSlideDialog({
	isOpen,
	onClose,
	title,
	children,
	dialogAction,
	titleVariant,
	contentStyle,
	footerStyle,
	titleStyle,
	closeIconStyle,
	disableBackDropClick,
	transitionDuration = 600,
	closeButton = true,
	addtlCloseComp,
	slidingEffect = true,
	inline = false,
	...props
}) {
	const handleClose = () => {
		onClose();
	};

	const titleComp = (
		<Fragment>
			<Typography sx={{ lineHeight: "initial" }} variant={titleVariant}>
				{title}
			</Typography>
			{closeButton && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								color: theme.palette.secondary.dark,
								...closeIconStyle,
							}}
						>
							<CloseIcon sx={{ color: (theme) => theme.palette.secondary.black }} />
						</IconButton>
					</Box>
					{addtlCloseComp}
				</Box>
			)}
		</Fragment>
	);

	return (
		<Fragment>
			{inline ? (
				<PowerUpCard
					sxOverride={{
						boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					}}
				>
					{titleComp}
					{children}
				</PowerUpCard>
			) : (
				<Dialog
					//TransitionComponent={slidingEffect ? Transition : NoTransition}
					//transitionDuration={transitionDuration}
					//aria-describedby="alert-dialog-slide-description"
					//keepMounted
					open={isOpen}
					onClose={disableBackDropClick ? () => {} : onClose}
					scroll={props?.scroll_type ? props?.scroll_type : "paper"}
					//PaperProps={{ sx: { position: "fixed", top: 50, left: 50, m: 0 } }}
					{...props}
				>
					{title && (
						<DialogTitle
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								...titleStyle,
							}}
						>
							{titleComp}
						</DialogTitle>
					)}
					<DialogContent
						sx={{
							...contentStyle,
						}}
					>
						{children}
					</DialogContent>
					{dialogAction && (
						<DialogActions
							sx={{
								...footerStyle,
							}}
						>
							{dialogAction}
						</DialogActions>
					)}
				</Dialog>
			)}
		</Fragment>
	);
}
