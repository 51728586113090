import React, { useState, useRef, useEffect, Fragment } from "react";
import { Button, Popover, Box, Typography, Avatar, Popper, Tooltip, TextField, Dialog, Drawer } from "@mui/material";
import { Input } from "react-chat-elements";
import { getBoltChatHistory, updateBoltChatHistory } from "../../utils/sessionStorage";
import { BoltTyping, chatGroup } from "../../pages/ResourceCenter/AskBolt/BoltHistory";
import { UPDATED_LOGO_ICON_NEW } from "../../constants/image";
import axios from "axios";

import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector } from "react-redux";
import BoltLoader from "../../pages/ResourceCenter/AskBolt/BoltLoader";
import { OpenInFull, SettingsEthernet } from "@mui/icons-material";
import { CSSTransition } from "react-transition-group";
import jwtDecode from "jwt-decode";

const IS_LOCAL_MODE = false;

function BoltChat({ isDesktop }) {
	const authDetails = useSelector((state) => state?.auth);
	const [anchorEl, setAnchorEl] = useState(null);
	const [message, setMessage] = useState("");
	const [inputKey, setInputKey] = useState(Date.now());
	const messageListRef = useRef(null); // Ref for the message list container
	const [currentBoltHistory, setcurrentBoltHistory] = useState(null);

	const [searchLoading, setSearchLoading] = useState(false);
	const [boltResponse, setBoltResponse] = useState("");
	const idToken = useSelector((store) => store?.auth?.idToken);
	const [socketUrl, setSocketUrl] = useState(null);
	const [connectionError, setConnectionError] = useState(false);
	const [isExpanded, setisExpanded] = useState(false);

	const [feedback_open, setfeedback_open] = useState({ open: false });
	const decoded = jwtDecode(authDetails?.idToken);

	const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, { share: true });

	const [boltThreadId, setboltThreadId] = useState("");

	const connectionStatus = {
		[ReadyState.CONNECTING]: "Connecting",
		[ReadyState.OPEN]: "Open",
		[ReadyState.CLOSING]: "Closing",
		[ReadyState.CLOSED]: "Closed",
		[ReadyState.UNINSTANTIATED]: "Uninstantiated",
	}[readyState];

	const prevwebsocketState = useRef(readyState);

	useEffect(() => {
		if (!currentBoltHistory) {
			setcurrentBoltHistory(getBoltSessionHistory());
		}
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const scrollToBottom = (smooth) => {
		if (messageListRef.current) {
			const { scrollHeight } = messageListRef.current;
			messageListRef.current.scrollTo({
				top: scrollHeight,
				behavior: smooth ? "smooth" : "auto",
			});
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? "chat-popover" : undefined;

	useEffect(() => {
		// Scroll to the bottom of the message list smoothly whenever messages change
		scrollToBottom(true);
	}, [currentBoltHistory]);

	useEffect(() => {
		let connectionTimeout;

		if (readyState === ReadyState.CONNECTING) {
			// Set a timeout for 5 seconds when the state is CONNECTING
			connectionTimeout = setTimeout(() => {
				if (readyState === ReadyState.CONNECTING) {
					// If still CONNECTING after 5 seconds, consider it an error
					setConnectionError(true);
				}
			}, 5000); // 5 seconds
		} else {
			// If the state changes from CONNECTING, clear the timeout
			clearTimeout(connectionTimeout);

			if (readyState !== ReadyState.CONNECTING && prevwebsocketState.current === ReadyState.CONNECTING) {
				scrollToBottom(false);
			}
		}

		prevwebsocketState.current = readyState;

		return () => clearTimeout(connectionTimeout); // Cleanup on unmount or when the effect re-runs
	}, [readyState]);

	useEffect(() => {
		if (searchLoading) {
			setTimeout(() => {
				scrollToBottom(true);
			}, 200);
		}
	}, [searchLoading]);

	useEffect(() => {
		if (open) {
			if (readyState === ReadyState.CLOSED || readyState === ReadyState.UNINSTANTIATED)
				setSocketUrl(IS_LOCAL_MODE ? "" : `${process.env.REACT_APP_BOLT_WEBSOCKET_BASE}?access_token=Bearer%20${idToken}`);
			setTimeout(() => {
				scrollToBottom(false);
			}, 200);
		} else {
			setSocketUrl(null);
		}
	}, [open]);

	const handleSendMessage = () => {
		if (message.trim()) {
			setBoltResponse("");
			let payload = {
				userRequest: message,
				timestamp: new Date(),
			};
			let prevItems = extractLastTwoValues(currentBoltHistory);
			let updatedHistory = updateBoltSessionHistory(payload);
			setcurrentBoltHistory(updatedHistory);
			handleSearch(message, prevItems);
			setMessage("");
			setInputKey(Date.now()); // Change the key to force re-render
		}
	};

	const getBoltSessionHistory = () => {
		return getBoltChatHistory();
	};

	const updateBoltSessionHistory = (data) => {
		updateBoltChatHistory(data);
		return getBoltSessionHistory();
	};

	useEffect(() => {
		if (boltResponse) {
			console.log("RECEIVED:", boltResponse);
			let content = "";
			let thread_id = "";
			let meta_obj = {};
			if (boltResponse?.content && typeof boltResponse?.content == "string") content = boltResponse?.content;
			else if (boltResponse?.response?.content && typeof boltResponse?.response?.content == "string") content = boltResponse?.response?.content;

			if (boltResponse?.thread_id && typeof boltResponse?.thread_id == "string") thread_id = boltResponse?.thread_id;
			else if (boltResponse?.response?.thread_id && typeof boltResponse?.response?.thread_id == "string") thread_id = boltResponse?.response?.thread_id;

			if (boltResponse?.response_metadata && typeof boltResponse?.response_metadata == "object") meta_obj = boltResponse?.response_metadata;
			else if (boltResponse?.response?.response_metadata && typeof boltResponse?.response?.response_metadata == "object")
				meta_obj = boltResponse?.response?.response_metadata;

			setboltThreadId(thread_id);

			let payload = {
				boltResponse: {
					output: content,
					meta_payload: meta_obj,
				},
				timestamp: new Date(),
				action_type: boltResponse?.action_type,
				raw_payload: boltResponse,
			};
			let updatedHistory = updateBoltSessionHistory(payload);
			setcurrentBoltHistory(updatedHistory);
			setBoltResponse("");
		}
	}, [boltResponse]);

	function extractLastTwoValues(arr) {
		// Extract the last two elements from the array
		const lastTwo = arr?.slice(-2);

		// Initialize response object
		let response = {
			userRequest: "",
			boltResponse: "",
		};

		// Process the last two elements
		lastTwo?.forEach((item) => {
			if (item.userRequest !== undefined) {
				response.userRequest = item.userRequest;
			}
			if (item.boltResponse !== undefined) {
				response.boltResponse = item.boltResponse;
			}
		});

		// If the last two are of the same type, keep only the last one's value
		if (lastTwo?.length === 2 && lastTwo[0].hasOwnProperty("userRequest") && lastTwo[1].hasOwnProperty("userRequest")) {
			response.userRequest = lastTwo[1].userRequest;
		}
		if (lastTwo?.length === 2 && lastTwo[0].hasOwnProperty("boltResponse") && lastTwo[1].hasOwnProperty("boltResponse")) {
			response.boltResponse = lastTwo[1].boltResponse;
		}

		return response;
	}

	const reconnectAndSendWithTimeout = (payload, maxWaitTime) => {
		return new Promise((resolve, reject) => {
			let isAttemptResolved = false;

			// Attempt to trigger a WebSocket reconnect by setting a new socket URL.
			if (!connectionError && (readyState === ReadyState.CLOSED || readyState === ReadyState.UNINSTANTIATED))
				setSocketUrl(IS_LOCAL_MODE ? "" : `${process.env.REACT_APP_BOLT_WEBSOCKET_BASE}?access_token=Bearer%20${idToken}`);

			// Polling to check if the WebSocket connection state becomes OPEN.
			const intervalId = setInterval(() => {
				if (prevwebsocketState.current === ReadyState.OPEN && !connectionError) {
					clearInterval(intervalId); // Stop polling once the state is OPEN.
					if (!isAttemptResolved) {
						isAttemptResolved = true; // Prevent further attempts after resolving.
						sendJsonMessage({
							action: "boltInvoke",
							data: payload,
						}); // Send the message via WebSocket.
						resolve("WebSocket"); // Indicate that the message was sent via WebSocket.
					}
				} else {
					clearInterval(intervalId);
				}
			}, 100); // Check every 100 milliseconds.

			// Timeout fallback to REST endpoint.
			setTimeout(() => {
				clearInterval(intervalId); // Clear the polling interval.
				if (!isAttemptResolved) {
					isAttemptResolved = true; // Prevent further attempts after resolving.
					// If the WebSocket connection wasn't established or message wasn't sent, fall back to REST.
					axios
						.post(IS_LOCAL_MODE ? "http://localhost:8000/invoke" : process.env.REACT_APP_AWS_BOLT_AI_ASSISTANT, payload)
						.then((res) => {
							setBoltResponse(res?.data); // Update state with the response from REST endpoint.
							resolve("REST"); // Indicate that the message was sent via REST.
						})
						.catch((e) => {
							console.log(e); // Log any errors from the REST request.
							reject(e); // Reject the promise on error.
						})
						.finally(() => {
							setSearchLoading(false); // Reset loading state.
						});
				}
			}, maxWaitTime); // Fallback after maxWaitTime milliseconds.
		});
	};

	const handleSearch = async (query, prevItems) => {
		if (query) {
			setSearchLoading(true); // Indicate that a search/load operation is starting.
			if (readyState === ReadyState.OPEN && !connectionError) {
				// If WebSocket is open, send the message directly.
				sendJsonMessage({
					action: "boltInvoke",
					data: {
						input: {
							message: query,
							user_id: authDetails?.userId?.toString(),
							company_id: authDetails?.companyId?.toString(),
							auth0_id: decoded?.sub,
							thread_id: boltThreadId,
						},
					},
				});
			} else {
				// If WebSocket is not open, attempt to reconnect and send the message.
				// Fallback to REST after a maximum of 2000 milliseconds if unsuccessful.
				if (IS_LOCAL_MODE) {
				}
				reconnectAndSendWithTimeout(
					IS_LOCAL_MODE
						? {
								message: query,
								user_id: authDetails?.userId?.toString(),
								company_id: authDetails?.companyId?.toString(),
								auth0_id: decoded?.sub,
								thread_id: boltThreadId,
						  }
						: {
								input: {
									message: query,
									user_id: authDetails?.userId?.toString(),
									company_id: authDetails?.companyId?.toString(),
									auth0_id: decoded?.sub,
									thread_id: boltThreadId,
								},
						  },
					connectionError ? 0 : 2000 // Max wait time set to 2000 milliseconds.
				)
					.then((method) => console.log(`Message sent via ${method}`)) // Log the method used to send the message.
					.catch((error) => console.error("Message sending failed", error)); // Log any errors encountered during the process.
			}
		}
	};

	useEffect(() => {
		if (lastJsonMessage !== null && lastJsonMessage?.type == "bolt_chat") {
			let message = lastJsonMessage;
			if (message?.action == "FINAL") {
				setSearchLoading(false);
				setBoltResponse({ response: message, action_type: "FINAL" });
			} else if (message?.action == "INTERMEDIATE") {
				setBoltResponse({ response: message, action_type: "INTERMEDIATE" });
				setTimeout(() => {
					scrollToBottom(true);
				}, 200);
			} else if (!message?.action && message?.message == "Forbidden") {
				setSearchLoading(false);
				setBoltResponse("Something went wrong");
			}
		}
	}, [lastJsonMessage]);

	const exampleMessage = (message) => {
		if (message.trim()) {
			let payload = {
				userRequest: message,
				timestamp: new Date(),
			};
			let updatedHistory = updateBoltSessionHistory(payload);
			setcurrentBoltHistory(updatedHistory);
			handleSearch(message, null);
		}
	};

	const feedback = (payload) => {
		setfeedback_open({
			...payload,
			open: true,
		});
	};

	const handleSendFeedback = () => {
		if (feedback_open?.comment.trim()) {
			let payload = {
				run_id: feedback_open?.run_id,
				key: "bolt-chat-feedback",
				score: feedback_open?.value,
				comment: feedback_open?.comment.trim(),
			};
			setfeedback_open({ open: false });
			axios
				.post("https://api.smith.langchain.com/feedback", payload, {
					headers: {
						"x-api-key": process.env.REACT_APP_LANGSMITH_KEY,
					},
				})
				.catch((e) => {
					console.log(e); // Log any errors from the REST request.
				});
		}
	};

	return (
		<Fragment>
			{isDesktop ? (
				<Button
					sx={{ border: "1px solid white" }}
					variant="DS1"
					color={"black"}
					startIcon={<Box component={"img"} src={UPDATED_LOGO_ICON_NEW} sx={{ width: 18, height: 18 }} />}
					fullWidth={true}
					onClick={handleClick}
				>
					Bolt AI
				</Button>
			) : (
				<Button variant="DS1" iconButton={true} color={"black"} onClick={handleClick}>
					<Box component={"img"} src={UPDATED_LOGO_ICON_NEW} sx={{ width: 20, height: 20 }} />
				</Button>
			)}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				PaperProps={{
					sx: {
						background: "none",
						overflow: "visible",
						width: "80%",
						...(isExpanded && {
							top: "50% !important",
							left: "50% !important",
							transform: "translate(-50%,-50%) !important",
						}),
					},
				}}
			>
				<Box
					sx={{
						position: "relative",
						p: "32px",
						transition: ".2s all",
						...(isExpanded
							? {
									width: "100%",
									maxWidth: "1080px",
							  }
							: {
									width: "520px",
							  }),

						display: "flex",
						flexDirection: "column",
						borderRadius: "30px",
						background: "white",
						boxShadow: "0px 10px 85px rgba(0, 0, 0, .2)",
					}}
				>
					<Button
						variant="DS1"
						iconButton={true}
						sx={{
							position: "absolute",
							zIndex: 2,
							top: "-25px",
							right: "-25px",
							"& > svg": {
								transition: ".2s all",
							},
							"&:hover > svg": {
								transform: "scale(1.2)",
							},
						}}
						onClick={() => setisExpanded(!isExpanded)}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
							<path
								d="M10.6667 7.33333L16.5 1.5M16.5 1.5H11.5M16.5 1.5V6.5M7.33333 10.6667L1.5 16.5M1.5 16.5H6.5M1.5 16.5L1.5 11.5"
								stroke="white"
								stroke-width="1.66667"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</Button>
					<Box sx={{ display: "flex" }}>
						<Box sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "space-between", flex: 1 }}>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M5.99984 29.3334V22.6667M5.99984 9.33341V2.66675M2.6665 6.00008H9.33317M2.6665 26.0001H9.33317M17.3332 4.00008L15.0209 10.0119C14.6449 10.9895 14.4569 11.4784 14.1645 11.8895C13.9054 12.2539 13.587 12.5723 13.2226 12.8315C12.8114 13.1238 12.3226 13.3118 11.345 13.6878L5.33317 16.0001L11.345 18.3123C12.3226 18.6883 12.8114 18.8763 13.2226 19.1687C13.587 19.4278 13.9054 19.7462 14.1645 20.1106C14.4569 20.5218 14.6449 21.0106 15.0209 21.9883L17.3332 28.0001L19.6454 21.9883C20.0214 21.0106 20.2094 20.5218 20.5018 20.1106C20.7609 19.7462 21.0793 19.4278 21.4437 19.1687C21.8549 18.8763 22.3437 18.6883 23.3214 18.3123L29.3332 16.0001L23.3214 13.6878C22.3437 13.3118 21.8549 13.1238 21.4437 12.8315C21.0793 12.5723 20.7609 12.2539 20.5018 11.8895C20.2094 11.4784 20.0214 10.9895 19.6454 10.0119L17.3332 4.00008Z"
									stroke="#7B61FF"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Button variant="DS1" color="clear" iconButton={true} small={true} onClick={() => handleClose()}>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path d="M24 8L8 24M8 8L24 24" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</Button>
						</Box>
					</Box>
					<Box sx={{ display: "flex", flex: 1, marginBottom: "10px" }}>
						<Typography variant="Text/md/Semibold">Bolt AI</Typography>
					</Box>
					<div
						ref={messageListRef}
						style={{
							...(isExpanded
								? {
										height: "800px",
								  }
								: {
										width: "520px",
										height: "675px",
								  }),

							overflowY: "scroll",
							overflowX: "hidden",
							marginBottom: "10px",
							paddingTop: "12px",
						}}
					>
						{readyState == ReadyState.CONNECTING && !connectionError ? (
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, height: "100%" }}>
								<BoltLoader />
							</Box>
						) : (
							<Fragment>
								{chatGroup(
									{
										systemMessage: (
											<div style={{ marginBottom: "8px" }}>
												<Typography variant="Text/sm/Regular">Hey, I’m Bolt! Here are some examples of things you can try:</Typography>
												<br />
												<br />
												<Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
													<Button
														variant="DS1"
														color="black"
														sx={{ width: "fit-content" }}
														small={true}
														onClick={() => exampleMessage("What can you do?")}
													>
														What can you do?
													</Button>
													<Button
														variant="DS1"
														small={true}
														onClick={() => exampleMessage("Perform a success analysis on my company")}
														color="black"
														sx={{ width: "fit-content" }}
													>
														Perform a success analysis on my company
													</Button>
													<Button
														variant="DS1"
														small={true}
														color="black"
														sx={{ width: "fit-content" }}
														onClick={() => exampleMessage("Provide me some articles that you recommend on finding a mentor.")}
													>
														Recommend some articles on finding a mentor
													</Button>
												</Box>
											</div>
										),
									},
									"intro_message_1"
								)}
								{currentBoltHistory?.map((item, index) => chatGroup(item, index, exampleMessage, feedback))}
								{searchLoading && <BoltTyping />}
							</Fragment>
						)}
					</div>
					{(readyState !== ReadyState.CONNECTING || connectionError) && (
						<Box sx={{ display: "flex", gap: 1, flex: 1, alignItems: "center" }}>
							<TextField
								placeholder="Type here"
								multiline
								sx={{ width: "100%", display: "flex", flex: 1 }}
								InputProps={{
									sx: {
										border: "1px solid var(--Gray-300, #D0D5DD)",
										borderRadius: "25px",
										padding: "10px 20px",
										fontFamily: "PoppinsRegular !important",
										fontSize: "16px !important",
										lineHeight: "24px",
										boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
									},
								}}
								key={inputKey}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === "Enter" && !e.shiftKey) {
										handleSendMessage();
										e.preventDefault();
									}
								}}
							/>
							<Box>
								<Button variant="DS1" disabled={searchLoading} onClick={handleSendMessage} color="primary">
									Ask Bolt
								</Button>
							</Box>
						</Box>
					)}
					<Dialog
						maxWidth={"lg"}
						open={feedback_open?.open}
						onClose={() => setfeedback_open({ open: false })}
						PaperProps={{
							sx: {
								width: "520px",
								borderRadius: "20px",
								p: 4,
							},
						}}
					>
						{feedback_open?.open && (
							<Fragment>
								<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									{feedback_open?.value == 0 ? (
										<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
											<path
												d="M22.6666 2.66663V17.3333M29.3332 13.0666V6.93329C29.3332 5.43982 29.3332 4.69308 29.0426 4.12265C28.7869 3.62089 28.379 3.21294 27.8772 2.95728C27.3068 2.66663 26.5601 2.66663 25.0666 2.66663H10.8239C8.87528 2.66663 7.90096 2.66663 7.11402 3.0232C6.42044 3.33748 5.83097 3.84319 5.41489 4.48091C4.9428 5.20448 4.79465 6.16748 4.49834 8.09346L3.80091 12.6268C3.4101 15.167 3.2147 16.4372 3.59165 17.4255C3.9225 18.2929 4.54477 19.0182 5.3518 19.4771C6.27128 20 7.55635 20 10.1265 20H11.1999C11.9466 20 12.32 20 12.6052 20.1453C12.8561 20.2731 13.0601 20.4771 13.1879 20.728C13.3332 21.0132 13.3332 21.3866 13.3332 22.1333V26.0455C13.3332 27.8613 14.8052 29.3333 16.621 29.3333C17.0541 29.3333 17.4466 29.0782 17.6225 28.6825L22.1035 18.6002C22.3073 18.1416 22.4092 17.9123 22.5703 17.7442C22.7127 17.5956 22.8875 17.482 23.0812 17.4122C23.3002 17.3333 23.5511 17.3333 24.053 17.3333H25.0666C26.5601 17.3333 27.3068 17.3333 27.8772 17.0426C28.379 16.787 28.7869 16.379 29.0426 15.8773C29.3332 15.3068 29.3332 14.5601 29.3332 13.0666Z"
												stroke="#7B61FF"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									) : (
										<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
											<path
												d="M9.33317 29.3333V14.6666M2.6665 17.3333V26.6666C2.6665 28.1394 3.86041 29.3333 5.33317 29.3333H23.2348C25.2091 29.3333 26.8881 27.8929 27.1883 25.9415L28.6242 16.6082C28.997 14.1852 27.1223 12 24.6707 12H19.9998C19.2635 12 18.6665 11.403 18.6665 10.6666V5.95441C18.6665 4.13862 17.1945 2.66663 15.3787 2.66663C14.9456 2.66663 14.5531 2.92169 14.3772 3.31746L9.68509 13.8748C9.47109 14.3563 8.99359 14.6666 8.46667 14.6666H5.33317C3.86041 14.6666 2.6665 15.8605 2.6665 17.3333Z"
												stroke="#7B61FF"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									)}
									<Button variant="DS1" color="clear" iconButton={true} small={true} onClick={() => setfeedback_open({ open: false })}>
										<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
											<path d="M24 8L8 24M8 8L24 24" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</Button>
								</Box>
								<Box sx={{ mt: "12px" }}>
									<Typography variant="Text/md/Semibold">{feedback_open?.value == 0 ? "Sorry about that!" : "Woohoo!"}</Typography>
								</Box>
								<Box sx={{ mt: "12px" }}>
									<Typography variant="Text/sm/Regular">
										{feedback_open?.value == 0
											? "Briefly describe what Bolt did wrong here and what you expected instead."
											: "Briefly describe what Bolt got right here! We appreciate your feedback!"}
									</Typography>
								</Box>
								<Box sx={{ mt: "24px" }}>
									<TextField
										placeholder="Type here"
										minRows={8}
										multiline
										sx={{ width: "100%", display: "flex", flex: 1 }}
										InputProps={{
											sx: {
												border: "1px solid var(--Gray-300, #D0D5DD)",
												borderRadius: "25px",
												padding: "10px 20px",
												fontFamily: "PoppinsRegular !important",
												fontSize: "16px !important",
												lineHeight: "24px",
												boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
												background: "white",
											},
										}}
										key={inputKey}
										value={feedback_open?.comment}
										onChange={(e) => setfeedback_open({ ...feedback_open, comment: e.target.value })}
										onKeyDown={(e) => {
											if (e.key === "Enter" && !e.shiftKey) {
												handleSendFeedback();
												e.preventDefault();
											}
										}}
									/>
								</Box>
								<Box sx={{ mt: "12px", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "4px" }}>
									<Button variant="DS1" color="clear" disabled={!feedback_open?.comment} onClick={() => setfeedback_open({ open: false })}>
										Never mind
									</Button>
									<Button variant="DS1" disabled={!feedback_open?.comment} onClick={handleSendFeedback} color="primary">
										Send
									</Button>
								</Box>
							</Fragment>
						)}
					</Dialog>
				</Box>
			</Popover>
		</Fragment>
	);
}

export default BoltChat;
