import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PowerPackContext } from "../PowerPackController";
import { Box, Button, CircularProgress, Link, Typography, useMediaQuery } from "@mui/material";
import { Card as PowerUpCard } from "../components/cards/Card";
import PowerupCourseCard from "../components/cards/PowerupCourseCard/PowerupCourseCard";
import { ALERT_ICON_V1, GLOBE_ICON_V1, LIGHTNING_ICON_V1, POWERPACK_DETAILS_DATA, PPT_ICON_V1, STARS_ICON_V2 } from "../../../constants";
import TopMentorMatches from "../components/TopMentorMatches";
import { Harness } from "../../PowerUP/compositions/LearnIt/PitchDeckGenerator/md-pitchdeck-generator";
import { USER_TYPES } from "../../../utils/userTypes";
import HeroCard from "../components/cards/HeroCard/HeroCard";
import PitchDeckViewer from "../../../components/Profile/Components/PitchDeckCard/PitchDeckViewer";
import MissingAsset from "../../../components/Profile/Components/MissingAsset";
import { GET_COMPANY_DATA_QUERY } from "../../../components/Profile/Stores/Function/common";
import { useLazyQuery } from "@apollo/client";
import BoltPitchdeckReviewSection from "../../../components/Profile/Components/UpdatedPitchDeckSection/BoltPitchdeckReviewSection";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../../services/abilities/context";
import PaymentPlanDialog from "../../../components/PaymentPlanDialog";
import { FREE, PITCHDECK, PREMIUM } from "../../../components/PaymentPlanDialog/Content";
import { useLocation, useHistory } from "react-router-dom";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import UpdatedDialogConfirmation from "../../../common/UpdatedDialogConfirmation";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useQueryParams = () => {
	const { search } = useLocation();

	return React.useMemo(() => {
		const params = new URLSearchParams(search);
		const queryObject = {};
		for (const [key, value] of params.entries()) {
			queryObject[key] = value;
		}
		return queryObject;
	}, [search]);
};

function StripePaymentConfirmDialog() {
	const stripe = useStripe();
	const dispatch = useDispatch();
	const queryParams = useQueryParams();
	const history = useHistory();

	const clearParams = () => {
		history.replace({
			pathname: window.location.pathname,
			search: "",
		});
	};

	useEffect(() => {
		paymentConfirmationDialog();
	}, [stripe]);

	const paymentConfirmationDialog = async () => {
		try {
			if (!stripe) return;

			if (queryParams && queryParams?.payment_intent_client_secret) {
				const { paymentIntent } = await stripe.retrievePaymentIntent(queryParams?.payment_intent_client_secret);
				if (paymentIntent && paymentIntent.status === "succeeded") {
					dispatch(
						setCommonDialogConfirmationData({
							isOpen: true,
							title: "Payment Successful",
							subTitle: "Pitch deck review in progress",
							text: "Bolt AI is now reviewing your pitch deck. Bolt will notify you once complete. Feel free to come back later, you will see the report below once Bolt has completed their review.",
							primaryButtonText: "Ok",
							primaryButtonAction: () => {
								dispatch(setCommonDialogConfirmationData({ isOpen: false }));
								setTimeout(() => {
									clearParams();
									dispatch(setCommonDialogConfirmationData({ title: "", subTitle: "", text: "", primaryButtonText: "", titleIcon: "" }));
								}, 500);
							},
							primaryButtonVariant: "DS1",
							primaryButtonLoading: false,
						})
					);
				} else {
					dispatch(
						setCommonDialogConfirmationData({
							isOpen: true,
							title: "Payment Failed",
							subTitle: "We were not able to finalize payment",
							text: "We were unable to finalize the transaction. Please try again or reach out to StartupOS support.",
							primaryButtonText: "Ok",
							titleIcon: ALERT_ICON_V1,
							primaryButtonAction: () => {
								dispatch(setCommonDialogConfirmationData({ isOpen: false }));
								setTimeout(() => {
									clearParams();
									dispatch(setCommonDialogConfirmationData({ title: "", subTitle: "", text: "", primaryButtonText: "", titleIcon: "" }));
								}, 500);
							},
							primaryButtonVariant: "DS1",
							primaryButtonLoading: false,
						})
					);
				}
			}
		} catch (e) {
			console.log("Error with stripe confirmation. Failed with error:", e);
		}
	};

	return <UpdatedDialogConfirmation />;
}

function BoltPDAnalysisComposition() {
	const { userType } = useSelector((store) => store?.auth);
	const { powerUpList, packDetail, packId } = useContext(PowerPackContext);
	const [profileData, setProfileData] = useState({});
	const [loadingValues, setloadingValues] = useState(false);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xl"));
	const [showPaywall, setShowPaywall] = useState(false);
	const [paywallOptions, setPaywallOptions] = useState([]);

	const [errors, setErrors] = useState({});
	const ability = useAbility(AbilityContext);

	const [runPDReportButton, setrunPDReportButton] = useState(null);

	const [loadUserData, { loading, error, data }] = useLazyQuery(GET_COMPANY_DATA_QUERY, {
		onCompleted: function (data) {
			if (data?.companyById) {
				setProfileData(data?.companyById);
			}
		},
		onError: (err) => {
			setErrors({
				error: "Unable to successfully retrieve your startup profile.",
			});
		},
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		loadUserData({ variables: { id: null } });
	}, []);

	return loadingValues ? (
		<CircularProgress />
	) : (
		<Box>
			<HeroCard />
			<Box
				sx={{
					my: "2.5rem",
				}}
			>
				<Box display={"flex"} gap={2} flexWrap={isSmallScreen && "wrap"}>
					<Box>
						<PowerUpCard sxOverride={{ mb: 2 }}>
							<Typography variant="Text/md/Semibold">Your Pitch Deck</Typography>

							<Box
								sx={{
									borderRadius: "40px",
									width: "100%",
								}}
							>
								{loading ? (
									<Box
										sx={{
											height: "450px",
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "column",
											gap: 1,
										}}
									>
										<CircularProgress />
										<Typography variant="Text/md/Regular">Loading Pitch Deck</Typography>
									</Box>
								) : profileData && profileData?.pitchDeckLink ? (
									<PitchDeckViewer profileData={profileData} />
								) : (
									<MissingAsset
										sxProps={{ height: "450px", p: 2 }}
										message={
											<Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
												<span>Please upload your pitch deck on your startup profile</span>
												<Button variant="DS1" href="/startup-profile">
													Go to Profile
												</Button>
											</Box>
										}
									/>
								)}
							</Box>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1, width: "100%" }}>
								{ability.can("view", "PremiumOnlyFeature")
									? runPDReportButton
									: runPDReportButton &&
									  React.cloneElement(runPDReportButton, {
											onClick: () => {
												setShowPaywall(true);
												setPaywallOptions([PITCHDECK, PREMIUM]);
											},
									  })}
							</Box>
						</PowerUpCard>
						<PowerUpCard>
							<Box display={"flex"} alignItems={"center"} gap={1}>
								<Box component={"img"} src={GLOBE_ICON_V1} alt="globe" />
								<Typography variant="Text/md/Semibold">Overview</Typography>
							</Box>
							<Typography variant="Text/sm/Regular">
								Take the guesswork out of fundraising with a detailed, AI-powered review of your pitch deck. Gain actionable insights to refine
								your messaging, address gaps, and boost your chances of success.
								<br />
								<br />
								StartupOS provides an analysis leveraging 20+ years of proprietary startup data which includes:
								<br />
								<ul>
									<li>A clear fundraising likelihood score.</li>
									<li>Specific recommendations for how to improve your pitch deck.</li>
									<li>Suggestions to improve narrative flow and design.</li>
									<li>Anticipated questions that investors might ask based on your pitch deck</li>
								</ul>
							</Typography>
							<Typography variant="Text/md/Semibold">What should you expect?</Typography>
							<Typography variant="Text/sm/Regular">
								<strong>Upload Your Pitch Deck</strong>
								<br />
								Drag and drop your pitch deck, or select it from your existing files within your StartupOS profile.
								<br />
								<br />
								<strong>AI-Powered Analysis in Action</strong>
								<br />
								Bolt AI will scan your slides, assessing critical elements like your problem statement, market opportunity, solution, team,
								funding ask, and more. The system will deliver an in-depth report with:
								<ul>
									<li>A clear fundraising likelihood score.</li>
									<li>Specific recommendations for each slide.</li>
									<li>Anticipated questions that investors might ask based on your pitch deck</li>
								</ul>
								<Link sx={{ textDecoration: "underline", fontWeight: "bold" }}>Click here</Link> to download a sample report.
								<br />
								<br />
								<strong>Your Next Steps</strong>
								<br />
								<ul>
									<li>Use the actionable feedback to refine your pitch, address gaps, and confidently approach investors.</li>
									<li>After payment, your pitch deck analysis will begin immediately for the pitch deck you have upload below.</li>
								</ul>
								<br />
								<strong>Why Founders Love It</strong>
								<br />
								<br />
								"It's like an SAT prep test… but for fundraising!" - Rob Kaminski, CoolStartupCo
								<br />
								<br />
								<strong>Stop second-guessing your pitch. Let Bolt AI take your deck to the next level.</strong>
							</Typography>

							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1, width: "100%" }}>
								{ability.can("view", "PremiumOnlyFeature")
									? runPDReportButton
									: runPDReportButton &&
									  React.cloneElement(runPDReportButton, {
											onClick: () => {
												setShowPaywall(true);
												setPaywallOptions([PITCHDECK, PREMIUM]);
											},
									  })}
							</Box>
						</PowerUpCard>
					</Box>
					<Box sx={{ maxWidth: isSmallScreen ? "auto" : "430px", width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
						<PowerUpCard>
							<Box display={"flex"} alignItems={"center"} gap={1}>
								<Box component={"img"} src={PPT_ICON_V1} alt="ppt" />
								<Typography variant="Text/md/Semibold">Your Pitch Deck Analysis History</Typography>
							</Box>
							<Typography variant="Text/sm/Regular" component={"p"}>
								Review your previous pitch deck analyses to track improvements and insights over time. Each report provides actionable feedback
								to refine your deck and strengthen your fundraising efforts. Ready to make more progress? Submit your pitch deck for another
								analysis today!
							</Typography>
							<BoltPitchdeckReviewSection profileData={profileData} setRunReportButton={(comp) => setrunPDReportButton(comp)} />
						</PowerUpCard>
						{ability.cannot("view", "PremiumOnlyFeature") && (
							<PowerUpCard
								sxOverride={{
									gap: "unset",
								}}
							>
								<Box display={"flex"} alignItems={"flex-start"} gap={1} sx={{ mb: "1.5rem" }}>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
										<path
											d="M28 28H6.13333C5.3866 28 5.01323 28 4.72801 27.8547C4.47713 27.7268 4.27316 27.5229 4.14532 27.272C4 26.9868 4 26.6134 4 25.8667V4M28 9.33333L20.7542 16.5791C20.4902 16.8431 20.3582 16.9751 20.206 17.0246C20.0721 17.0681 19.9279 17.0681 19.794 17.0246C19.6418 16.9751 19.5098 16.8431 19.2458 16.5791L16.7542 14.0876C16.4902 13.8236 16.3582 13.6916 16.206 13.6421C16.0721 13.5986 15.9279 13.5986 15.794 13.6421C15.6418 13.6916 15.5098 13.8236 15.2458 14.0876L9.33333 20M28 9.33333H22.6667M28 9.33333V14.6667"
											stroke="black"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<Typography variant="Text/md/Semibold">Take Your Startup to the Next Level with Premium</Typography>
								</Box>
								<Typography variant="Text/sm/Regular" component={"p"} sx={{ mb: "1rem" }}>
									Unlock exclusive tools and support to accelerate your growth and funding success.
								</Typography>
								<Typography variant="Text/sm/Semibold" component={"p"}>
									Why Go Premium?
								</Typography>

								<ul>
									<li>
										<Typography variant="Text/sm/Regular">
											<strong>Unlimited Pitch Deck Analyses</strong>: Get unlimited AI-powered insights to perfect your deck, impress
											investors, and secure funding with confidence.
										</Typography>
									</li>
									<li>
										<Typography variant="Text/sm/Regular">
											<strong>Exclusive Founder Community</strong>: Join a network of driven founders and mentors, share experiences, and
											gain support from those who've been there.
										</Typography>
									</li>
									<li>
										<Typography variant="Text/sm/Regular">
											<strong>Access to Weekly Office Hours Event</strong>: Weekly sessions with StartupOS experts to tackle challenges,
											refine strategies, and keep your startup on track.
										</Typography>
									</li>
								</ul>

								<Typography variant="Text/sm/Semibold" component={"p"}>
									Your startup's success starts here.
								</Typography>

								<Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%", mt: "1.5rem" }}>
									<Button
										variant="DS1"
										small
										onClick={() => {
											setShowPaywall(true);
											setPaywallOptions([FREE, PREMIUM]);
										}}
									>
										Upgrade to Premium
									</Button>
								</Box>
							</PowerUpCard>
						)}
					</Box>
				</Box>
			</Box>
			<PaymentPlanDialog
				open={showPaywall}
				isRedirect={false}
				onClose={() => setShowPaywall(false)}
				plans={paywallOptions}
				metadata={{
					startupName: profileData?.companyName,
					pitchDeckLink: profileData?.pitchDeckLink,
					returnUrl: process.env.REACT_APP_HOME_URL + "/workspace/powerPack/" + packId,
				}}
			/>
			<Elements stripe={stripePromise}>
				<StripePaymentConfirmDialog />
			</Elements>
		</Box>
	);
}

export default BoltPDAnalysisComposition;
