import React, { Fragment, useEffect, useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import ROUTES_PATH from "../../../constants/routes";
import { PD_ANALYSIS_PROMOTIONAL_BANNER, SPEAKER_ICON } from "../../../constants";
import { AbilityContext } from "../../../services/abilities/context";
import { useAbility } from "@casl/react";
import PaymentPlanDialog from "../../PaymentPlanDialog";

export default function PromotionalEvents() {
	const dispatch = useDispatch();
	const { open } = useSelector((state) => state?.commonDialogConfirmation);
	const ability = useAbility(AbilityContext);
	const [showPaywall, setShowPaywall] = useState(false);

	const history = useHistory();
	const handleClose = () => {
		dispatch(
			setCommonDialogConfirmationData({
				open: false,
			})
		);
	};

	return (
		<>
			<CommonSlideDialog
				open={open}
				onClose={handleClose}
				title={<Box component={"img"} src={SPEAKER_ICON} />}
				titleVariant="Text/md/Semibold"
				maxWidth="sm"
				disableBackDropClick={false}
				PaperProps={{
					sx: {
						px: 2,
						py: 3,
						borderRadius: "30px",
						width: 1,
					},
				}}
				contentStyle={{ mt: 0 }}
				dialogAction={
					<Box display="flex" alignItems="center" gap={1.5} sx={{ pr: 3, my: 1 }}>
						{ability.cannot("view", "PremiumOnlyFeature") && (
							<Box>
								<Button
									variant="noOutlineNew"
									onClick={() => {
										handleClose();
										setShowPaywall(true);
									}}
								>
									Upgrade to Premium
								</Button>
							</Box>
						)}
						<Box>
							<Button
								variant="DS1"
								onClick={() => {
									const env_val = process.env.REACT_APP_HOME_URL;
									switch (env_val) {
										case "http://localhost:3000":
											history.push("/workspace/powerPack/pitch-deck-analysis-by-bolt-ai-43");
											break;
										case "https://app.dev.startupos.com":
											history.push("/workspace/powerPack/pitch-deck-analysis-by-bolt-ai-43");
											break;
										case "https://app.stage.startupos.com":
											history.push("/workspace/powerPack/pitch-deck-analysis-by-bolt-ai-34");
											break;
										case "https://app.startupos.com":
											history.push("/workspace/powerPack/pitch-deck-analysis-by-bolt-ai-87");
											break;
										default:
											history.push("/home/get-started");
											break;
									}
									handleClose();
								}}
							>
								Start My Analysis
							</Button>
						</Box>
					</Box>
				}
			>
				<Box>
					<Stack mb="20px" spacing={2}>
						<Typography component={"p"} variant="Text/md/Semibold">
							Check Your Deck - Before You Bomb Your Pitch!
						</Typography>

						<Box
							component={"img"}
							src={PD_ANALYSIS_PROMOTIONAL_BANNER}
							sx={(theme) => ({
								borderRadius: theme.shape.standard10.borderRadius,
							})}
						/>

						<Typography variant="Text/sm/Semibold" component={"p"}>
							Pitching is hard. Let us make it easier.
						</Typography>
						<Typography variant="Text/sm/Regular" component={"p"}>
							Upload your pitch deck and get an AI-powered analysis that includes:
						</Typography>

						<Typography variant="Text/sm/Regular" sx={{ "& p": { margin: 0 }, "& ul": { marginBlockStart: 1, marginBlockEnd: 1, ml: "0px" } }}>
							<ul>
								<li>A fundraising likelihood score to gauge your chances of success.</li>
								<li>Macro and micro feedback on every slide to improve your story and messaging</li>
								<li>Actionable insights designed to help you pitch with confidence.</li>
								<li>Results in less than 30 minutes {ability.cannot("view", "PremiumOnlyFeature") && "for just $49."}</li>
							</ul>
						</Typography>

						{ability.cannot("view", "PremiumOnlyFeature") && (
							<Fragment>
								<Typography variant="Text/sm/Semibold" component={"p"}>
									Become a Premium Member and unlock these exclusive benefits:
								</Typography>

								<Typography
									variant="Text/sm/Regular"
									sx={{ "& p": { margin: 0 }, "& ul": { marginBlockStart: 1, marginBlockEnd: 1, ml: "0px" } }}
								>
									<ul>
										<li>Unlimited Pitch Deck Analyses to refine your deck anytime.</li>
										<li>Access to an exclusive Founder Community for advice, mentorship, and networking.</li>
										<li>Weekly Office Hours with startup experts to guide your growth.</li>
										<li>AI resources to help you scale smarter and faster.</li>
									</ul>
								</Typography>
								<Typography variant="Text/sm/Regular" component={"p"}>
									All of this for just <strong>$99/month</strong>
								</Typography>
								<Typography variant="Text/sm/Medium" component={"p"}>
									Join thousands of early-stage founders who’ve used StartupOS to perfect their pitch and secure funding.
								</Typography>
							</Fragment>
						)}
					</Stack>
				</Box>
				{/* Not in used currently */}
				{/* <Box>
					<Stack mb="20px" spacing={2}>
						<Typography component={"p"} variant="Text/sm/Semibold">
							StartupOS Pitch Day Event!
						</Typography>
						<Chip
							sx={{ maxWidth: "fit-content", backgroundColor: (theme) => theme.palette.secondary.white, "& .MuiChip-label": { px: "10px" } }}
							variant="outlined"
							size="small"
							label={
								<Typography
									variant="Text/xs/Regular"
									sx={(theme) => ({ color: theme.palette.secondary.black, borderColor: theme.palette.secondary.grey })}
								>
									{getFormattedDate(promotionalEventDetails?.eventDate, "MMMM DD, YYYY")}
								</Typography>
							}
						/>
						<Box
							component={"img"}
							src={promotionalEventDetails?.imageUrl}
							sx={(theme) => ({
								borderRadius: theme.shape.standard10.borderRadius,
							})}
						/>
						<Typography variant="Text/sm/Regular" sx={{ "& p": { margin: 0 }, "& ul": { marginBlockStart: 0, marginBlockEnd: 0 } }}>
							<span
								dangerouslySetInnerHTML={{
									__html: promotionalEventDetails?.pitchEventDescription,
								}}
							/>
						</Typography>
					</Stack>
				</Box> */}
			</CommonSlideDialog>
			<PaymentPlanDialog open={showPaywall} isRedirect={false} onClose={() => setShowPaywall(false)} />
		</>
	);
}
