import React from "react";
import { Box, Typography } from "@mui/material";
import NewOnboardCheckIcon from "../../Assets/new-onboard-check.svg";
import { useThemeContext } from "../../../../constants/Theme/ThemeContext";
import { useSelector } from "react-redux";
import { CREATE_ACCOUNT_TEXT, CREATE_ACCOUNT_TEXT_FOR_GENAI, CREATE_ACCOUNT_TEXT_FOR_SVB, CREATE_ACCOUNT_TEXT_FOR_SVBBHHM } from "../../../../constants";

export const Join = () => {
	const { utmCampaign } = useSelector((store) => store.utmParams);
	const { appNameOverride } = useThemeContext();

	return (
		<Box display="flex" alignItems="center" flexDirection="column" maxWidth={utmCampaign && utmCampaign === "gen-ai" ? "600px" : "400px"} gap={4}>
			<Typography variant="Text/2xl/Semibold" color="#000" textAlign={"left"}>
				{utmCampaign && utmCampaign === "2025svbbhhm" && `Join the SVB 2025 Black History Month Pitch Event on StartupOS`}
				{utmCampaign &&
					utmCampaign === "2024svbafrotech" &&
					`Join the BLCK VC Pitch Event at AfroTech24 on ${appNameOverride ? appNameOverride : "StartupOS"}`}
				{utmCampaign &&
					utmCampaign === "gen-ai" &&
					`Join the ${appNameOverride ? appNameOverride : "StartupOS"} platform and Participate in the Generative AI Accelerator Events`}
				{!utmCampaign && `Join the ${appNameOverride ? appNameOverride : "StartupOS"} platform today.`}
			</Typography>

			<Typography variant="Text/sm/Regular" color="#667085" textAlign={"left"}>
				{utmCampaign && utmCampaign === "2025svbbhhm" && (
					<>
						Ready to take your startup to the next level? Sign up now to participate in the Silicon Valley Bank Pitch Event, exclusively hosted on
						StartupOS. Compete for a chance to win:
						<ul>
							<li>1st Place: $10,000</li>
							<li>2nd Place: $8,000</li>
							<li>3rd Place: $6,000</li>
						</ul>
						By joining StartupOS, you'll will also gain access to a suite of powerful tools and resources designed to help you succeed:
					</>
				)}
				{utmCampaign && utmCampaign === "2024svbafrotech" && (
					<>
						Ready to take your startup to the next level? Sign up now to participate in the BLCK VC Pitch Event at AfroTech24, exclusively hosted on
						StartupOS to compete for a chance to win:
						<ul>
							<li>1st Place: $15,000</li>
							<li>2nd Place: $10,000</li>
							<li>3rd Place: $5,000</li>
						</ul>
						By joining StartupOS, you'll will also gain access to a suite of powerful tools and resources designed to help you succeed:
					</>
				)}
				{utmCampaign && utmCampaign === "gen-ai" && (
					<>
						Elevate your venture on a platform that bridges the gap between agile startups and leading enterprises. Engage with our StartupOS AI
						Accelerator to transform your innovative ideas into market-leading solutions.
					</>
				)}
				{!utmCampaign && (
					<>
						Founders, mentors, and Investors, join our platform today to access our amazing features!
						{appNameOverride && (
							<>
								<br />
								Powered by StartupOS.
							</>
						)}
					</>
				)}
			</Typography>
			<Box display="flex" alignItems="center" flexDirection="column" gap={1.5}>
				{utmCampaign && utmCampaign === "2025svbbhhm" && (
					<>
						{CREATE_ACCOUNT_TEXT_FOR_SVBBHHM.map((item, index) => (
							<Box key={index} display="flex" alignItems="flex-start" width="100%" gap={1}>
								<img src={NewOnboardCheckIcon} width={26.67} height={26.67} alt="Item Logo" />
								<Typography variant="Text/sm/Semibold">{item}</Typography>
							</Box>
						))}
					</>
				)}
				{utmCampaign && utmCampaign === "2024svbafrotech" && (
					<>
						{CREATE_ACCOUNT_TEXT_FOR_SVB.map((item, index) => (
							<Box key={index} display="flex" alignItems="flex-start" width="100%" gap={1}>
								<img src={NewOnboardCheckIcon} width={26.67} height={26.67} alt="Item Logo" />
								<Typography variant="Text/sm/Regular">{item}</Typography>
							</Box>
						))}
					</>
				)}
				{utmCampaign && utmCampaign === "gen-ai" && (
					<>
						{CREATE_ACCOUNT_TEXT_FOR_GENAI.map((item, index) => (
							<Box key={index} display="flex" alignItems="flex-start" width="100%" gap={1}>
								<img src={NewOnboardCheckIcon} width={26.67} height={26.67} alt="Item Logo" />
								<Typography variant="Text/sm/Regular">{item}</Typography>
							</Box>
						))}
					</>
				)}
				{!utmCampaign && (
					<>
						{CREATE_ACCOUNT_TEXT.map((item, index) => (
							<Box key={index} display="flex" alignItems="flex-start" width="100%" gap={1}>
								<img src={NewOnboardCheckIcon} width={26.67} height={26.67} alt="Item Logo" />
								<Typography variant="Text/sm/Regular">{item}</Typography>
							</Box>
						))}
					</>
				)}
			</Box>
			<Typography variant="Text/sm/Regular" color="#667085" textAlign={"left"}>
				{utmCampaign && utmCampaign === "2025svbbhhm" && (
					<>
						Don't miss out on this incredible opportunity to win cash prizes and access the tools and community that can drive your startup forward.
						Sign up now and secure your spot in this event.
					</>
				)}
				{utmCampaign && utmCampaign === "2024svbafrotech" && (
					<>
						Don't miss out on this incredible opportunity to win cash prizes and access the tools and community that can drive your startup forward.
					</>
				)}
			</Typography>
		</Box>
	);
};
