import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useDimensions from "react-cool-dimensions";
import { ProfileContext } from "../..";

function PitchDeckViewer({ profileData: propProfileData }) {
	const context = useContext(ProfileContext);
	const contextProfileData = context ? context.profileData : undefined;

	// Use the prop value if provided; otherwise, fallback to the context value
	const profileData = propProfileData || contextProfileData;

	const [isSmallWidth, setIsSmallWidth] = useState(false);
	const { observe, unobserve, width, height, entry } = useDimensions({
		breakpoints: { SM: 650 },
		updateOnBreakpointChange: true,
	});

	useEffect(() => {
		observe();
	}, []);

	useEffect(() => {
		if (width >= 650) {
			setIsSmallWidth(false);
		} else {
			setIsSmallWidth(true);
		}
	}, [width]);

	// Handle case where no profileData is available
	if (!profileData) {
		return <div>No profile data available</div>;
	}
	return (
		<Box ref={observe} sx={{ position: "relative", overflow: "hidden", width: "100%", height: isSmallWidth ? "600px" : "800px" }}>
			{profileData?.pitchDeckLink?.substr(profileData?.pitchDeckLink?.length - 3) === "ppt" ||
				(profileData?.pitchDeckLink?.substr(profileData?.pitchDeckLink?.length - 4) === "pptx" && (
					<iframe
						src={`https://view.officeapps.live.com/op/embed.aspx?src=${profileData?.pitchDeckLink}&&slide=12`}
						frameBorder="0"
						id="iframe-slides"
						title="slides"
						style={{
							width: "100%",
							height: isSmallWidth ? "600px" : "800px",
							borderRadius: "30px",
							border: "none",
							margin: 0,
							padding: 0,
							overflow: "hidden",
						}}
					></iframe>
				))}

			{profileData?.pitchDeckLink?.substr(profileData?.pitchDeckLink?.length - 3) === "pdf" && (
				<iframe
					src={profileData?.pitchDeckLink}
					id="iframe-pdf"
					frameBorder="0"
					style={{
						width: "100%",
						height: isSmallWidth ? "600px" : "800px",
						borderRadius: "30px",
						border: "none",
						margin: 0,
						padding: 0,
						overflow: "hidden",
					}}
				></iframe>
			)}
		</Box>
	);
}

export default PitchDeckViewer;
