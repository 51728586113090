import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Dropzone from "react-dropzone";
import { NEW_PROFILE_LOGO, UPLOAD_ICON_V2 } from "../../constants";
import CommonImageCropper from "../CommonImageCropper";
import { toastContainer } from "../../utils/toast";
import services from "../../services";
import CommonSlideDialog from "../CommonSlideDialog";
import { GravatarURL } from "../../constants/profile-data";

const UpdatedImageUploader = ({
	title,
	instructions,
	width,
	height,
	maxWidth,
	maxHeight,
	shape,
	type,
	userData,
	setPayLoad,
	circularCropper,
	aspectRatio,
	fileType,
	editTitle,
	backgroundColor = "white",
	boxShadow = "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
	uploadIcon = UPLOAD_ICON_V2,
	uploaderType = "PROFILE",
	readOnly = false,
}) => {
	const [isCropperOpen, setIsCropperOpen] = useState(false);
	const [localFile, setLocalFile] = useState(false);
	const [cropper, setCropper] = useState();
	const [cropData, setCropData] = useState(false);
	const [cropperLoader, setCropperLoader] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState("");
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);

	const handleOnClose = () => {
		setIsCropperOpen(false);
		setCropData("");
		setLocalFile("");
	};

	const handleFileSelect = (file) => {
		if (file) {
			const fileSize = (file?.size / (1024 * 1024 * 1024)).toFixed(2);

			if (fileSize < 2) {
				const reader = new FileReader();
				reader.addEventListener("load", () => setLocalFile(reader.result?.toString() || ""));
				reader.readAsDataURL(file);
				setIsCropperOpen(true);
			} else {
				toastContainer("Please Upload file less than 2GB", "error");
			}
		} else {
			toastContainer(`Invalid file format for '${title}'. Please upload a file in one of the following formats: png, jpg, jpeg`, "error");
		}
	};

	const convertURIToImageData = (dataurl) => {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let filename = Date.now() + "." + mime.split("/")[1];
		return new File([u8arr], filename, {
			type: mime,
		});
	};

	const getCropData = () => {
		setCropperLoader(true);

		if (typeof cropper !== "undefined") {
			var fileUrl = convertURIToImageData(cropper.getCroppedCanvas().toDataURL(), "");
			setCropData(cropper.getCroppedCanvas().toDataURL());
			getFiles(fileUrl);
		}
	};

	const getFiles = async (data) => {
		var formData = new FormData();
		formData.append("file", data);
		formData.append("fileGroup", "PROFILE");
		services
			.uploadFile(formData)
			.then((response) => {
				userData[type] = response?.data?.data?.fileLocation;
				setPayLoad(...userData);
				setCropperLoader(false);
				setIsCropperOpen(false);
				setLocalFile("");
				setCropData("");
			})
			.catch((e) => {
				setCropperLoader(false);
				setIsCropperOpen(false);
				setLocalFile("");
				setCropData("");
			});
	};

	const handleRemove = () => {
		setCropData("");
		setLocalFile("");
	};

	const handleDelete = () => {
		setDeleteConfirmation(false);
		setIsDeleteOpen(false);
		userData[type] = "";
		if (uploaderType === "SURVEY") {
			setPayLoad(userData);
		} else {
			setPayLoad(...userData);
		}
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 3,
				justifyContent: "center",
			}}
		>
			<Typography variant="poppinsSemiBold20" sx={{ textAlign: "center" }}>
				{title}
			</Typography>
			{/* <Typography variant="flyout_text" sx={{ textAlign: "center" }}>
				{instructions}
			</Typography> */}
			<Box
				sx={{
					width: { width },
					height: { height },
					maxWidth: { maxWidth },
					maxHeight: { maxHeight },
					borderRadius: shape == "circular" ? "50%" : "30px",
					boxShadow: boxShadow,
					background: backgroundColor,
					alignSelf: "center",
					cursor: "pointer",
				}}
			>
				{userData && userData[type] ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Box
							component={"img"}
							src={`${userData[type] ? (!userData[type].includes(GravatarURL) ? userData[type] : NEW_PROFILE_LOGO) : NEW_PROFILE_LOGO}`}
							sx={{
								width: { width },
								height: { height },
								maxWidth: { maxWidth },
								maxHeight: { maxHeight },
								borderRadius: shape == "circular" ? "50%" : "30px",
								boxShadow: boxShadow,
								objectFit: "cover",
							}}
							onError={(e) => {
								e.target.src = NEW_PROFILE_LOGO;
							}}
						/>
						{!readOnly && (
							<Box
								component={"img"}
								src={uploadIcon}
								width={50}
								height={50}
								alt="img"
								sx={{ position: "absolute" }}
								onClick={() => {
									setIsDeleteOpen(true);
								}}
							/>
						)}
					</Box>
				) : (
					<Dropzone onDrop={!cropData ? (acceptedFiles) => handleFileSelect(acceptedFiles[0]) : () => {}} accept={fileType}>
						{({ getRootProps, getInputProps }) => (
							<section>
								<Box {...getRootProps()}>
									<input {...getInputProps()} />
									<Box
										sx={(theme) => ({
											width: { width },
											height: { height },
											maxWidth: { maxWidth },
											maxHeight: { maxHeight },
											borderRadius: shape == "circular" ? "50%" : "30px",
											justifyContent: "center",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											gap: 2,
										})}
									>
										<Typography
											variant="bodyF16"
											sx={{
												mt: 1,
												display: "grid",
												color: (theme) => theme.palette.secondary.outline,
											}}
										>
											Drag file to upload or browse
										</Typography>
										<Box component={"img"} src={uploadIcon} width={50} height={50} alt="img"></Box>
									</Box>
								</Box>
							</section>
						)}
					</Dropzone>
				)}
			</Box>

			<CommonSlideDialog
				disableBackDropClick={true}
				title={editTitle}
				titleVariant={"poppinsSemiBold20"}
				isOpen={isDeleteOpen}
				onClose={() => {
					setIsDeleteOpen(false);
				}}
				contentStyle={{ px: 0 }}
				footerStyle={{ p: 0 }}
				maxWidth={"md"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
				dialogAction={
					<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
						<Box>
							<Button
								variant="noOutlineNew"
								onClick={() => {
									setDeleteConfirmation(true);
								}}
								sx={{
									fontSize: "18px",
									lineHeight: "28px",
									fontFamily: "PoppinsSemiBold",
								}}
							>
								Delete
							</Button>
						</Box>
						<Box>
							<Dropzone onDrop={!cropData ? (acceptedFiles) => handleFileSelect(acceptedFiles[0]) : () => {}} accept={fileType}>
								{({ getRootProps, getInputProps }) => (
									<section>
										<Box {...getRootProps()}>
											<input {...getInputProps()} />
											<Button variant="DS1">Edit</Button>
										</Box>
									</section>
								)}
							</Dropzone>
						</Box>
					</Box>
				}
			>
				<Box display={"flex"}>
					<Box
						component={"img"}
						src={`${userData[type] ? (!userData[type].includes(GravatarURL) ? userData[type] : NEW_PROFILE_LOGO) : NEW_PROFILE_LOGO}`}
						alt={""}
						sx={{
							width: { width },
							height: { height },
							maxWidth: { maxWidth },
							maxHeight: { maxHeight },
							borderRadius: shape == "circular" ? "50%" : "30px",
							boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
							objectFit: "cover",
							margin: "auto",
						}}
						onError={(e) => {
							e.target.src = NEW_PROFILE_LOGO;
						}}
					/>
				</Box>
			</CommonSlideDialog>

			<CommonImageCropper
				openCropper={isCropperOpen}
				handleOnClose={handleOnClose}
				cropperLoader={cropperLoader}
				handleCancel={handleRemove}
				aspectRatio={aspectRatio}
				localFile={localFile}
				getCropData={getCropData}
				setCropper={setCropper}
				circularCropper={circularCropper}
			/>
			<CommonSlideDialog
				disableBackDropClick={true}
				title={"Delete Image"}
				titleVariant={"poppinsSemiBold20"}
				isOpen={deleteConfirmation}
				onClose={() => {
					setDeleteConfirmation(false);
				}}
				titleStyle={{ px: 0 }}
				contentStyle={{ px: 0 }}
				footerStyle={{ p: 0 }}
				maxWidth={"sm"}
				fullWidth
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
					}),
				}}
				dialogAction={
					<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
						<Box>
							<Button variant="RedRounded" onClick={handleDelete}>
								Delete
							</Button>
						</Box>
						<Box>
							<Button
								variant="noOutlineNew"
								onClick={() => {
									setDeleteConfirmation(false);
								}}
								sx={{
									fontSize: "18px",
									lineHeight: "28px",
									fontFamily: "PoppinsSemiBold",
								}}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				}
			>
				<Box
					sx={(theme) => ({
						textAlign: "left",
					})}
				>
					<Typography variant="title_medium">Are you sure you want to delete this image?</Typography>
				</Box>
			</CommonSlideDialog>
		</Box>
	);
};

export default UpdatedImageUploader;
