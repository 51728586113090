import React, { useEffect, useState } from "react";
import {
	Typography,
	Box,
	Button,
	Grid,
	InputAdornment,
	IconButton,
	TextField,
	Switch,
	CircularProgress,
	Stack,
	Item,
	useMediaQuery,
	FormControl,
	Select,
	MenuItem,
	Skeleton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import ReusableQuill from "../../../../common/ReusableQuill";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import EventImageUploader from "../EventImageUploader";
import messages from "../../../../constants/messages";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CompanyGroupTable from "../../../Partner/Dashboard/Admin/CompanyGroupTable";
import GroupsTable from "../../../Partner/Dashboard/Admin/GroupsTable";
import axios from "axios";
import { assignFormUrl, getAssignedFormByEntityIdUrl, getPartnerFormsUrl } from "../../../../utils/urls";
import ViewFormFlyout from "../../../../components/Forms/Utilities/ViewFormFlyout";
import ManageFormFlyout from "../../../../components/Forms/Utilities/ManageForm";

export default function AddEvent({ open, onClose, getEventList, isEdit = false, eventObj = null }) {
	const partnerCode = useSelector((state) => state?.auth?.partnerCode);

	const schema = yup.object({
		title: yup.string().required("Title is required"),
		description: yup.string().required("Description is required").nullable(),
		isPitchEvent: yup.boolean(),
		isPremiumEvent: yup.boolean(),
		isFeatureEvent: yup.boolean(),
		companyIds: yup.array(),
		groupIds: yup.array(),
		isPrivateEvent: yup.boolean().when(["companyIds", "groupIds"], {
			is: (companyIds, groupIds) => !companyIds.length && !groupIds.length, // If both arrays are empty
			then: yup.boolean().oneOf([false], "At least one company or group must be selected for private events."),
		}),
		redirectLink: yup.string(),
		displayDate: yup.string().required("Display date is required"),
		showDate: yup.string().nullable(),
		eventDate: yup.string().required("Event date is required"),
		deadlineDate: yup.string().required("Deadline date is required"),
		hideDate: yup.string().nullable(),
		selectedForm: yup.string().nullable(),
	});

	const [loading, setLoading] = useState(false);

	const [heroImage, setHeroImage] = useState("");
	const [cardImage, setCardImage] = useState("");

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [heroImageData, setHeroImageData] = useState({});
	const [cardImageData, setCardImageData] = useState({});
	//name and size for heroImage
	const [heroImageName, setHeroImageName] = useState("Untitled Image");
	const [heroImageSize, setHeroImageSize] = useState(0);
	//name and size for cardImage
	const [cardImageName, setCardImageName] = useState("Untitled Image");
	const [cardImageSize, setCardImageSize] = useState(0);

	const [forms, setforms] = useState([]);
	const [formObj, setformObj] = useState(null);
	const [formLoading, setformLoading] = useState(false);

	const [selectedFormListOpenState, setselectedFormListOpenState] = useState(false);
	const [previewFormOpen, setpreviewFormOpen] = useState(false);

	const [createFormOpen, setcreateFormOpen] = useState(false);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: "onSubmit",
		defaultValues: {
			title: isEdit ? eventObj?.title : "",
			description: isEdit ? eventObj?.title : null,
			isPitchEvent: isEdit ? eventObj?.title : false,
			isPremiumEvent: isEdit ? eventObj?.title : false,
			isFeatureEvent: isEdit ? eventObj?.title : false,
			isPrivateEvent: isEdit ? eventObj?.isPrivate : false,
			companyIds: isEdit
				? eventObj?.eventAccesses?.filter((access) => access?.accessType === "COMPANY")?.map((access) => access?.accessTypeEntityId)
				: [],
			groupIds: isEdit ? eventObj?.eventAccesses?.filter((access) => access?.accessType === "GROUP")?.map((access) => access?.accessTypeEntityId) : [],
			redirectLink: isEdit ? eventObj?.title : "",
			displayDate: isEdit ? eventObj?.title : "",
			showDate: isEdit ? eventObj?.title : null,
			eventDate: isEdit ? eventObj?.title : null,
			deadlineDate: isEdit ? eventObj?.title : null,
			hideDate: isEdit ? eventObj?.title : null,
			selectedForm: null,
		},
		resolver: yupResolver(schema),
	});
	const deadlineDate = watch("deadlineDate") || null;
	const eventDate = watch("eventDate") || null;
	const showDate = watch("showDate") || null;
	const isPrivateEvent = watch("isPrivateEvent"); // Watch the checkbox value
	const selectedForm = watch("selectedForm") || null;

	useEffect(() => {
		if (isPrivateEvent) {
			setValue(
				"companyIds",
				isEdit ? eventObj?.eventAccesses?.filter((access) => access?.accessType === "COMPANY")?.map((access) => access?.accessTypeEntityId) : []
			);
			setValue(
				"groupIds",
				isEdit ? eventObj?.eventAccesses?.filter((access) => access?.accessType === "GROUP")?.map((access) => access?.accessTypeEntityId) : []
			);
		} else {
			setValue("companyIds", []);
			setValue("groupIds", []);
		}
	}, [isPrivateEvent, setValue]);

	useEffect(() => {
		if (open) {
			setHeroImage(isEdit ? eventObj?.heroImageUrl || "" : "");
			setCardImage(isEdit ? eventObj?.imageUrl || "" : "");

			getEventForm();

			reset({
				title: isEdit ? eventObj?.title || "" : "",
				description: isEdit ? eventObj?.description : null,
				isPitchEvent: isEdit ? eventObj?.isPitchEvent : false,
				isPremiumEvent: isEdit ? eventObj?.isPremiumEvent : false,
				isFeatureEvent: isEdit ? eventObj?.isFeatureEvent : false,
				isPrivateEvent: isEdit ? eventObj?.isPrivate : false,
				companyIds: isEdit
					? eventObj?.eventAccesses?.filter((access) => access?.accessType === "COMPANY")?.map((access) => access?.accessTypeEntityId)
					: [],
				groupIds: isEdit
					? eventObj?.eventAccesses?.filter((access) => access?.accessType === "GROUP")?.map((access) => access?.accessTypeEntityId)
					: [],
				redirectLink: isEdit ? eventObj?.redirectLink || "" : "",
				displayDate: isEdit ? eventObj?.displayDate || "" : "",
				showDate: isEdit ? eventObj?.showDate : null,
				eventDate: isEdit ? eventObj?.eventDate : null,
				deadlineDate: isEdit ? eventObj?.deadlineDate : null,
				hideDate: isEdit ? eventObj?.hideDate : null,
			});

			if (isEdit) {
				const metaData = eventObj?.metadata && JSON.parse(eventObj?.metadata);
				if (metaData?.length > 0) {
					const heroImgData = metaData[0];
					const cardImgData = metaData[1];
					setHeroImageName(heroImgData.name);
					setHeroImageSize(heroImgData.size);
					setCardImageName(cardImgData.name);
					setCardImageSize(cardImgData.size);
				} else {
					setHeroImageName("Untitled Image");
					setHeroImageSize(0);
					setCardImageName("Untitled Image");
					setCardImageSize(0);
				}
			}
		}
	}, [open]);

	const getEventForm = async () => {
		setformLoading(true);

		const promises = [axios.get(getPartnerFormsUrl(partnerCode))];

		if (isEdit) promises.push(axios.get(getAssignedFormByEntityIdUrl({ entityType: "EVENT", entityId: eventObj?.id })));

		const results = await Promise.allSettled(promises);

		results.forEach((result, index) => {
			if (index === 0) {
				if (result?.value?.data) {
					setforms(result?.value?.data);
				} else {
					setforms([]);
				}
			} else if (index === 1) {
				if (result?.value?.data) {
					setformObj(result?.value?.data);
					setValue("selectedForm", result?.value?.data?.id);
				} else {
					setformObj(null);
				}
			}
		});
		setformLoading(false);
	};

	const handleChange = (event, key, onChange) => {
		if (key === "companySize" || key === "noOfCoFounders") {
			let newValue = event.target.value;
			onChange(newValue.replace(/[^0-9]/g, ""));
		} else if (key === "isPitchEvent" || key === "isPremiumEvent" || key === "isFeatureEvent" || key === "isPrivateEvent") {
			onChange(event.target.checked);
		} else if (key === "displayDate" || key === "showDate" || key === "eventDate" || key === "deadlineDate" || key === "hideDate") {
			onChange(event);
		} else if (key === "description") {
			onChange(event);
		} else {
			console.log(event);
			onChange(event.target.value);
		}
	};

	const processEventAccessPayload = () => {
		const privateEventAccess = [];

		const companyIds = getValues("companyIds");
		const groupIds = getValues("groupIds");

		// Add company access entries if companyIds are provided
		if (companyIds?.length > 0) {
			companyIds?.forEach((companyId) => {
				privateEventAccess.push({
					accessType: "COMPANY",
					accessTypeEntityId: companyId,
					permission: "READ",
				});
			});
		}

		// Add group access entries if groupIds are provided
		if (groupIds?.length > 0) {
			groupIds?.forEach((groupId) => {
				privateEventAccess.push({
					accessType: "GROUP",
					accessTypeEntityId: groupId,
					permission: "READ",
				});
			});
		}

		return privateEventAccess;
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			let link = getValues("redirectLink");
			if (!getValues("redirectLink")?.startsWith("https://") && !getValues("redirectLink")?.startsWith("http://")) {
				link = "https://" + getValues("redirectLink");
			}
			const payload = {
				title: getValues("title"),
				description: getValues("description"),
				imageUrl: cardImage,
				heroImageUrl: heroImage,
				redirectLink: getValues("redirectLink") ? link : null,
				isPitchEvent: getValues("isPitchEvent"),
				isPremiumEvent: getValues("isPremiumEvent"),
				isFeatureEvent: getValues("isFeatureEvent"),
				privateEventAccess: processEventAccessPayload(),
				displayDate: getValues("displayDate"),
				showDate: getValues("showDate") && moment(getValues("showDate")).format("YYYY-MM-DD"),
				eventDate: getValues("eventDate") && moment(getValues("eventDate")).format("YYYY-MM-DD"),
				deadlineDate: getValues("deadlineDate") && moment(getValues("deadlineDate")).format("YYYY-MM-DD"),
				hideDate: getValues("hideDate") && moment(getValues("hideDate")).format("YYYY-MM-DD"),
				metadata: JSON.stringify([heroImageData, cardImageData]),
			};
			let resData = null;

			if (isEdit) {
				const promises = [services.updateEventApi(payload, eventObj?.uuid)];
				if (selectedForm)
					promises.push(
						axios.post(assignFormUrl(), {
							entityType: "EVENT",
							entityId: eventObj?.id,
							formId: selectedForm,
						})
					);
				const results = await Promise.allSettled(promises);
				results.forEach((result, index) => {
					if (index === 0) {
						if (result?.value?.data) {
							resData = result?.value?.data;
						}
					}
				});
			} else {
				resData = await services.createEventApi(payload);
				await axios.post(assignFormUrl(), {
					entityType: "EVENT",
					entityId: resData?.data?.data?.id,
					formId: selectedForm,
				});
			}
			if (resData) {
				onClose();
				reset();
				setHeroImage("");
				setCardImage("");
				getEventList();
				toastContainer(isEdit ? messages.EVENT_UPDATED_SUCCESS : messages.EVENT_CREATED_SUCCESS);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastContainer(err?.response.data?.message, "error");
		}
	};
	const CommonDatePicker = ({ value, type, onChange, disableFuture = true, disablePast = false, maxDate, minDate }) => {
		const [openDatePicker, setOpenDatePicker] = useState(false);
		const datePickerClick = () => {
			if (openDatePicker === false) {
				setOpenDatePicker(true);
			}
		};
		return (
			<Box onClick={datePickerClick}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DatePicker
						open={openDatePicker}
						onClose={() => setOpenDatePicker(false)}
						format="MM/DD/YYYY"
						maxDate={maxDate}
						minDate={minDate}
						value={value ? moment(value) : null}
						disableFuture={disableFuture}
						disablePast={disablePast}
						onChange={(newValue) => {
							handleChange(moment(newValue).toDate(), type, onChange);
						}}
						slots={{
							OpenPickerIcon: CalendarTodayOutlinedIcon,
						}}
						sx={{
							cursor: "pointer",
							"& .MuiOutlinedInput-root": {
								p: "6px",
							},
							"& input::placeholder": {
								fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
								fontSize: "16px",
								lineHeight: "24px",
							},
							"& .MuiInputBase-root": {
								fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
								fontSize: "16px",
								lineHeight: "24px",
								height: "60px",
								width: "100%",
								borderRadius: "30px",
								background: "white",
							},

							"&.MuiTextField-root": {
								width: "100%",
								borderRadius: "30px",
								background: "white",
							},
						}}
						slotProps={{
							openPickerButton: {
								sx: {
									mr: "5px",
								},
							},
							field: {
								InputProps: {
									endAdornment: (
										<InputAdornment position="end" sx={{ mr: 1.5 }}>
											<IconButton edge="end">
												<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
											</IconButton>
										</InputAdornment>
									),
									variant: "outlined",
									sx: {
										borderRadius: "30px",
										height: "60px",
										fontWeight: "500",
										fontSize: "16px",
										lineHeight: "24px",
										padding: "1.5px 6px",
									},
								},
							},
						}}
					/>
				</LocalizationProvider>
			</Box>
		);
	};

	const handleSelectFormListOpen = () => {
		setselectedFormListOpenState(true);
	};
	const handleSelectFormListClose = () => {
		setselectedFormListOpenState(false);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<CommonSlideDialog
				open={open}
				slidingEffect={false}
				onClose={onClose}
				keepMounted={false}
				title={
					<Box display="flex" flexDirection="column" p="0 0 8px">
						<Typography variant="Text/md/Semibold" sx={{ mt: 1.5 }}>
							{`New Event`}
						</Typography>
					</Box>
				}
				titleVariant="Text/md/Semibold"
				maxWidth="md"
				btnCloseStyle={{ margin: "0 24px 0 0" }}
				dialogAction={
					<Box display={"flex"} alignItems={"center"} gap={2}>
						<Button onClick={onClose} sx={{ color: (theme) => theme.palette.secondary.light }} variant="noOutlineNew">
							Cancel
						</Button>
						<Button
							startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
							variant="DS1"
							onClick={handleSubmit(onSubmit)}
							disabled={loading}
						>
							{isEdit ? "Save" : "Create"}
						</Button>
					</Box>
				}
				PaperProps={{
					sx: {
						position: "fixed",
						bottom: 0,
						right: 0,
						top: 0,
						m: 0,
						borderRadius: "24px 0px 0px 24px",
						boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
						minHeight: 1,
						width: "100%",
					},
				}}
				footerStyle={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 2 }, pt: 0 }}
				scroll_type="paper"
			>
				{" "}
				<Box>
					{" "}
					<Grid container rowSpacing={2} columnSpacing={7}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{`${
								isEdit ? "Edit an existing " : "Create a new "
							}Event by filling out the details below`}</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Box
								display="flex"
								gap={2}
								marginTop={"20px"}
								flexDirection={isSmallScreen ? "column" : "row"}
								alignItems={isSmallScreen ? "center" : "normal"}
							>
								<EventImageUploader
									title={"Hero Image"}
									instructions={"Your Profile photo will be displayed is a circular view."}
									shape={"rectangle"}
									uploadedImage={heroImage}
									setUploadedImage={(item) => setHeroImage(item)}
									circularCropper={false}
									aspectRatio={5 / 2}
									fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
									readOnly={false}
									sx={{ border: "1px solid #EAECF0", borderRadius: "12px", width: 1 }}
									imageSizeInstruction={"PNG, JPG or JPEG (max. 800x400px)"}
									setImageData={setHeroImageData}
									imgName={heroImageName}
									imgSize={heroImageSize}
									setImgName={setHeroImageName}
									setImgSize={setHeroImageSize}
								/>

								<EventImageUploader
									title={"Card Image"}
									instructions={"Your Profile photo will be displayed is a circular view."}
									shape={"rectangle"}
									uploadedImage={cardImage}
									setUploadedImage={(item) => setCardImage(item)}
									circularCropper={false}
									aspectRatio={5 / 3}
									fileType={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
									readOnly={false}
									sx={{ border: "1px solid #EAECF0", width: 1 }}
									imageSizeInstruction={"PNG, JPG or JPEG (max. 338x216px)"}
									setImageData={setCardImageData}
									imgName={cardImageName}
									imgSize={cardImageSize}
									setImgName={setCardImageName}
									setImgSize={setCardImageSize}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{"Title*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="title"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<UpdatedTextInputField
											control={control}
											id={"title"}
											value={value}
											inputProps={{ maxLength: 255 }}
											name={name}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
												},
											}}
											handleChange={onChange}
											placeholder={"Type here"}
											textFieldProps={{
												fullwidth: true,

												width: "100%",
												height: "60px",
											}}
										/>
									)}
								/>
								{errors.title && (
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors.title.message}
									</Typography>
								)}
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{"Description*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="description"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<Box sx={{ backgroundColor: "white", borderRadius: "30px", p: 2, border: "1px solid #D0D5DD" }}>
											<ReusableQuill
												id={"event-description"}
												value={value}
												placeholder={"Type here"}
												onChange={(e) => handleChange(e, "description", onChange)}
												className={"profile-container"}
											/>
										</Box>
									)}
								/>
								{errors.description && (
									<Typography variant="subhead2" sx={{ color: "#F04438" }}>
										{errors.description.message}
									</Typography>
								)}
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="isPitchEvent"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="Text/sm/Medium">{"Pitch Event?"}</Typography>

										<Switch
											color="primary"
											onChange={(e) => handleChange(e, "isPitchEvent", onChange)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
						</Grid>{" "}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="isPremiumEvent"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="Text/sm/Medium">{"Premium Event?"}</Typography>

										<Switch
											color="primary"
											onChange={(e) => handleChange(e, "isPremiumEvent", onChange)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
						</Grid>{" "}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="isFeatureEvent"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="Text/sm/Medium">{"Feature Event?"}</Typography>

										<Switch
											color="primary"
											onChange={(e) => handleChange(e, "isFeatureEvent", onChange)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
						</Grid>{" "}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography variant="Text/sm/Medium">Attach Registration Form</Typography>
								{formLoading ? (
									<Skeleton animation="wave" variant="rounded" height={50} sx={{ my: 2 }} />
								) : (
									<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
										<Controller
											name="selectedForm"
											control={control}
											render={({ field: { onChange, value, name } }) => (
												<Select
													fullWidth
													displayEmpty
													onChange={(e) => handleChange(e, "selectedForm", onChange)}
													value={value || ""}
													onOpen={handleSelectFormListOpen}
													onClose={handleSelectFormListClose}
													open={selectedFormListOpenState}
													sx={{
														my: 1,
														borderRadius: "100px",
														backgroundColor: "#FFFFFF",
														border: "1px solid #D0D5DD",
														boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)", // Initial shadow
														"&.Mui-focused": {
															boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF", // Shadow when focused
															borderColor: "#F4EBFF", // Highlighted border color on focus
														},
														"& .MuiOutlinedInput-notchedOutline": {
															border: "none", // Remove the default border
														},
													}}
													renderValue={(selected) => {
														if (!selected || selected?.length === 0) {
															return (
																<Typography variant="Text/sm/Medium" sx={{ color: "#667085" }}>
																	Select Option
																</Typography>
															);
														}

														// Assuming options is passed or available in this context
														const selectedOption = forms?.find((option) => option?.id === selected);

														// If a matching option is found, display its label
														if (selectedOption) {
															return <Typography variant="Text/sm/Regular">{selectedOption?.name}</Typography>;
														}

														// Default fallback in case the option is not found
														return (
															<Typography variant="Text/sm/Medium" sx={{ color: "#667085" }}>
																Select Option
															</Typography>
														);
													}}
												>
													{forms?.map((option, index) => (
														<MenuItem
															key={index}
															value={option?.id}
															sx={{
																"&:hover": {
																	backgroundColor: "#F9FAFB", // Hover background color for options
																},
																"&.Mui-selected": {
																	backgroundColor: "#F9FAFB", // Background color for the selected option
																	color: "#6941C6", // Text color for the selected option
																},
																"&.Mui-selected:hover": {
																	backgroundColor: "#F9FAFB", // Ensure hover and selected states match
																},
															}}
														>
															<Typography variant="Text/sm/Regular">{option?.name}</Typography>
														</MenuItem>
													))}
													<MenuItem
														key={"createForm"}
														disableRipple
														sx={{
															"&:hover": {
																backgroundColor: "unset",
															},
															"&.Mui-selected": {
																backgroundColor: "unset",
															},
															"&.Mui-selected:hover": {
																backgroundColor: "unset",
															},
														}}
													>
														<Button
															variant="DS1"
															small
															fullWidth
															onClick={() => {
																setValue("selectedForm", "");
																handleSelectFormListClose();
																setcreateFormOpen(true);
															}}
														>
															Create Form
														</Button>
													</MenuItem>
												</Select>
											)}
										/>

										{selectedForm && (
											<Button
												variant="DS1"
												onClick={() => {
													setpreviewFormOpen(true);
												}}
											>
												Preview
											</Button>
										)}
										{selectedForm && (
											<Box sx={{ pl: 1, borderLeft: "2px solid lightgray" }}>
												<Button
													variant="DS1"
													iconButton
													small
													color="destructive"
													onClick={() => {
														setValue("selectedForm", "");
													}}
												>
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
														<path
															d="M21.3333 7.99996V6.93329C21.3333 5.43982 21.3333 4.69308 21.0427 4.12265C20.787 3.62089 20.3791 3.21294 19.8773 2.95728C19.3069 2.66663 18.5601 2.66663 17.0667 2.66663H14.9333C13.4399 2.66663 12.6931 2.66663 12.1227 2.95728C11.6209 3.21294 11.213 3.62089 10.9573 4.12265C10.6667 4.69308 10.6667 5.43982 10.6667 6.93329V7.99996M13.3333 15.3333V22M18.6667 15.3333V22M4 7.99996H28M25.3333 7.99996V22.9333C25.3333 25.1735 25.3333 26.2936 24.8974 27.1493C24.5139 27.9019 23.9019 28.5138 23.1493 28.8973C22.2936 29.3333 21.1735 29.3333 18.9333 29.3333H13.0667C10.8265 29.3333 9.70635 29.3333 8.8507 28.8973C8.09806 28.5138 7.48613 27.9019 7.10264 27.1493C6.66667 26.2936 6.66667 25.1735 6.66667 22.9333V7.99996"
															stroke="white"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
													</svg>
												</Button>
											</Box>
										)}
										{selectedForm && (
											<ViewFormFlyout
												open={previewFormOpen}
												formString={forms?.find((obj) => obj?.id === selectedForm)?.formStructure}
												onClose={() => {
													setpreviewFormOpen(false);
												}}
											/>
										)}
										<ManageFormFlyout
											open={createFormOpen}
											editMode={false}
											partnerCode={partnerCode}
											form={null}
											onClose={(formId) => {
												setcreateFormOpen(false);
												getEventForm();
												if (formId) setValue("selectedForm", formId);
											}}
										/>
									</Box>
								)}
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="isPrivateEvent"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box
										display={"flex"}
										justifyContent={"space-between"}
										alignItems={"center"}
										sx={{
											...(errors?.isPrivateEvent?.message && {
												ml: "-10px",
												pl: "10px",
												py: "10px",
												borderRadius: "12px",
												border: "1px solid #FDA29B",
												background: "white",
												boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2",
											}),
										}}
									>
										<Box>
											<Typography
												variant="Text/sm/Medium"
												component={"p"}
												sx={{
													...(errors?.isPrivateEvent?.message && {
														color: (theme) => theme?.palette?.error?.main,
													}),
												}}
											>
												Private Event?
											</Typography>
											<Typography variant="Text/xs/Regular" component={"p"}>
												Limit event availability to specific companies and groups.
											</Typography>
										</Box>

										<Switch
											color="primary"
											onChange={(e) => handleChange(e, "isPrivateEvent", onChange)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
							<Box mt={1}>
								<Typography variant="Text/xs/Regular" color="error">
									{errors?.isPrivateEvent?.message}
								</Typography>
							</Box>
						</Grid>
						{isPrivateEvent && (
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<GroupsTable
									partnerCode={partnerCode}
									selectedIds={getValues("groupIds")}
									updatedSelection={(ids) => setValue("groupIds", ids)}
								/>
								<CompanyGroupTable
									partnerCode={partnerCode}
									selectedIds={getValues("companyIds")}
									updatedSelection={(ids) => setValue("companyIds", ids)}
								/>
							</Grid>
						)}
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Redirection Link"}</Typography>
							<Controller
								name="redirectLink"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<UpdatedTextInputField
										id={name}
										handleChange={(e) => handleChange(e, "redirectLink", onChange)}
										inputValue={value && value.replace("https://", "").replace("http://", "")}
										placeholder={name}
										textFieldProps={{
											fullwidth: true,
											width: "100%",
											height: "60px",
										}}
										sx={{ mt: 1 }}
										InputProps={{
											startAdornment: (
												<Typography component={"p"} sx={{ pl: 2 }} variant="title_medium">
													https://
												</Typography>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Display Date*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="displayDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<UpdatedTextInputField
											control={control}
											id={"displayDate"}
											value={value}
											name={name}
											inputProps={{ maxLength: 255 }}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
												},
											}}
											handleChange={onChange}
											placeholder={"Display Date"}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
										/>
									)}
								/>{" "}
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The ‘friendly’ date to show on the events page.{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Event Date*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="eventDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type={"eventDate"}
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={null}
											minDate={deadlineDate && moment(deadlineDate)}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to show when the event happened.{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Start Date"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="showDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type="showDate"
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={(deadlineDate && moment(deadlineDate)) || (eventDate && moment(eventDate))}
											minDate={null}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to start showing this event on the events page for users.{" "}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Deadline to Apply to Pitch/Register for Event*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="deadlineDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type="deadlineDate"
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={eventDate && moment(eventDate).subtract(1, "days")}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to disable the “Apply to Pitch” button or “Register for Event”
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Hide Date"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="hideDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDatePicker
											value={value}
											type="hideDate"
											disableFuture={false}
											disablePast={true}
											onChange={onChange}
											minDate={getValues("showDate") && moment(getValues("showDate")).add(1, "days")}
										/>
									)}
								/>
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date to hide this event on the events page for users.{" "}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</CommonSlideDialog>
		</form>
	);
}
