import React, { useMemo, useState } from "react";
import { Box, Typography, Button, CircularProgress, IconButton, Chip } from "@mui/material";
import {
	ARROW_UP_RIGHT_ICON_V1,
	CALENDAR_BLANK_BLACK,
	CHECK_CIRCLE_WAVY_LIGHT,
	COMMUNITY_CARD_PLACEHOLDER,
	EYE_OFF_BLACK_FILLED,
	HEART_OUTLINED_EMPTY_ICON,
	HEART_PINK_FILLED_ICON,
	MESSAGES_BLACK_ICON,
	NEW_LINKEDIN_ICON,
} from "../../../constants";
import { get } from "lodash";
import { USER_TYPES } from "../../../utils/userTypes";
import CompanyInfo from "./component/CompanyInfo";
import { GravatarURL } from "../../../constants/profile-data";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import MentorInfo from "./component/MentorInfo";
import InvestorInfo from "./component/InvestorInfo";
import IndustryDetailsDialog from "../../Investor/IndustryDetails";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import AcceleratorsProgramsDialog from "./component/AcceleratorsPrograms";

function NetworkingCard({
	matched,
	onSaveIconClick,
	onMessageClick,
	onCalendarClick,
	redirectToMatched,
	currentTab,
	onHideIconClick,
	onReqProxyInvestorConnectClick = () => {},
	setShowPlanModal,
}) {
	const isAlumni = matched?.userBadges?.[0] || null;
	const [isPictureError, setPictureError] = useState(false);
	const dispatch = useDispatch();
	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userEmail: store?.auth?.email,
		isPremiumPlanAccess: store?.auth?.isPremiumPlanAccess,
		isAdmin: store?.auth?.isAdmin,
	}));

	const name = get(matched, "name", "");
	const firstName = get(matched, "firstName", "");
	const lastName = get(matched, "lastName", "");
	const linkedInUrl = get(matched, "linkedInUrl", "");
	const profession = get(matched, "profession", "");

	const companyData = get(matched, "companyModel", []);
	const createdDate = get(matched, "createdAt", "");
	const connectionStatus = get(matched, "connectionStatus", "");

	const [sendingReq, setSendingReq] = useState(false);
	const [isRequestSent, setIsRequestSent] = useState(false);
	const [openAcceleratorsDialog, setOpenAcceleratorsDialog] = useState(false);

	const displayPicture = useMemo(() => {
		return matched?.picture;
	}, [matched?.picture, isPictureError]);

	const getPlaceHolderForUser = () => {
		console.log(matched?.picture, matched?.picture?.includes(GravatarURL), isPictureError);
		return (
			<Box>
				<Box
					component="img"
					src={COMMUNITY_CARD_PLACEHOLDER}
					sx={{
						width: 1,
						position: "relative",
						borderRadius: (theme) => theme.shape.standard5.borderRadius,
					}}
					onError={(e) => {
						setPictureError(true);
					}}
				/>
				<Typography
					variant="Text/3xl/Semibold"
					sx={{ color: "rgba(102, 112, 133, 1)", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
				>
					{`${firstName?.capitalize()?.charAt(0) ?? name?.split(" ")[0]?.toUpperCase()?.charAt(0) ?? ""}${
						lastName?.capitalize()?.charAt(0) ?? name?.split(" ")[1]?.toUpperCase()?.charAt(0) ?? ""
					}`}{" "}
				</Typography>
			</Box>
		);
	};

	const isSaved = get(matched, "isSaved", false);
	const userTypes = get(matched, "userTypes", []) ?? [];

	const LinkedInProfileIcon = ({ url }) => {
		const regex = /^https:\/\/www\.linkedin\.com\/in\/(?!$).+/;

		if (regex.test(url)) {
			return (
				<img
					src={NEW_LINKEDIN_ICON}
					style={{
						height: "28px",
						width: "28px",
						stroke: "#12B76A",
						cursor: "pointer",
					}}
					onClick={() => window.open(url, "_blank")}
				/>
			);
		} else {
			return "";
		}
	};
	const OpenAcceleratorsProgramsDialog = () => {
		setOpenAcceleratorsDialog(true);
	};
	const IconButtons = ({ handleClick, icon }) => {
		return (
			<IconButton
				sx={{
					backgroundColor: "rgba(242, 244, 247, 1)",
					height: "36px",
					width: "36px",
					padding: 1,
					borderRadius: "50%",
				}}
				onClick={handleClick}
			>
				{" "}
				<Box
					component={"img"}
					src={icon}
					alt="message"
					sx={{
						height: "20px",
						width: "20px",
						cursor: "pointer",
					}}
				/>
			</IconButton>
		);
	};

	const getAccelerators = () => {
		try {
			return JSON.parse(matched?.companyModel?.incubator);
		} catch (error) {
			return [];
		}
	};

	if (currentTab === USER_TYPES.ALUMNI_USER && !isAlumni) {
		return null; // Don't render the component if currentTab is "alumni" and isAlumni is false
	}
	return (
		<>
			{" "}
			<Box
				display="flex"
				flexDirection="column"
				sx={{
					py: 4,
					backgroundColor: (theme) => theme.palette.secondary.white,
					borderRadius: (theme) => theme.shape.standard5.borderRadius,
					position: "relative",
					"&:hover .hide-icon,& .MuiBox-root:hover .hide-icon": {
						display: "block",
					},
					flex: 1,
				}}
			>
				<Box
					sx={{ position: "absolute", top: 30, right: 30, display: "none", cursor: "pointer", zIndex: 2, height: "48px", width: "48px" }}
					className="hide-icon"
					component="img"
					src={EYE_OFF_BLACK_FILLED}
					alt="eye-off-black"
					onClick={() => onHideIconClick?.(matched, currentTab === "HIDDEN")}
				/>
				{isAlumni ? (
					<Chip
						label={
							<Typography variant="Text/xs/Regular" sx={{ color: (theme) => theme.palette.primary.primaryPurple }}>
								Alumni
							</Typography>
						}
						variant="outlined"
						sx={(theme) => ({
							border: `1px solid ${theme.palette.primary.primaryPurple}`,
							background: theme.palette.secondary.white,
							position: "absolute",
							left: "45px",
							top: "45px",
							zIndex: 1,
						})}
					/>
				) : (
					""
				)}
				<Box
					sx={{
						px: 4,
						position: "relative",
						opacity: currentTab === "HIDDEN" ? 0.5 : 1,
						pb: "1px",
					}}
				>
					{!matched?.picture || matched?.picture?.includes(GravatarURL) || isPictureError ? (
						getPlaceHolderForUser()
					) : (
						<Box
							component="img"
							src={displayPicture}
							sx={{
								width: 1,
								borderRadius: (theme) => theme.shape.standard5.borderRadius,
								position: "relative",
							}}
							onError={(e) => {
								console.log("HERE IS ERROR:", e);
								setPictureError(true);
							}}
						/>
					)}

					{userTypes?.includes(USER_TYPES.STARTUP_OWNER) && matched?.companyModel?.isPremiumPlanAccess && (
						<Chip
							label={
								<Typography variant="Text/xs/Regular" sx={{ color: (theme) => theme.palette.primary.primaryPurple }}>
									Premium
								</Typography>
							}
							variant="outlined"
							sx={(theme) => ({
								border: `1px solid ${theme.palette.primary.primaryPurple}`,
								background: theme.palette.secondary.white,
								position: "absolute",
								left: "50px",
								top: "16px",
							})}
						/>
					)}
				</Box>
				<Box
					sx={{
						backgroundColor: (theme) => theme.palette.secondary.white,
						paddingTop: "10px",
						opacity: currentTab === "HIDDEN" ? 0.5 : 1,
					}}
				>
					<Box display="flex" flexDirection="column" rowGap={1} sx={{ pb: 6 }}>
						<>
							{(userTypes?.includes(USER_TYPES.STARTUP_OWNER) || userTypes?.includes(USER_TYPES.MENTOR_USER)) && (
								<Box display="flex" alignItems="center" sx={{ px: 4 }} gap={1}>
									<IconButtons
										handleClick={() => onSaveIconClick?.(matched, isSaved ?? false)}
										icon={!isSaved ? HEART_OUTLINED_EMPTY_ICON : HEART_PINK_FILLED_ICON}
									/>

									{userData?.userId !== matched?.id && ( //not my card
										<>
											{!userTypes?.includes(USER_TYPES.INVESTOR_USER) && (
												<>
													<IconButtons handleClick={() => onCalendarClick?.()} icon={CALENDAR_BLANK_BLACK} />
													<IconButtons handleClick={() => onMessageClick?.(matched)} icon={MESSAGES_BLACK_ICON} />
												</>
											)}
										</>
									)}
									{isAlumni && matched?.companyModel?.incubator && (
										<IconButton
											onClick={() => OpenAcceleratorsProgramsDialog()}
											sx={{
												backgroundColor: "rgba(242, 244, 247, 1)",
												height: "36px",
												width: "36px",
												padding: 1,
												borderRadius: "50%",
											}}
										>
											<KeyboardDoubleArrowRightRoundedIcon sx={{ color: "#344054" }} />
										</IconButton>
									)}
									{linkedInUrl && (
										<Box sx={{ ml: "auto" }}>
											<LinkedInProfileIcon url={linkedInUrl} />
										</Box>
									)}
								</Box>
							)}
							<Box sx={{ px: 4 }}>
								{" "}
								<Box display="flex" alignItems="center" gap={1} sx={{ minHeight: "30px", height: "30px", cursor: "pointer" }}>
									<Typography
										onClick={() => redirectToMatched(currentTab, matched?.id, matched)}
										variant="Text/sm/Semibold"
										sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
									>{`${name ? name : "No Data"}`}</Typography>
									<Box
										component={"img"}
										onClick={() => redirectToMatched(currentTab, matched?.id, matched)}
										src={ARROW_UP_RIGHT_ICON_V1}
										alt="arrow"
									/>
								</Box>{" "}
								{userTypes?.includes(USER_TYPES.CORPORATE_USER) && (
									<Box sx={{ minHeight: "30px", height: "30px" }}>
										<Typography variant="Text/sm/Semibold" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{`${
											profession || ""
										}`}</Typography>
									</Box>
								)}
							</Box>

							<Box sx={{ px: 4 }}>
								{userTypes?.includes(USER_TYPES.STARTUP_OWNER) ? (
									<CompanyInfo companyData={companyData} userTypes={userTypes} />
								) : userTypes?.includes(USER_TYPES.MENTOR_USER) ? (
									<MentorInfo matched={matched} />
								) : (
									<InvestorInfo matched={matched} />
								)}
							</Box>

							{(userTypes?.includes(USER_TYPES.STARTUP_OWNER) || userTypes?.includes(USER_TYPES.MENTOR_USER)) && (
								<Box
									sx={{
										position: "absolute",
										left: "50%",
										transform: "translate(-50%, -50%)",
										bottom: "-26px",
										width: 1,
										textAlign: "center",
										backgroundColor: "rgba(234, 236, 240, 1)",
										borderRadius: "0px 0px 16px 16px",
									}}
								>
									<Typography variant="Text/xs/Regular" sx={{ color: "rgba(102, 112, 133, 1)", display: "block", p: "12px" }}>
										{" "}
										Joined {moment(createdDate).format("MMM D, YYYY")}
									</Typography>
								</Box>
							)}
							{userTypes?.includes(USER_TYPES.INVESTOR_USER) && (
								<Box display="flex" justifyContent="center" sx={{ width: 1, position: "absolute", bottom: "24px" }}>
									{connectionStatus === "REQUEST_SENT" ? (
										<Box>
											<Button
												variant="GreyRounded"
												sx={{
													borderRadius: "100px",
													boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
												}}
											>
												<Typography variant="Text/xs/Semibold" color="inherit">
													Request Sent
												</Typography>
											</Button>
										</Box>
									) : (
										<Box>
											<Button
												variant="GreyRounded"
												startIcon={sendingReq && <CircularProgress size={"1.25rem"} />}
												sx={{ borderRadius: "100px" }}
												onClick={async () => {
													if (userData.isPremiumPlanAccess || userData.isAdmin) {
														handleMixPanel(`Community: Investor Request Connection clicked`, {
															userName: userData.userName,
															userId: userData.userId,
														});
														setSendingReq(true);
														await onReqProxyInvestorConnectClick(matched);
														setSendingReq(false);
														setIsRequestSent(true);
													} else {
														setShowPlanModal(true);
													}
												}}
											>
												<Typography variant="Text/xs/Semibold" color="inherit">
													Request Connection
												</Typography>
											</Button>
										</Box>
									)}
								</Box>
							)}
						</>
					</Box>
				</Box>
			</Box>
			<AcceleratorsProgramsDialog
				isOpen={openAcceleratorsDialog}
				setOpenAcceleratorsDialog={setOpenAcceleratorsDialog}
				acceleratorsPrograms={getAccelerators()}
			/>
		</>
	);
}

export default NetworkingCard;
