import axios from "axios";
import * as url from "../utils/urls";
import {
	PERSONA_BUILDER_SIDEBAR,
	STORY_TELLER_SIDEBAR,
	TAMSAMSOM_SIDEBAR,
	PROBLEM_SOLUTION_SIDEBAR,
	VALUE_PROP_SIDEBAR,
	IDEA_VALIDATION_SIDEBAR,
	REVENUE_MODELER_SIDEBAR,
	BRANDSTORMING_SIDEBAR,
} from "../constants/powerup-data";
import { USER_TYPES } from "../utils/userTypes";

export default {
	// login and register user
	login: function (payload) {
		return axios.post(url.loginUrl, payload);
	},

	//update user email
	updateEmail: function (payload) {
		return axios.post(url.updateEmailUrl, payload);
	},

	//resend user email
	resendEmail: function (payload) {
		return axios.post(url.resendEmailUrl, payload);
	},

	//search location by coordinates
	searchLocationByCoordinates: function (lat, lng) {
		return axios.get(url.searchLocationByCoordinatesUrl(lat, lng));
	},

	//search location by postalcode
	searchLocationByZipcode: function (zipcode) {
		return axios.get(url.searchLocationByZipcodeUrl(zipcode));
	},

	//search location by postalcode google api
	searchLocationByPostalCode: function (zipcode) {
		return axios.get(url.searchLocationByPostalcodeUrl(zipcode));
	},

	//get auto-complete data for onboarding product/services and journey
	autoCompleteData: function (questionKey) {
		return axios.get(url.autoCompleteDataUrl(questionKey));
	},

	addAutoCompleteData: function (data) {
		return axios.post(url.autoCompleteDataUrl(data.key), data);
	},

	//update user flag to not show the flag again after initial skip
	updateUserFlag: function (payload) {
		return axios.put(url.updateUserFlagUrl(), payload);
	},

	//update runway calculator details
	updateRunwayDetails: function (payload) {
		return axios.put(url.updateRunwayDetailsUrl(), payload);
	},

	//get dashboard stats
	getDashboardStats: function () {
		return axios.get(url.getDashboardStatsUrl());
	},

	//get todays top 3
	getTodaysTop: function () {
		return axios.get(url.getTodaysTopUrl());
	},
	// get company profile
	getCompanyProfile: function () {
		return axios.get(url.companyProfile());
	},

	// update company profile
	updateCompanyProfile: function (data) {
		return axios.put(url.companyProfile(), data);
	},

	//create public startup profile
	createPublicCompanyProfile: function (data) {
		return axios.post(url.createPublicCompanyProfile(), data);
	},

	//update public startup profile
	updatePublicCompanyProfile: function (data, requestId) {
		return axios.put(url.updateCompanyPublicProfileUrl(requestId, data?.id), data);
	},

	// get company profile
	getPublicCompanyProfile: function (companyId) {
		return axios.get(url.getCompanyPublicProfileUrl(companyId));
	},

	//get Powerups and poweruppacks for workspace page
	getPowerUpPacksList: function () {
		return axios.get(url.powerUpPacksApiUrl());
	},

	getPowerUpPacksList: function () {
		return axios.get(url.powerUpPacksApiUrl());
	},
	getPowerUpPackDetail: function (packId) {
		return axios.get(url.powerUpPacksDetailsApiUrl(packId));
	},

	getToDosList: function (group) {
		if (group) {
			return axios.get(url.todosApiUrl(), {
				params: { group: group },
			});
		}

		return axios.get(url.todosApiUrl());
	},

	getPowerThingsList: function (type, id) {
		if (type) {
			return axios.get(url.powerThingsApiUrl(), {
				params: { type: type },
			});
		}

		if (id) {
			return axios.get(url.powerThingsApiUrl(), {
				params: { id: id },
			});
		}

		return axios.get(url.powerThingsApiUrl());
		// 1. No param (get all powerup and plugin irrespective of powerpack)
		// 2. type - powerup/plugin (get only powerup or plugin)
		// 3. id (powerpack id - get powerup or plugin as per powerpack id)
	},

	storeExcerciseStep: function (data) {
		return axios.post(url.excerciseStepsUrl(), data);
	},

	getPowerupSteps: function (powerUpId) {
		return axios.get(url.excerciseStepsUrl(), {
			params: { powerUpId: powerUpId },
		});
	},

	getModulesSteps: function (moduleId) {
		return axios.get(url.modulesStepUrl(), {
			params: { id: moduleId },
		});
	},

	getExcerciseDetails: function (powerUpId) {
		return axios.get(url.getExcerciseDetailsUrl(), { params: { powerUpId } });
	},

	getModuleStepResponses: function (moduleId) {
		return axios.get(url.modulesStepResponsesUrl(moduleId));
	},

	storeModuleStepResponses: function (moduleId, data) {
		return axios.post(url.modulesStepResponsesUrl(moduleId), data);
	},
	//get to get powerup details
	getPowerupData: function (id) {
		return axios.get(url.powerupDataUrl(id));
	},

	// Update last visited powerup
	updateLastVisitedPowerup: function (payload) {
		return axios.put(url.lastVisitedPowerupDataUrl, payload);
	},

	startPowerup: function (payload) {
		return axios.post(url.startPowerupDataUrl, payload);
	},
	startModule: function (payload) {
		return axios.post(url.startModuleDataUrl, payload);
	},
	uploadFile: function (formData, config = {}) {
		return axios.post(url.uploadFile, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			...config,
		});
	},

	verifyEmail: function (token) {
		return axios.get(url.verifyEmail(token));
	},

	getSidebarContent: function (id) {
		switch (id) {
			case "1":
				return VALUE_PROP_SIDEBAR;
			case "2":
				return STORY_TELLER_SIDEBAR;
			case "3":
				return PERSONA_BUILDER_SIDEBAR;
			case "4":
				return IDEA_VALIDATION_SIDEBAR;
			case "5":
				return PROBLEM_SOLUTION_SIDEBAR;
			case "6":
				return BRANDSTORMING_SIDEBAR;
			case "7":
				return TAMSAMSOM_SIDEBAR;
			case "9":
				return REVENUE_MODELER_SIDEBAR;
			default:
				return {};
		}
	},
	// get powerUp packs details
	getPoweupPacksDetails: function (id) {
		return axios.get(url.powerUpPacksDeatils(id));
	},

	//get module details details
	getModuleDetails: function (id) {
		return axios.get(url.moduleDetails(id));
	},

	getInterviewCandidates: function (emails) {
		return axios.get(url.interviewCandidates(), { params: { emails: emails } });
	},

	scheduleCandidateEvent: function (payload) {
		return axios.post(url.scheduleCanidateEvent(), payload);
	},
	getAdvisorPluginDetails: function (id) {
		return axios.get(url.getAdvisorPluginDetails(id));
	},
	getAdvisorPlugineEventDetails: function (id) {
		return axios.get(url.getAdvisorPlugineEventDetails(id));
	},
	getMarketTestDetails: function (id) {
		return axios.get(url.getMarketTestDetails(id));
	},
	getCandidateDetails: function (id) {
		return axios.get(url.getCandidateDetails(id));
	},

	getCompletedPowerUps: async function (marketTestUuid) {
		return axios.get(url.getCompletedPowerUpsApiUrl(), {
			params: { marketTestUuid: marketTestUuid },
		});
	},

	createMarketTest: function (payload) {
		return axios.post(url.createMarketTestApiUrl(), payload);
	},

	deleteMarketTest: async function (marketTestUuid, testId) {
		return axios.delete(url.deleteMarketTestApiUrl(marketTestUuid, testId));
	},
	getPluginsDetails: function (id) {
		return axios.get(url.getPluginDetails(id));
	},

	getPluginsResponses: function (id) {
		return axios.get(url.pluginResponses(id));
	},

	addPluginsResponses: function (id, data) {
		return axios.post(url.pluginResponses(id), data);
	},

	updatePluginsResponses: function (id, responseId, data) {
		return axios.put(url.pluginResponses(id), data, { params: { responseId } });
	},

	sendInterviewInvite: function (data) {
		return axios.post(url.sendInterviewInvite(), data);
	},

	createMarketTest: function (marketTestUuid, payload) {
		return axios.post(url.createMarketTestApiUrl(marketTestUuid), payload);
	},

	getMarketTestList: async function (marketTestUuid) {
		return axios.get(url.marketTestListApiUrl(marketTestUuid));
	},

	getMarketTestResponses: (marketTestUuid, responseId) => {
		return axios.get(url.marketTestResponses(marketTestUuid, responseId));
	},

	getModuleResponseDetailsByResponseId: (moduleId, responseId) => {
		return axios.get(url.moduleResponseDetailsByResponseId(moduleId, responseId));
	},

	completeMarketTest: (marketTestUuid) => {
		return axios.post(url.marketTestComplete(marketTestUuid));
	},
	chckEmail: (email) => {
		return axios.get(url.checkEmail(email));
	},
	getMentorMatches: async function (page, searchText) {
		return axios.get(url.getMentorMatchesApiUrl(), {
			params: { page, searchText },
		});
	},
	getAvailableMentors: async function (page, searchText, type) {
		return axios.get(url.getAvailableMentorsApiUrl(), {
			params: { page, searchText, type },
		});
	},
	getUserProfile: function () {
		return axios.get(url.userProfile());
	},
	updateUserProfile: function (payload) {
		return axios.put(url.userProfile(), payload);
	},

	getStartupDetails: (companyId) => {
		return axios.get(url.startupDetails(companyId));
	},
	getStartupCardDetails: (companyId) => {
		return axios.get(url.startupCardDetails(companyId));
	},
	saveStartup: (data) => {
		return axios.post(url.startupCollection(), data);
	},
	userOnboarding: (data) => {
		return axios.post(url.userOnboarding(), data);
	},
	userOnboard: (data) => {
		return axios.put(url.userOnboard(), data);
	},
	addMentorExperience: (data) => {
		return axios.post(url.addMentorExperienceApiUrl(), data);
	},
	updateMentorExperience: (data, id) => {
		return axios.put(url.updateMentorExperienceApiUrl(id), data);
	},
	deleteMentorExperience: (id) => {
		return axios.delete(url.updateMentorExperienceApiUrl(id));
	},

	deleteAllMentorExperience: () => {
		return axios.delete(url.updateAllMentorExperienceApiUrl());
	},

	deleteAllMentorEducation: () => {
		return axios.delete(url.updateAllMentorEducationApiUrl());
	},

	addMentorEducation: (data) => {
		return axios.post(url.addMentorEducationApiUrl(), data);
	},
	updateMentorEducation: (data, id) => {
		return axios.put(url.updateMentorEducationApiUrl(id), data);
	},
	deleteMentorEducation: (id) => {
		return axios.delete(url.updateMentorEducationApiUrl(id));
	},
	handleStartUpConnection: (data) => {
		return axios.post(url.startupConnection(), data);
	},
	updateStartUpConnection: (data) => {
		return axios.put(url.startupConnection(), data);
	},

	saveStartup: async function (payload) {
		return axios.post(url.savedStartupApiUrl(), payload);
	},

	getSavedStartupList: async function (page, searchText, type = "SAVED") {
		return axios.get(url.getSavedStartUpListApiUrl(), {
			params: { page, searchText, type },
		});
	},

	getCalendarInviteEventDetails: (eventId) => {
		return axios.get(url.calendarInviteEventDetails(eventId));
	},
	getCalendarInvites: (data) => {
		return axios.get(url.getCalendarInvitesUrl(), data);
	},

	addCandidate: (data) => {
		return axios.post(url.platformInviteUrl(), data);
	},

	deleteEvent: async function (eventId) {
		return axios.delete(url.deleteEvent(eventId));
	},

	getCandidateProfileDetails: function () {
		return axios.get(url.candidateDetails());
	},

	updateCandidateProfileDetails: (data) => {
		return axios.put(url.candidateDetails(), data);
	},

	candidateInvitationDetails: (id) => {
		return axios.get(url.candidateInvitationDetails(id));
	},

	updateCalendarInviteEvent: (eventId, data) => {
		return axios.put(url.calendarInviteEventDetails(eventId), data);
	},

	addUserReview: (data) => {
		return axios.post(url.addReviewUrl(), data);
	},
	getReviewsList: (perpage, page, sort, filter, entityID, entityType, details) => {
		return axios.get(url.getReviewList(perpage, page, sort, filter, entityID, entityType, details));
	},
	getMyWorkPowerups: function (id) {
		if (id) {
			return axios.get(url.getMyWorkPowerups(), {
				params: { id },
			});
		}

		return axios.get(url.getMyWorkPowerups());
	},

	getWorkHistoryTasksApi: function (packId) {
		return axios.get(url.getWorkHistoryTaskUrl(), { params: { packId } });
	},

	getMyWorkDetails: (id, page, sort, filter, archived, status) => {
		return axios.get(url.getMyWorkDetails(), {
			params: { id, sort, filter, archived, status },
		});
	},

	archiveModuleResponse: (data) => {
		return axios.put(url.archiveUrl(), data);
	},
	deleteArchiveModuleResponse: async function (responseId) {
		return axios.delete(url.deleteArchiveModuleResponse(responseId));
	},
	deleteAllArchiveModuleResponse: async function () {
		return axios.delete(url.archiveUrl());
	},
	getArchivedList: (page, sort, filter) => {
		return axios.get(url.getArchivedList(page, sort, filter));
	},

	archiveApiCall: (data) => {
		return axios.put(url.archiveUrl(), data);
	},

	getTeamMembersDetails: (page, perPage) => {
		return axios.get(url.getTeamMembersDetails(), {
			params: { page, perPage },
		});
	},
	// Send Team Member Invite Api call
	sendTeamMemberInviteApiCall: (data) => {
		return axios.post(url.sendTeamMemberInviteUrl(), data);
	},

	// get invited team Members Api call
	getTeamMemberInviteApiCall: () => {
		return axios.get(url.sendTeamMemberInviteUrl());
	},

	// resend Invitation Api call
	resendInvitationApiCall: (data) => {
		return axios.post(url.resendTeamMemberInviteUrl(), data);
	},

	// delete Invitation Api call
	deleteInvitationApiCall: (invitationId) => {
		return axios.delete(url.deleteInvitedTeamMemberUrl(invitationId));
	},

	linkSocialAccountApiCall: (data) => {
		return axios.post(url.linkSocialAccount(), data);
	},

	unLinkSocialAccountApiCall: (data) => {
		return axios.put(url.unLinkSocialAccount(), data);
	},

	userSignup: (data) => {
		return axios.post(url.signUp(), data);
	},

	registerUser: (data) => {
		return axios.post(url.register(), data);
	},

	updateUserProfileDetailsApiCall: (data) => {
		return axios.put(url.updateUserProfileDetailsUrl(), data);
	},
	changeEmailRequest: (data) => {
		return axios.post(url.changeEmailRequest(), data);
	},
	changeEmailRequestDetails: () => {
		return axios.get(url.changeEmailRequestDetails());
	},
	resendChangeEmailRequest: (data) => {
		return axios.post(url.resendChangeEmailRequest(), data);
	},

	callbackChangeEmailRequest: (data) => {
		return axios.put(url.callbackChangeEmailRequest(), data);
	},

	verifyPasswordApi: (data) => {
		return axios.post(url.verifyPassword(), data);
	},

	changePassword: (data) => {
		return axios.put(url.changePassword(), data);
	},

	getNotesList: (page, perPage, sort, filter, search, tags, types) => {
		return axios.get(url.notesList(), {
			params: { page, perPage, sort, filter, search, tags, types },
		});
	},
	getPinnedNotesList: (page, perPage, sort, filter, search, tags, types, isPinned) => {
		return axios.get(url.pinnedNotesList(), {
			params: { page, perPage, sort, filter, search, tags, types, isPinned },
		});
	},
	getResearchCuratorDetailsApiCall: (researchCuratorId) => {
		return axios.get(url.getResearchCuratorDetailsUrl(researchCuratorId));
	},

	addResearchCuratorApiCall: (data) => {
		return axios.post(url.addResearchCuratorUrl(), data);
	},
	addLinkToResearchCuratorApiCall: (data, researchCuratorId) => {
		return axios.post(url.addLinkToResearchCuratorUrl(researchCuratorId), data);
	},
	removeLinkFromResearchCuratorApiCall: (researchCuratorId, linkId) => {
		return axios.delete(url.removeLinkFromResearchCuratorUrl(researchCuratorId, linkId));
	},

	updateResearchCuratorApiCall: (data, researchCuratorId) => {
		return axios.put(url.updateResearchCuratorUrl(researchCuratorId), data);
	},
	updateReferencesApiCall: (data, researchCuratorId) => {
		return axios.put(url.updateReferencesUrl(researchCuratorId), data);
	},

	getResearchCuratorListApiCall: () => {
		return axios.get(url.getResearchCuratorListUrl());
	},

	getNotesListPanel: (page, sort, filter, perPage) => {
		return axios.get(url.getNotesListPanel(), {
			params: { page, sort, filter, perPage },
		});
	},

	addNotesAttachment: (data, noteId) => {
		return axios.post(url.addNotesAttachment(noteId), data, {
			headers: {
				"Content-Type": "application/json",
			},
		});
	},
	deleteNotesAttachemnt: (noteId, attachmentId) => {
		return axios.delete(url.deleteNotesAttachemnt(noteId, attachmentId));
	},

	deleteNote: (noteId) => {
		return axios.delete(url.deleteNote(noteId));
	},
	getResearchCuratorReferenceList: () => {
		return axios.get(url.getResearchCuratorReferenceListUrl());
	},
	addCommentToNote: (noteId, data) => {
		return axios.post(url.noteComments(noteId), data);
	},

	getNoteComments: (noteId, page, sort, filter) => {
		return axios.get(url.noteComments(noteId), {
			params: { page, sort, filter },
		});
	},

	getTagsList: () => {
		return axios.get(url.getTagsList());
	},

	addTagToList: (noteId, data) => {
		return axios.put(url.addTagToList(noteId), data);
	},

	getNotesFilters: () => {
		return axios.get(url.notesFilters());
	},

	updateCompanyUserHideStatusApiCall: (data) => {
		return axios.put(url.updateCompanyUserHideStatusUrl(), data);
	},

	getMetadataDetails: (payload) => {
		return axios.post(url.getMetadataDetails(), payload);
	},

	getPendingConnections: ({ perPage, page }) => {
		return axios.get(url.getPendingConnections({ perPage, page }));
	},

	getStartupConnections: ({ perPage, page }) => {
		return axios.get(url.getStartupConnections({ perPage, page }));
	},

	calGetAllEventTypes: (userId) => {
		return axios.get(url.calEndPoint("event-types"), { params: { userId } });
	},
	calGetEventType: (id) => {
		return axios.get(url.calEndPoint("event-types" + "/" + id));
	},
	calUpdateEventTypes: (data, id) => {
		return axios.patch(url.calEndPoint(`event-types/${id}`), data);
	},
	calRemoveEventTypes: (data) => {
		return axios.delete(url.calEndPoint(`event-types/${data.id}`));
	},
	calAddEventTypes: (data) => {
		return axios.post(url.calEndPoint(`event-types`), data);
	},

	calBookMeeting: (data) => {
		return axios.post(url.calEndPoint(`bookings`), data);
	},

	calGetBookingDetails: (bookingId) => {
		return axios.get(url.calEndPoint(`bookings/${bookingId}`));
	},

	calGetAllBookings: (userId) => {
		return axios.get(url.calEndPoint(`bookings`), { params: { userId } });
	},

	calUpdateBooking: (data, bookingId) => {
		return axios.patch(url.calEndPoint(`bookings/${bookingId}`), data);
	},

	calCreateSchedule: (data) => {
		return axios.post(url.calEndPoint(`schedules`), data);
	},
	calGetSchedules: (userId) => {
		return axios.get(url.calEndPoint(`schedules`), { params: { userId } });
	},
	calGetAvailability: ({ userId, from, to }) => {
		return axios.get(url.calEndPoint(`availability`), { params: { userId: userId, dateFrom: from, dateTo: to } });
	},

	calUpdateSchedule: (id, data) => {
		return axios.patch(url.calEndPoint(`schedules/${id}`), data);
	},
	calUpdateAvailability: (id, data) => {
		return axios.patch(url.calEndPoint(`availabilities/${id}`), data);
	},
	calDeleteAvailability: (id) => {
		return axios.delete(url.calEndPoint(`availabilities/${id}`));
	},
	calAddAvailability: (data) => {
		return axios.post(url.calEndPoint(`availabilities`), data);
	},
	setUserNotifications: (payload) => {
		return axios.post(url.setUserNotifications(), payload);
	},
	getUserByCalUserIdApi: (calUserId) => {
		return axios.get(url.getUserByCalUserId(), { params: { calUserId } });
	},

	getControlRoleAccessApi: (email) => {
		return axios.get(url.getControlRoleAccessApiUrl(email));
	},

	calCancelBooking: (id, data) => {
		return axios.delete(url.calEndPoint(`bookings/${id}`), {
			data: data,
		});
	},
	getConversationByUserIDServiceCall: ({ friendlyName, idArray }) => {
		return axios.post(url.getConversationByUserIDEndpoint(), {
			name: friendlyName,
			userIds: idArray,
		});
	},
	calUpdateUser: (id, data) => {
		return axios.patch(url.calEndPoint(`users/${id}`), data);
	},

	getConversationByConvoIDServiceCall: (id) => {
		return axios.get(url.getConversationByConvoIDEndpoint(id));
	},
	getConversationWithSearch: (searchQuery) => {
		return axios.get(url.getConversationWithSearchURL(searchQuery));
	},

	getContactsWithSearch: (searchQuery) => {
		return axios.get(url.getContactsWithSearchURL(searchQuery));
	},

	leaveConversation: ({ userIDs, convoID }) => {
		return axios.put(url.updateConversationURL(), {
			conversationId: convoID,
			conversationAction: "LEAVE",
			userIds: userIDs,
		});
	},
	addConversation: ({ userIDs, convoID }) => {
		return axios.put(url.updateConversationURL(), {
			conversationId: convoID,
			conversationAction: "ADD_TO",
			userIds: userIDs,
		});
	},
	deleteModuleController: (responseId) => {
		return axios.delete(url.deleteModuleController(responseId));
	},

	fileController: (data) => {
		return axios.post(url.fileController(), data);
	},

	getHarmonicsController: ({ userId, linkedinUrl }) => {
		return axios.get(url.getHarmonicData({ userId, linkedinUrl }));
	},

	getSurveyDetails: (surveyId) => {
		return axios.get(url.getSurveyDetailsUrl(surveyId));
	},
	getSurveyResponse: (smCollectorId) => {
		return axios.get(url.getSurveyResponseUrl(smCollectorId));
	},
	sendSurvey: (surveyConfiguration) => {
		return axios.post(url.sendSurveyUrl(surveyConfiguration), surveyConfiguration);
	},

	getAdvertsById: (id) => {
		return axios.get(url.getAdvertsByIdUrl(id));
	},

	getAdvertsByKey: (viewKey) => {
		return axios.get(url.getAdvertsUrl(), {
			params: { viewKey },
		});
	},

	getUsers: () => {
		return axios.get(url.getUserUrl());
	},

	getNotifications: ({ lastIndex, perPage }) => {
		return axios.get(url.getNotificationsUrl({ lastIndex, perPage }));
	},

	deleteNotifications: ({ delete_array }) => {
		return axios.delete(url.deleteNotificationsUrl(), {
			data: {
				id_array: delete_array,
			},
		});
	},
	markReadNotifications: ({ read_array }) => {
		return axios.put(url.updateNotificationsUrl(), {
			data: {
				notification_array: read_array,
			},
		});
	},
	// Api call to get list of companies
	getCompanyList: (
		page,
		perPage,
		searchText,
		sort,
		filter,
		collectionType,
		fundingRaisedMin,
		fundingRaisedMax,
		foundedStartYear,
		foundedEndYear,
		stages,
		financings,
		teamMemberTags,
		industries,
		states,
		foundedYearTrailingRange,
		productOffering,
		businessModel,
		revenueModel,
		incorporatedStatus,
		pitchDeckAvailable,
		isPremiumPlanAccess,
		askMin,
		askMax,
		cohorts,
		startupsJoined,
		minScore,
		maxScore,
		minMrr,
		maxMrr,
		minRunway,
		maxRunway
	) => {
		return axios.get(url.companyList(), {
			params: {
				page,
				perPage,
				searchText,
				sort,
				filter,
				collectionType,
				fundingRaisedMin,
				fundingRaisedMax,
				foundedStartYear,
				foundedEndYear,
				stages,
				financings,
				teamMemberTags,
				industries,
				states,
				foundedYearTrailingRange,
				productOffering,
				businessModel,
				revenueModel,
				incorporatedStatus,
				pitchDeckAvailable,
				isPremiumPlanAccess,
				askMin,
				askMax,
				cohorts,
				startupsJoined,
				minScore,
				maxScore,
				minMrr,
				maxMrr,
				minRunway,
				maxRunway,
			},
		});
	},

	// Api call to download list of companies
	downloadCompanyList: (
		searchText,
		sort,
		filter,
		collectionType,
		fundingRaisedMin,
		fundingRaisedMax,
		foundedStartYear,
		foundedEndYear,
		stages,
		financings,
		teamMemberTags,
		industries,
		states,
		foundedYearTrailingRange,
		productOffering,
		businessModel,
		revenueModel,
		incorporatedStatus,
		pitchDeckAvailable,
		isPremiumPlanAccess,
		askMin,
		askMax,
		cohorts,
		startupsJoined,
		minScore,
		maxScore,
		minMrr,
		maxMrr,
		minRunway,
		maxRunway
	) => {
		return axios.get(url.companyExportList(), {
			params: {
				searchText,
				sort,
				filter,
				collectionType,
				fundingRaisedMin,
				fundingRaisedMax,
				foundedStartYear,
				foundedEndYear,
				stages,
				financings,
				teamMemberTags,
				industries,
				states,
				foundedYearTrailingRange,
				productOffering,
				businessModel,
				revenueModel,
				incorporatedStatus,
				pitchDeckAvailable,
				isPremiumPlanAccess,
				askMin,
				askMax,
				cohorts,
				startupsJoined,
				minScore,
				maxScore,
				minMrr,
				maxMrr,
				minRunway,
				maxRunway,
			},
		});
	},
	// Api call to get list of companies with filter
	getFilteredCompanyList: (payload) => {
		return axios.post(url.companyListFilterUrl(), {
			payload,
		});
	},

	// Api call to add company to watchlist or portfolio
	addCompanyToWatchlistOrPorfolio: (data) => {
		return axios.post(url.addCompanyToWatchlistOrPorfolioUrl(), data);
	},

	// Api call to remove company to watchlist or portfolio
	removeCompanyToWatchlistOrPorfolio: (type, companyId) => {
		return axios.delete(url.addCompanyToWatchlistOrPorfolioUrl(), {
			params: { type, companyId },
		});
	},

	// Api call for bulk action for watchlist or portfolio
	bulkWatchlistOrPorfolioCollectionActionApi: (data) => {
		return axios.post(url.bulkWatchlistOrPorfolioCollectionActionUrl(), data);
	},

	// Vouch Rest APIs
	getVouchToken: (email) => {
		return axios.get(url.getVouchTokenUrl(email));
	},

	addNewVouchData: () => {
		return axios.get(url.addNewVouchData());
	},

	getAllVouchData: () => {
		return axios.get(url.getAllVouchData());
	},

	getVouchDataById: (applicationId) => {
		return axios.get(url.getVouchDataById(applicationId));
	},

	deleteVouchDataById: (applicationId) => {
		return axios.get(url.deleteVouchDataById(applicationId));
	},

	// Vouch APIs
	getVouchNewApplication: () => {
		return axios.get(url.getVouchApplication());
	},

	createVouchApplication: (data) => {
		return axios.post(url.createVouchApplication(), data);
	},

	getVouchMoreQuestions: (vouchId) => {
		return axios.get(url.getVouchMoreQuestions(vouchId));
	},

	getVouchApplications: () => {
		return axios.get(url.getVouchApplications());
	},

	getVouchApplicationById: (appId) => {
		return axios.get(url.getVouchApplicationById(appId));
	},

	setVouchNewAnswers: (appId, data) => {
		return axios.post(url.setVouchNewAnswers(appId), data);
	},

	reviewVouchAnswers: (appId) => {
		return axios.get(url.reviewVouchAnswers(appId));
	},

	attestVouchApplication: (appId, data) => {
		return axios.post(url.attestVouchApplication(appId), data);
	},

	getVouchQuotes: (appId) => {
		return axios.get(url.getVouchQuotes(appId));
	},

	getVouchDecision: (appId) => {
		return axios.get(url.getVouchDecision(appId));
	},

	getVouchQuestionSets: (name) => {
		return axios.get(url.getVouchQuestionSets(), { name });
	},

	getVouchQuestionSetsById: (questionId) => {
		return axios.get(url.getVouchQuestionSetsById(questionId));
	},
	// API call to get a company user by id
	getCompanyUser: (companyId) => {
		return axios.get(url.companyUser(companyId));
	},
	// API call to get Industry Data
	getIndustryData: () => {
		return axios.get(url.getIndustryDataUrl());
	},
	getCompanyScore: (companyId) => {
		return axios.get(url.companyScore(companyId));
	},
	getStartupScoreReport: (startupId, months) => {
		return axios.get(url.startupScoreReportUrl(startupId, months));
	},

	createSavedFilterApi: (payload) => {
		return axios.post(url.createSavedFilterUrl(), payload);
	},

	getListOfSavedFilterApi: () => {
		return axios.get(url.createSavedFilterUrl());
	},

	updateSavedFilterApi: (payload) => {
		return axios.put(url.createSavedFilterUrl(), payload);
	},

	verifyUser: (payload) => {
		return axios.post(url.verifyUser(), payload);
	},

	deleteSavedFilterApi: (id) => {
		return axios.delete(url.deleteSavedFilterUrl(id));
	},

	getApprovalStatus: () => {
		return axios
			.get(url.userProfile())
			.then((response) => {
				if (response.data.message === "Success") {
					let approvalStatus = "APPROVED";
					const verificationStatus = response.data.data.verificationStatus;
					for (const r of response.data.data.userTypes) {
						if (r === "INVESTOR_USER" || r === USER_TYPES.CORPORATE_USER || r === "MENTOR_USER") {
							if (verificationStatus) {
								if (verificationStatus !== "PENDING" && verificationStatus !== "REJECTED" && verificationStatus !== "APPROVED") {
									verificationStatus = "RESTRICTED";
								}
								if (approvalStatus !== "PENDING" && approvalStatus !== "REJECTED") {
									approvalStatus = verificationStatus;
								}
							} else {
								approvalStatus = "RESTRICTED";
							}
						}
					}
					return approvalStatus;
				}
			})
			.catch((e) => {
				console.log("Submit", e);
				return "RESTRICTED";
			});
	},

	getListOfCountryStateApi: () => {
		return axios.get(url.getListOfCountryStateUrl());
	},

	getTSSPowerUPData: (companyID) => {
		return axios.get(url.getTSSPowerUPDataUrl(companyID));
	},
	getPersonaBuilderPowerUPData: (companyID) => {
		return axios.get(url.getPersonaBuilderPowerUPDataUrl(companyID));
	},
	getListOfCategoryApi: (category) => {
		return axios.get(url.getListOfCategoryApi(), {
			params: { category },
		});
	},
	getProbSolPowerUPData: (companyID) => {
		return axios.get(url.getProbSolPowerUPDataUrl(companyID));
	},
	getValuePropPowerUPData: (companyID) => {
		return axios.get(url.getValuePropPowerUPDataUrl(companyID));
	},
	getBrandstormingPowerUPData: (companyID) => {
		return axios.get(url.getBrandstormingPowerUPData(companyID));
	},

	getInvestorImpressionsData: (companyID) => {
		return axios.get(url.getInvestorImpressionsDataUrl(companyID));
	},

	getGrowthRateData: (companyID) => {
		return axios.get(url.getGrowthRateUrl(companyID));
	},
	getCurrentStreaksData: (companyID) => {
		return axios.get(url.getCurrentStreaksUrl(companyID));
	},

	updateStreakActionApi: function (payload) {
		return axios.post(url.updateStreakActionUrl, payload);
	},
	getOpenAIChatResponse: (payload) => {
		return axios.post(url.getOpenAIChatResponseUrl, payload);
	},
	getOpenAICompletionResponse: (payload) => {
		return axios.post(url.getOpenAICompletionResponseUrl, payload);
	},
	getCohorts: () => {
		return axios.get(url.getCohortsUrl);
	},
	applyPitch: (payload) => {
		return axios.post(url.applyToPitchUrl, payload);
	},
	getSubscriptionDetails: () => {
		return axios.get(url.getSubscriptionDetails);
	},
	getSubscriptionSettings: () => {
		return axios.get(url.getSubscriptionSettings);
	},
	checkOut: (priceId) => {
		return axios.post(url.checkout, { priceId: priceId });
	},
	paymentIntentCheckout: (payload) => {
		return axios.post(url.paymentIntent, payload);
	},
	getAllBIChartData: (companyID) => {
		return axios.get(url.getAllBIChartDataUrl(companyID));
	},
	updateAnnualRevenueGridData: (payload) => {
		return axios.post(url.updateAnnualRevenueGridData(), payload);
	},
	updateNetArrGridData: (payload) => {
		return axios.post(url.updateNetArrGridData(), payload);
	},
	updateMonthlyRevenueGridData: (payload) => {
		return axios.post(url.updateMonthlyRevenueGridData(), payload);
	},
	updateBurnRunwayGridData: (payload) => {
		return axios.post(url.updateBurnRunwayGridData(), payload);
	},
	updatePaybackGridData: (payload) => {
		return axios.post(url.updatePaybackGridData(), payload);
	},
	updateCustomerGridData: (payload) => {
		return axios.post(url.updateCustomerGridData(), payload);
	},

	// Feed section
	getFeedData: (page, perPage, filter, sort) => {
		return axios.get(url.feedApiUrl(), {
			params: { page, perPage, filter, sort },
		});
	},

	createFeedPost: (payload) => {
		return axios.post(url.feedApiUrl(), payload);
	},

	likeOnFeedPost: (postId, payload) => {
		return axios.post(url.feedReactionUrl(postId), payload);
	},

	disLikeOnFeedPost: (postId, action, payload) => {
		return axios.post(`${url.feedReactionUrl(postId)}?action=${action}`, payload);
	},

	getFeedPostComments: (postId, page, perPage, filter, sort) => {
		return axios.get(url.getAndPostFeedCommentUrl(postId), {
			params: { page, perPage, filter, sort },
		});
	},

	createFeedPostComment: (postId, payload) => {
		return axios.post(url.getAndPostFeedCommentUrl(postId), payload);
	},

	editFeedPostComment: (postId, commentId, payload) => {
		return axios.put(url.updatePostFeedCommentUrl(postId, commentId), payload);
	},

	deleteFeedPostComment: (postId, commentId) => {
		return axios.delete(url.updatePostFeedCommentUrl(postId, commentId));
	},

	getCapTableData: (companyID) => {
		return axios.get(url.capTableApiUrl(companyID));
	},

	editFeedPost: (postId, payload) => {
		return axios.put(url.updateFeedPostUrl(postId), payload);
	},

	deleteFeedPost: (postId) => {
		return axios.delete(url.updateFeedPostUrl(postId));
	},

	updateCapGridData: (payload) => {
		return axios.post(url.updateCapGridDataUrl(), payload);
	},

	getAnnualRevenueGridData: (companyID) => {
		return axios.get(url.getAnnualRevenueGridDataUrl(companyID));
	},

	getNetArrGridData: (companyID) => {
		return axios.get(url.getNetArrGridDataUrl(companyID));
	},

	getBurnMultipleGridData: (companyID) => {
		return axios.get(url.getBurnMultipleDataUrl(companyID));
	},

	getMagicNumberGridData: (companyID) => {
		return axios.get(url.getMagicNumberDataUrl(companyID));
	},

	getMonthlyRevenueGridData: (companyID) => {
		return axios.get(url.getMonthlyRevenueGridDataUrl(companyID));
	},

	getBurnRunwayGridData: (companyID) => {
		return axios.get(url.getBurnRunwayGridDataUrl(companyID));
	},

	getPaybackGridData: (companyID) => {
		return axios.get(url.getPaybackGridDataUrl(companyID));
	},

	getCustomerGridData: (companyID) => {
		return axios.get(url.getCustomerGridDataUrl(companyID));
	},

	getRevenueGridData: (companyID) => {
		return axios.get(url.getRevenueGridDataUrl(companyID));
	},

	updateRevenueGridData: (payload) => {
		return axios.post(url.updateRevenueGridDataUrl(), payload);
	},

	updateBurnMultipleGridData: (payload) => {
		return axios.post(url.getBurnMultipleDataUrl(), payload);
	},

	updateMagicNumberGridData: (payload) => {
		return axios.post(url.getMagicNumberDataUrl(), payload);
	},
	getAcvGridData: (companyID) => {
		return axios.get(url.getAcvGridDataUrl(companyID));
	},

	updateAcvGridData: (payload) => {
		return axios.post(url.updateAcvGridDataUrl(), payload);
	},

	getSmExpenseVsNewSalesGridData: (companyID) => {
		return axios.get(url.getSmExpenseVsNewSalesGridDataUrl(companyID));
	},

	updateSmExpenseVsNewSalesGridData: (payload) => {
		return axios.post(url.updateSmExpenseVsNewSalesGridDataUrl(), payload);
	},

	getFeedPostByIdApi: (postId) => {
		return axios.get(url.getFeedPostByIdApiUrl(postId));
	},

	getNetworkMatchApi: function ({
		page,
		userTypes,
		collectionType,
		perPage,
		search,
		sort,
		filter,
		country,
		state,
		city,
		industries,
		productOffering,
		expertise,
		currentStage,
		investmentRangeHigh,
		investmentRangeLow,
		badges,
		partnerCode,
	}) {
		return axios.get(url.getNetworkMatchApiUrl(), {
			params: {
				page,
				userTypes,
				collectionType,
				perPage,
				search,
				sort,
				filter,
				country,
				state,
				city,
				industries,
				productOffering,
				expertise,
				currentStage,
				investmentRangeHigh,
				investmentRangeLow,
				badges,
				partnerCode,
			},
		});
	},

	updateCollections: (payload, isRemove = false) => {
		return axios.post(url.updateCollectionsUrl(isRemove), payload); //SOS-2051
	},

	getUserPublicDataApi: (uuid) => {
		return axios.get(url.getUserPublicData(uuid));
	},

	getCompanyPublicDataApi: (uuid) => {
		return axios.get(url.getCompanyPublicData(uuid));
	},

	getPlatformJourneyApi: () => {
		return axios.get(url.getPlatformJourneyUrl());
	},

	getProxyInvestorsApi: () => {
		return axios.get(url.getProxyInvestorsUrl());
	},

	requestProxyInvestorConnectApi: (payload) => {
		return axios.post(url.requestProxyInvestorConnectUrl(), payload);
	},

	getWaitListApi: () => {
		return axios.get(url.waitListUrl());
	},

	updateWaitListApi: (payload) => {
		return axios.post(url.waitListUrl(), payload);
	},

	deactivateAccountApi: () => {
		return axios.post(url.deactivateAccountUrl(), {});
	},

	collectingDataFromPartnerApi: ({ partner, payload }) => {
		return axios.post(url.collectingDataFromPartnerUrl(partner), payload);
	},

	createGoogleSheetApi: ({ payload }) => {
		return axios.post(url.createGoogleSheetUrl(), payload);
	},
	getGoogleSheetApi: () => {
		return axios.get(url.getGoogleSheetUrl());
	},
	fetchGoogleSheetGridDataApi: () => {
		return axios.get(url.getGoogleSheetGridDataUrl());
	},

	googleSheetTokenApi: (payload) => {
		return axios.post(url.googleSheetTokenUrl(), payload);
	},

	getDataFromPartnerApi: ({ partner }) => {
		return axios.get(url.collectingDataFromPartnerUrl(partner));
	},

	downloadSheetApi: ({ partner }) => {
		return axios.get(url.collectingDataFromPartnerUrl(partner), {
			headers: {
				"Content-Type": "application/octet-stream",
			},
			responseType: "arraybuffer",
		});
	},

	getApplyToPitchApi: (companyId) => {
		return axios.get(url.getApplyToPitchUrl(companyId));
	},

	getPlaidLinkTokenApi: () => {
		return axios.get(url.getPlaidLinkTokenUrl());
	},

	fetchPlaidDataApi: ({ code }) => {
		return axios.post(url.fetchPlaidDataUrl(), { code });
	},

	createAccount: ({ data, queryParams }) => {
		let queryString = "";
		if (queryParams && Object.keys(queryParams).length > 0) {
			queryString = Object.keys(queryParams)
				.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
				.join("&");
			queryString = `?${queryString}`;
		}
		return axios.post(url.getCreateAccountUrl() + queryString, data);
	},

	getPlaidAccounts: (assetReportId) => {
		return axios.get(url.getPlaidAccountsUrl(), { params: { assetReportId } });
	},

	sendPlaidAccounts: ({ assetReportId, accountIds }) => {
		return axios.post(url.sendPlaidAccountsUrl(), { assetReportId, accountIds });
	},

	updateUserNewOnboard: function (payload) {
		return axios.put(url.userNewOnboard(), payload);
	},

	createUserSocialOnboard: function (payload, paramsData) {
		return axios.post(url.userSocialOnboard(paramsData), payload);
	},

	getEventListApi: (status = null, partnerCode = null) => {
		return axios.get(url.getEventListApiUrl(), { params: { status, partnerCode } });
	},

	getEventDetailsApi: (eventId) => {
		return axios.get(url.getEventDetailsApiUrl(eventId));
	},

	getPromotionalEventsApi: () => {
		return axios.get(url.getPromotionalEventListApiUrl());
	},

	getPartnerTheme: (partnerCode) => {
		return axios.get(url.getPartnerThemeUrl(partnerCode));
	},

	getPartnerStartups: (page, perPage, partnerCode, sort, sortCol, filterString) => {
		return axios.get(url.getPartnerStartupsUrl(page, perPage, partnerCode, sort, sortCol, filterString));
	},

	getPartnerUsers: (page, perPage, partnerCode, sort, sortCol, filterString) => {
		return axios.get(url.getPartnerUsersUrl(page, perPage, partnerCode, sort, sortCol, filterString));
	},

	getPartnerAdmins: (page, perPage, partnerCode, sort, sortCol, filterString) => {
		return axios.get(url.getPartnerAdminsUrl(page, perPage, partnerCode, sort, sortCol, filterString));
	},

	getRatingReviewApi: ({ entityId, entityType, details, filter, sort, perPage }) => {
		return axios.get(url.getRatingReviewApiUrl({ entityId, entityType, details, filter, sort, perPage }));
	},

	addRatingReviewApi: function (payload) {
		return axios.post(url.addRatingReviewApiUrl(), payload);
	},

	updateRatingReviewApi: function (reviewId, payload) {
		return axios.put(url.updateRatingReviewApiUrl(reviewId), payload);
	},

	getAdvertBannerApi: () => {
		return axios.get(url.getAdvertBannerUrl());
	},

	getPartnerCardsApi: () => {
		return axios.get(url.getPartnerCardsUrl());
	},

	enterpriseReadyScoreApi: function (payload) {
		return axios.post(url.enterpriseReadyScoreUrl(), payload);
	},

	getPitchCompaniesApi: ({ page, perPage, isApplied, eventUuid, filter, sort, searchText }) => {
		return axios.get(url.getPitchCompaniesUrl(), { params: { page, perPage, isApplied, eventUuid, filter, sort, searchText } });
	},

	sendEventInvitationApi: function (payload) {
		return axios.post(url.sendEventInvitationUrl(), payload);
	},

	createEventApi: function (payload) {
		return axios.post(url.createEventUrl(), payload);
	},

	updateEventApi: function (payload, eventId) {
		return axios.put(url.updateEventUrl(eventId), payload);
	},

	// ***------------------------Scheduling Apis------------------------***

	getSchedulesByUserIdApi: (userId) => {
		return axios.get(url.getSchedulesByUserIdUrl(), { params: { userId: userId } });
	},

	getAvailablitiesByDateApi: (userId, date, meetingLength, timezone) => {
		return axios.get(url.getAvailablitiesByDateUrl(), { params: { userId, date, meetingLength, timezone } });
	},

	updateScheduleApi: (id, data) => {
		return axios.put(url.updateSchedulesByUserIdUrl(id), data);
	},

	getAllEventTypesApi: (userId) => {
		return axios.get(url.getEventTypesByUserIdUrl(), { params: { userId } });
	},

	bookMeetingApi: (data) => {
		return axios.post(url.bookMeetingUrl(), data);
	},

	getAllBookingsApi: (userId, pageNo, pageSize, timezone) => {
		return axios.get(url.bookMeetingUrl(), { params: { userId, pageNo, pageSize, timezone } });
	},

	cancelBookingApi: (id, cancellationReason) => {
		return axios.put(url.deleteBookingMeetingUrl(id), {
			cancellationReason: cancellationReason,
		});
	},

	rescheduleMeetingApi: (bookingId, data) => {
		return axios.put(url.rescheduleMeetingUrl(bookingId), data);
	},

	createAvailabilityApi: (timeZone) => {
		return axios.post(url.getSchedulesByUserIdUrl(), timeZone);
	},

	// ***-----------------------------END-------------------------------***

	// ***------------------------Partner White label Question and Answers---------------***

	getPartnerQAAPI: () => {
		return axios.get(url.partnerQAUrl());
	},

	updateParterQAAPI: (payload) => {
		return axios.put(url.partnerQAUrl(), payload);
	},
	getParterQuestionsAPI: () => {
		return axios.get(url.partnerQuestionsUrl());
	},
	addPartnerQuestionAPI: (payload) => {
		return axios.post(url.partnerQuestionsUrl(), payload);
	},
	updatePartnerQuestionAPI: (payload, questionId) => {
		return axios.put(url.updatePartnerQuestionsUrl(questionId), payload);
	},
	deletePartnerQuestionAPI: (questionId) => {
		return axios.delete(url.updatePartnerQuestionsUrl(questionId));
	},

	getPartnerEntitiesAPI: ({ pageNo, pageSize, filterBy, sortBy, searchText }) => {
		return axios.get(url.partnerEntitiesUrl(), { params: { pageNo, pageSize, filterBy, sortBy, searchText } });
	},

	getPartnerQuestionAnswersAPI: ({ entityId }) => {
		return axios.get(url.partnerQuestionAnswerUrl(), { params: { entityId } });
	},

	// ***-----------------------------END-------------------------------***

	getPowerPacksAPI: () => {
		return axios.get(url.adminPowerPackUrl());
	},

	createPowerUpPacks: (payload) => {
		return axios.post(url.createPowerPackUrl(), payload);
	},

	getActivityCardsAPI: () => {
		return axios.get(url.activityCardsUrl());
	},
	getMeetingHisotryApi: ({ companyId, pageNo, pageSize, timezone }) => {
		return axios.get(url.meetingHistoryUrl(companyId), { params: { pageNo, pageSize, timezone } });
	},

	getPowerUpActivityApi: ({ companyId, pageNo, pageSize }) => {
		return axios.get(url.powerUpActivityUrl(companyId), { params: { pageNo, pageSize } });
	},

	updatePowerUpPacks: (payload, powerUpPackUuid, cardId) => {
		return axios.put(url.updateAdminPowerPackUrl(powerUpPackUuid), payload, { params: { cardId } });
	},
	deletePowerUpPack: (powerUpPackUuid, cardId) => {
		return axios.delete(url.deleteAdminPowerupPackUrl(powerUpPackUuid), { params: { cardId } });
	},
	getAllGroupCardsApi: ({ group }) => {
		return axios.get(url.getAllGroupCardsUrl(), { params: { group } });
	},
	updatePowerupPackSettingsApi: (payload) => {
		return axios.put(url.updatePowerupPackSettingsUrl(), payload);
	},
	downloadUserProfileDataAPi: () => {
		return axios.get(url.downloadUserProfileDataUrl(), {
			headers: {
				"Content-Type": "application/octet-stream",
			},
			responseType: "arraybuffer",
		});
	},
	deleteUserProfileDataApi: () => {
		return axios.delete(url.deleteUserProfileDataUrl());
	},
	getLikesApi: ({ entityType, collectionType, entityIds }) => {
		return axios.get(url.getLikesUrl(), { params: { entityType, collectionType, entityIds } });
	},
	getPartnerCodesApi: () => {
		return axios.get(url.getPartnerCodesUrl());
	},
	getAdminQuestionAnswersAPI: ({ entityId }) => {
		return axios.get(url.adminQuestionAnswerUrl(), { params: { entityId } });
	},
	trackCompanyApi: (payload, isRemove = false) => {
		return axios.put(url.trackCompanyUrl(isRemove), payload);
	},
	inviteUserApi: (payload) => {
		return axios.post(url.inviteUserUrl(), payload);
	},
	verifyInvitationToken: (payload) => {
		return axios.post(url.verifyInvitationTokenUrl(), payload);
	},
	getCommunityPartnersApi: (pageName) => {
		return axios.get(url.getCommunityPartnersUrl(pageName));
	},

	removePartnerFromFounderApi: (invitedUserId) => {
		return axios.delete(url.removeCommunityPartnersUrl(), { params: { invitedUserId } });
	},
};
