import { Box, Chip, Rating, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import useDimensions from "react-cool-dimensions";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";

import { PowerPackContext } from "../../../PowerPackController";
import SvgComponent from "../../../../../utils/SVGColor";
import { ROCKET_LAUNCH_ICON } from "../../../../../constants";

const InfoCorner = ({ title, estimatedEffort, whoShouldDoIt, ratingValue, barStyle, displayType }) => (
	<Box
		sx={{
			...(barStyle
				? {
						borderRadius: "30px",
						px: "2.5rem",
						py: "1.5rem",
						gap: ".25rem",
				  }
				: {
						borderRadius: "0px 100px 0px 0px",
						padding: "2.5rem",
						gap: "0.625rem",
				  }),
			background: "#000",
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
		}}
	>
		<Typography variant={barStyle ? "Title/20/Semibold" : "Title/30/Semibold"} sx={{ color: "white" }}>
			{title}
		</Typography>

		{displayType && (
			<Box display={"flex"} alignItems={"center"} gap={1}>
				<SvgComponent strokeColor={"white"} svgUrl={ROCKET_LAUNCH_ICON} style={{ height: "34px", width: "34px" }} />
				<Typography variant="Body/18/Regular" sx={{ color: "white" }}>
					PowerPack
				</Typography>
			</Box>
		)}
		{/* https://startupos.atlassian.net/browse/SOS-2938 */}
		{/* <Typography variant="Body/18/Regular" sx={{ color: "white" }}>
			Estimated Effort: {estimatedEffort}
			{whoShouldDoIt && (
				<React.Fragment>
					<br />
					Who Should Do It: {whoShouldDoIt}
				</React.Fragment>
			)}
		</Typography> */}
	</Box>
);

function HeroCard() {
	const { packDetail } = useContext(PowerPackContext);
	const currentPath = window.location.pathname;
	const parts = currentPath.split("/");
	const packId = parts[parts.length - 1];

	const { observe, width } = useDimensions({
		onResize: ({ width }) => {},
	});

	const calculateHeight = (width) => {
		const maxWidth = 1630;
		const maxHeight = 750;
		const aspectRatio = maxWidth / maxHeight;
		const calculatedHeight = width / aspectRatio;

		return calculatedHeight > maxHeight ? maxHeight : calculatedHeight;
	};

	const height = calculateHeight(width);
	const infoCornerRef = useRef(null);
	const minHeight = infoCornerRef.current ? infoCornerRef.current.clientHeight : "auto";

	useEffect(() => {
		observe();
	}, [observe]);

	return (
		<React.Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - {packDetail?.powerUpPackName || ""}</title>
			</Helmet>
			<Box
				sx={{
					position: "relative",
					width: "100%",
					height: `100%`,
					minHeight: `${minHeight}px`,
					maxWidth: "1630px",
					maxHeight: "750px",
					backgroundColor: "gray",
					borderRadius: "1.875rem",
					overflow: "hidden",
				}}
			>
				{" "}
				<Box
					ref={observe}
					sx={{
						position: "relative",
						width: "100%",
						height: `${packDetail?.thumbnailUrl ? height : 525}px`,
						minHeight: `${minHeight}px`,
						maxWidth: "1630px",
						maxHeight: "750px",
						backgroundColor: "gray",
						borderRadius: "1.875rem",
						overflow: "hidden",
						backgroundImage: `url(${packDetail?.thumbnailUrl})`,
						backgroundSize: "contain",
						backgroundPosition: "center",
						...(typeof packId == "string" &&
							packId?.startsWith("pitch-deck-analysis-by-bolt-ai") && {
								backgroundRepeat: "no-repeat",
								backgroundColor: "#7B61FF",
							}),
					}}
				>
					{packDetail?.isAiPowered && (
						<Box
							sx={{
								position: "absolute",
								top: "1.87rem",
								left: "1.87rem",
							}}
						>
							<Chip
								sx={{ background: "white" }}
								label={
									<Typography variant="Text/xs/Medium" color={"#344054"}>
										AI Powered
									</Typography>
								}
							/>
						</Box>
					)}

					{width > 1000 && (
						<Box
							ref={infoCornerRef}
							sx={{
								position: "absolute",
								bottom: "0",
								left: "0",
								maxWidth: "35.3125rem",
								width: "100%",
							}}
						>
							<InfoCorner
								title={packDetail?.powerUpPackName}
								estimatedEffort={packDetail?.estimatedEfforts}
								ratingValue={5}
								barStyle={false}
								displayType={typeof packId == "string" && packId?.startsWith("pitch-deck-analysis-by-bolt-ai") ? false : true}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}

export default HeroCard;
