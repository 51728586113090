import { createTheme } from "@mui/material/styles";
import { sosPrimary } from "./colors";

export const themeLayout = (themePalette) => ({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			mobile: 981,
		},
	},
	components: {
		MuiCard: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.size === "large" && {
						borderRadius: 28,
						padding: theme.spacing(3),
					}),
					...(ownerState.size === "medium" && {
						borderRadius: 14,
						padding: theme.spacing(2),
					}),
					...(ownerState.size === "small" && {
						borderRadius: 7,
						padding: theme.spacing(1),
					}),
				}),
			},
		},
		MuiAlertTitle: {
			styleOverrides: {
				root: {
					fontFamily: "'PoppinsSemiBold' !important",
					fontSize: "16px !important",
					lineHeight: "24px !important",
					color: "black",
				},
			},
		},
		MuiAlert: {
			defaultProps: {},
			styleOverrides: {
				root: {
					borderRadius: "12px",
					fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
					fontSize: "16px",
					lineHeight: "24px",
					color: "black",
				},
			},
			variants: [
				{
					props: { severity: "success" },
					style: {
						backgroundColor: "#F6FEF9",
					},
				},
				{
					props: { severity: "info" },
					style: {
						backgroundColor: "#F5FBFF",
					},
				},
				{
					props: { severity: "error", variant: "outlined" },
					style: {
						backgroundColor: "#FFFBFA",
						border: "1px solid #FDA29B",
						color: "#B42318",
						"& .MuiAlertTitle-root": {
							color: "#B42318",
						},
						"& .MuiSvgIcon-root": {
							fill: "#B42318",
						},
					},
				},
			],
		},
		MuiContainer: {
			defaultProps: {
				disableGutters: true,
			},
			styleOverrides: {
				root: {
					maxWidth: "80rem !important",
				},
			},
		},
	},
});

// As material does not fully supported to add a custom breakpoint.
export const BREAKPOINT_XXL = 1836;
export const BREAKPOINT_SM = 400;
