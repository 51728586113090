import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Typography,
	Box,
	Button,
	Switch,
	styled,
	useMediaQuery,
	CircularProgress,
	ToggleButtonGroup,
	ToggleButton,
	Divider,
	Dialog,
	DialogContent,
	Alert,
	AlertTitle,
	Link,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import theme from "../../constants/Theme";
import services from "../../services";
import ROUTES_PATH from "../../constants/routes";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import CommonSlideDialog from "../../common/CommonSlideDialog";
import { USER_TYPES } from "../../utils/userTypes";
import { PAYMENT_PLANS, PAYMENT_DESC } from "./Content";
import { setNetworkingDashboardData } from "../../modules/actions/networkingActions";
import { CardElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PremiumSwitch = styled(Switch)(({ theme }) => ({
	width: 86,
	height: 24,
	padding: 0,
	// zIndex: 2,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 20,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(20px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(62px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "#F4F3FF",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 20,
		height: 20,
		borderRadius: 20,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 20,
		opacity: 1,
		backgroundColor: "#FDF2FA",
		boxSizing: "border-box",
	},
}));

export default function PaymentPlanDialog({
	open,
	descIndex = 0,
	isRedirect = true,
	onClose,
	plans = PAYMENT_PLANS,
	metadata,
	inline = false,
	dialogTitle,
	dialogDescription,
	closeButton = true,
}) {
	const history = useHistory();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [isMonthlyPlan, setIsMonthlyPlan] = useState(false);
	const [isManagePlan, setIsManagePlan] = useState(false);
	const [loading, setLoading] = useState(false);

	const [stripeLoading, setStripeLoading] = useState(false);
	const [clientSecret, setClientSecret] = useState("");
	const [showStripeDialog, setShowStripeDialog] = useState(false);

	const userType = useSelector((store) => store?.auth?.userType);
	const userId = useSelector((store) => store?.auth?.userId);
	const dispatch = useDispatch();

	useEffect(() => {
		if (open) {
			handleMixPanel("Upgrade Plan Viewed");
		}
	}, [open]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			getSubscriptionDetails();
		}
	}, [userType]);

	const getSubscriptionDetails = async () => {
		try {
			const details = await services.getSubscriptionDetails();
			if (details?.data?.data?.subscriptionStatus !== "active" && details?.data?.data?.isCreatedByUser === true) {
				setIsManagePlan(true);
			} else {
				setIsManagePlan(false);
			}
		} catch (err) {
			setIsManagePlan(false);
		}
	};

	const handleClose = () => {
		dispatch(
			setNetworkingDashboardData({
				openMessageDialog: false,
				messageWithMatch: {},
			})
		);

		if (isRedirect) {
			history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
		}

		if (typeof onClose == "function") onClose();
	};

	const handlePlanChoose = async () => {
		setLoading(true);
		await services
			.checkOut(isMonthlyPlan ? process.env.REACT_APP_PREMIUM_MONTHLY_PRICE_ID : process.env.REACT_APP_PREMIUM_ANNUAL_PRICE_ID)
			.then((checkoutData) => {
				if (checkoutData?.data?.data) {
					handleMixPanel("Go to Stripe", {
						"Plan name": isMonthlyPlan ? "Monthly" : "Annual",
					});
					handleMixPanel("Upgrade Viewed");

					window.open(checkoutData?.data?.data?.url);
				}
			})
			.catch((e) => {
				console.log("Error Checkout. Failed with error:", e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOneTimePD = async () => {
		setLoading(true);
		await services
			.paymentIntentCheckout({
				productId: process.env.REACT_APP_PITCH_REVIEW_PRODUCT_ID,
				userId: userId,
				pitchDeckLink: metadata?.pitchDeckLink,
				startupName: metadata?.startupName,
			})
			.then((checkoutData) => {
				if (checkoutData?.data?.data) {
					setLoading(false);

					handleMixPanel("Go to Stripe", {
						"Plan name": "Pitchdeck One-Time Review",
					});
					console.log("SEC:", checkoutData?.data?.data?.clientSecret);
					setClientSecret(checkoutData?.data?.data?.clientSecret || "");
					setShowStripeDialog(true);
				}
			})
			.catch((e) => {
				console.log("Error init payment intent for One Time PD Review. Failed with error:", e);
				setClientSecret("");
				setShowStripeDialog(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handlePlanChange = () => {
		setIsMonthlyPlan(!isMonthlyPlan);
	};

	const handleSubscriptionSettings = async () => {
		try {
			const settings = await services.getSubscriptionSettings();
			window.open(settings?.data?.data?.url || process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
		} catch (err) {
			window.open(process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
		}
	};

	return (
		<CommonSlideDialog
			open={open}
			slidingEffect={false}
			onClose={handleClose}
			maxWidth={plans?.length > 2 ? "lg" : "md"}
			inline={inline}
			closeButton={closeButton}
			dialogAction={
				<Box display={"flex"} alignItems={"center"} sx={{ flex: 1, justifyContent: "flex-end" }} gap={2}>
					{" "}
					<Button onClick={onClose} color="clear" variant="DS1">
						Close
					</Button>
					<Button onClick={handlePlanChoose} startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />} variant="DS1">
						Subscribe Now
					</Button>
				</Box>
			}
			PaperProps={{
				sx: (theme) => ({
					p: 4,
					borderRadius: "24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
				}),
			}}
		>
			<Box>
				<Box pb={3}>
					<Box sx={{ mb: 8 }}>
						<Typography variant="Text/2xl/Semibold" sx={{ textAlign: "center", pb: 2 }} component={"p"}>
							{dialogTitle ? dialogTitle : "StartupOS Plans"}
						</Typography>
						<Typography variant="Text/md/Regular" sx={{ pb: 4, color: "rgba(18, 17, 39, 0.56)" }} component={"p"}>
							{dialogDescription
								? dialogDescription
								: "Choose the plan that matches your startup's needs and goals. Take your startup to the next level with a plan designed for growth and success."}
						</Typography>
						<Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
							<ToggleButtonGroup value={isMonthlyPlan} exclusive onChange={() => handlePlanChange()} variant={"DS1"}>
								<ToggleButton value={true}>Monthly Billing</ToggleButton>
								<ToggleButton value={false}>Yearly Billing</ToggleButton>
							</ToggleButtonGroup>
						</Box>
					</Box>
				</Box>
				{!isManagePlan && (
					<Box
						display="flex"
						gap={4}
						sx={(theme) => ({
							flexDirection: "row",
							[theme.breakpoints.down("md")]: {
								flexDirection: "column",
							},
						})}
					>
						{plans.map((payment) => (
							<Plan
								key={payment.id}
								isMonthlyPlan={isMonthlyPlan}
								payment={payment}
								handlePlanChoose={handlePlanChoose}
								handlePlanChange={handlePlanChange}
								handleOneTimePD={handleOneTimePD}
								loading={loading}
							/>
						))}
					</Box>
				)}
				{isManagePlan && (
					<Box display="flex" justifyContent="flex-end">
						<Box mr={10}>
							<Button
								onClick={handleSubscriptionSettings}
								sx={{
									height: "48px",
									width: "300px",
									fontWeight: 600,
									fontSize: "16px",
									lineHeight: "24px",
									boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
									borderRadius: "100px",
									background: "#7B61FF",
									"&:hover": {
										background: "#7B61FF",
									},
									"&:active": {
										background: "#7B61FF",
									},
								}}
							>
								Update Subscription Settings
							</Button>
						</Box>
					</Box>
				)}
			</Box>
			<Dialog
				open={showStripeDialog}
				onClose={() => {
					setShowStripeDialog(false);
					setClientSecret("");
				}}
				maxWidth="sm"
				fullWidth
			>
				<DialogContent>
					{stripeLoading ? (
						<CircularProgress />
					) : clientSecret ? (
						<Elements stripe={stripePromise} options={{ clientSecret }}>
							<CheckoutForm
								clientSecret={clientSecret}
								metadata={metadata}
								close={() => {
									setShowStripeDialog(false);
									setTimeout(() => {
										setClientSecret("");
									}, 200);
								}}
							/>
						</Elements>
					) : (
						<Typography color="error">Failed to initialize payment. Please try again later.</Typography>
					)}
				</DialogContent>
			</Dialog>
		</CommonSlideDialog>
	);
}

function CheckoutForm({ clientSecret, close, metadata }) {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: metadata?.returnUrl,
			},
		});

		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const paymentElementOptions = {
		layout: "accordion",
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" options={paymentElementOptions} />

			<Button
				type="submit"
				disabled={isLoading || !stripe || !elements}
				startIcon={isLoading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
				variant="DS1"
				fullWidth
				sx={{ my: 2 }}
			>
				Pay Now
			</Button>

			{/* Show any error or success messages */}
			{message && (
				<Alert severity="error" variant="outlined">
					<AlertTitle>Error</AlertTitle>
					{message}
				</Alert>
			)}
			<Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", my: 1 }}>
				<Button
					color="clear"
					variant="DS1"
					onClick={() => close()}
					sx={{
						"& > .MuiButton-startIcon, & > span.button_text": {
							transition: "all .2s",
						},
						"&:hover > .MuiButton-startIcon": {
							transform: "translateX(-5px)",
						},
						"&:hover > span.button_text": {
							transform: "translateX(-2px)",
						},
					}}
					startIcon={
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<path
								d="M16 10.6667L10.6667 16M10.6667 16L16 21.3333M10.6667 16H21.3333M10.4 28H21.6C23.8402 28 24.9603 28 25.816 27.564C26.5686 27.1805 27.1805 26.5686 27.564 25.816C28 24.9603 28 23.8402 28 21.6V10.4C28 8.15979 28 7.03969 27.564 6.18404C27.1805 5.43139 26.5686 4.81947 25.816 4.43597C24.9603 4 23.8402 4 21.6 4H10.4C8.15979 4 7.03969 4 6.18404 4.43597C5.43139 4.81947 4.81947 5.43139 4.43597 6.18404C4 7.03969 4 8.15979 4 10.4V21.6C4 23.8402 4 24.9603 4.43597 25.816C4.81947 26.5686 5.43139 27.1805 6.18404 27.564C7.03969 28 8.15979 28 10.4 28Z"
								stroke="black"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
				>
					<span className="button_text">Go Back</span>
				</Button>
			</Box>
		</form>
	);
}

const Plan = ({ payment, isMonthlyPlan, handlePlanChoose, handleOneTimePD, loading }) => {
	const { title, description, amountMonth, amountYear, showPerTag, showOneTime, subscriptionButton, subscriptionButtonActive, items } = payment;
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				background: "white",
				border: "1px solid rgba(0, 0, 0, 0.12)",
				borderRadius: "12px",
				flex: 1,
				py: "40px",
			}}
		>
			<Box
				sx={{
					px: 3,
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					variant={"Text/md/Semibold"}
					color="primary"
					sx={{
						...(showOneTime && {
							color: "#EC6C67 !important",
						}),
					}}
				>
					{title}
				</Typography>
				<Typography variant={"Text/sm/Regular"}>{description}</Typography>
				<Typography variant={"Text/2xl/Semibold"}>
					{isMonthlyPlan ? amountMonth : amountYear}
					{(showPerTag || showOneTime) && (
						<Typography component={"span"} variant="Text/sm/Semibold" sx={{ ml: 1, color: "rgba(18, 17, 39, 0.56)" }}>
							{showOneTime ? "one-time" : isMonthlyPlan ? "/mo" : "/yr"}
						</Typography>
					)}
				</Typography>
				<Button
					variant="DS1"
					disabled={!subscriptionButtonActive || loading}
					color={subscriptionButtonActive ? (showOneTime ? "salmon" : "primary") : "gray"}
					sx={{ borderRadius: "6px", mt: 3 }}
					onClick={showOneTime ? handleOneTimePD : handlePlanChoose}
				>
					{loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit", mr: 2 }} />}
					{subscriptionButton}
				</Button>
			</Box>
			<Divider sx={{ my: 4 }} />

			<Box
				sx={{
					px: 3,
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					variant="Text/xs/Medium"
					color="primary"
					sx={{
						textTransform: "uppercase",
						...(showOneTime && {
							color: "#EC6C67 !important",
						}),
					}}
				>
					What's Included
				</Typography>
				<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
					{items.map((item) => (
						<Box sx={{ display: "flex", flex: 1, gap: 1 }}>
							<Box>
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
									<path
										d="M16.9031 2.16721L17.3855 1.09109C11.3371 -1.60533 4.23743 1.11585 1.54102 7.17661L2.61707 7.65898C5.04138 2.19193 11.4485 -0.269466 16.9031 2.16721Z"
										fill="#0A141B"
									/>
									<path
										d="M2.61717 7.64647L1.54111 7.16409C-1.15531 13.2125 1.56581 20.3122 7.62657 23.0087L8.10894 21.9325C2.65426 19.5082 0.192865 13.1011 2.61717 7.64647Z"
										fill="#0A141B"
									/>
									<path
										d="M8.0962 21.9326L7.61377 23.0087C13.6622 25.7052 20.7619 22.984 23.4583 16.9232L22.3823 16.4409C19.958 21.9079 13.5509 24.3693 8.0962 21.9326Z"
										fill="#0A141B"
									/>
									<path
										d="M17.3852 1.09106L16.9028 2.16718C22.3575 4.59149 24.819 11.0109 22.3823 16.4533L23.4583 16.9356C26.1548 10.8749 23.4336 3.78748 17.3852 1.09106Z"
										fill="#0A141B"
									/>
									<path
										d="M6.35277 23.5282L10.4097 14.4124C10.4963 14.2145 10.4097 13.9794 10.2118 13.8928L4.37375 11.2954C3.85426 11.0604 3.78003 10.3554 4.23768 10.009L18.0538 0.0768325C18.3753 -0.158176 18.7959 0.188128 18.6351 0.546826L14.5781 9.66272C14.4915 9.86062 14.5781 10.0956 14.776 10.1822L20.6141 12.7796C21.1336 13.0147 21.2078 13.7197 20.7502 14.066L6.93408 23.9983C6.61248 24.2456 6.19198 23.8993 6.35277 23.5282Z"
										fill="#0A141B"
									/>
									<path
										d="M14.5903 9.68753L18.6473 0.57169C18.8081 0.212992 18.3875 -0.133374 18.0659 0.101635L4.24989 10.0339C4.00252 10.2071 3.91595 10.4791 3.95305 10.7389L14.6274 10.0462C14.5532 9.93489 14.5408 9.79885 14.5903 9.68753Z"
										fill="#0A141B"
									/>
									<path
										d="M4.37366 11.3078L10.2117 13.9053C10.2736 13.93 10.3231 13.9795 10.3726 14.029L14.6645 10.0957C14.6522 10.0833 14.6398 10.0586 14.6275 10.0338L3.95312 10.7265C3.97786 10.9738 4.12628 11.1965 4.37366 11.3078Z"
										fill="#0A141B"
									/>
									<path
										d="M20.6265 12.7922L14.7884 10.1947C14.7389 10.17 14.6895 10.1329 14.6524 10.0958L10.3604 14.0291C10.3727 14.0538 10.3974 14.0786 10.3974 14.1033L21.0471 13.3736C21.0223 13.1262 20.8739 12.9035 20.6265 12.7922Z"
										fill="#0A141B"
									/>
									<path
										d="M6.93398 24.0107L20.7501 14.0784C20.9851 13.9052 21.084 13.6332 21.0469 13.3734L10.3973 14.1032C10.4344 14.2021 10.4467 14.3134 10.3973 14.4124L6.3403 23.5282C6.19187 23.8993 6.61239 24.2457 6.93398 24.0107Z"
										fill="#0A141B"
									/>
								</svg>
							</Box>
							<Box sx={{ flex: 1 }}>
								<Typography variant="Text/xs/Regular" sx={{ lineHeight: "180%" }}>
									<span>
										<Typography variant="Text/xs/Semibold">{item.title}</Typography>
									</span>
									{item.description}
								</Typography>
							</Box>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};

const CheckIcon = ({ color = "#12B76A" }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.8122 8.52278L10.7737 20.5603C10.6344 20.6996 10.4691 20.8101 10.2871 20.8855C10.105 20.9609 9.90996 20.9997 9.71295 20.9997C9.51594 20.9997 9.32085 20.9609 9.13884 20.8855C8.95683 20.8101 8.79146 20.6996 8.65217 20.5603L1.93779 13.8103C1.65699 13.529 1.49927 13.1479 1.49927 12.7504C1.49927 12.353 1.65699 11.9718 1.93779 11.6906L4.18779 9.44059C4.46813 9.16018 4.84809 9.00217 5.2446 9.00112C5.64111 9.00006 6.0219 9.15605 6.30373 9.43496L9.73873 12.7415L9.74904 12.7518L18.4425 4.1859C18.7236 3.90595 19.1042 3.74878 19.5009 3.74878C19.8977 3.74878 20.2782 3.90595 20.5594 4.1859L22.8094 6.39465C22.9498 6.53388 23.0614 6.69951 23.1376 6.88203C23.2139 7.06455 23.2533 7.26033 23.2535 7.45813C23.2538 7.65592 23.2149 7.85181 23.1392 8.03453C23.0634 8.21725 22.9523 8.38318 22.8122 8.52278Z"
				fill={color}
			/>
		</svg>
	);
};
