import React, { useEffect, useState, memo } from "react";
import { Dialog, Box, Typography, DialogContent, Slide, Button, DialogActions, CircularProgress } from "@mui/material";
import { MESSAGE_DOTS_PURPLE_ICON } from "../../../constants";
import CloseIcon from "@mui/icons-material/Close";
import Messaging from "../../../containers/Networking/Messaging";
import { useSelector, useDispatch } from "react-redux";
import { setNetworkingDashboardData } from "../../../modules/actions/networkingActions";
import { getConversationWithID } from "../../../utils/messaging-helper";
import { updateCurrentConversation } from "../../../containers/Messaging/store/action-creators";
import CommonSlideDialog from "../../../common/CommonSlideDialog";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

function MessageDialog() {
	const dispatch = useDispatch();
	const openMessageDialog = useSelector((state) => state.networking.openMessageDialog);
	const [showMessaging, setShowMessaging] = useState(false);
	const messageWithMatch = useSelector((state) => state.networking.messageWithMatch);
	const [loading, setLoading] = useState(true);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);

	const updateCurrentConversationId = async () => {
		try {
			setLoading(true);
			if (messageWithMatch && messageWithMatch?.id) {
				let conversationID = await getConversationWithID({
					idArray: [messageWithMatch?.id],
				});
				dispatch(updateCurrentConversation(conversationID));
			}
			setTimeout(() => {
				setLoading(false);
				setShowMessaging(true);
			}, [700]);
		} catch (error) {
			setLoading(false);
			setShowMessaging(true);
			console.log("Could not get matched conversation.", error);
		}
	};

	useEffect(() => {
		if (openMessageDialog) {
			updateCurrentConversationId();
			handleMixPanel("Message Drawer Opened", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
		} else {
			setTimeout(() => {
				setShowMessaging(false);
			}, [700]);
		}
	}, [openMessageDialog]);

	const handleClose = () => {
		dispatch(
			setNetworkingDashboardData({
				openMessageDialog: false,
				messageWithMatch: {},
			})
		);
	};

	return (
		<CommonSlideDialog
			open={openMessageDialog}
			disableBackDropClick
			onClose={handleClose}
			title={
				<Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
					<Box component={"img"} src={MESSAGE_DOTS_PURPLE_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					<Typography variant="Text/md/Semibold">Messages</Typography>
				</Box>
			}
			maxWidth="md"
			titleVariant="poppinsSemiBold30"
			titleStyle={{ px: { xs: 2, md: 5 }, pt: { xs: 2, md: 5 }, pb: 3, alignItems: "baseline" }}
			contentStyle={{ px: { xs: 2, md: 5 }, pb: 1, flex: 1, display: "flex" }}
			fullWidth
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					top: 0,
					m: 0,
					borderRadius: "24px 0px 0px 24px",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
					minHeight: 1,
					width: "100%",
				},
			}}
			footerStyle={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 2 }, pt: 0 }}
			scroll_type="paper"
		>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flex: 1,
					}}
				>
					<CircularProgress
						sx={(theme) => ({
							height: "80px",
							width: "80px",
							color: theme.palette.primary.primaryPurple,
						})}
					/>
				</Box>
			)}
			{showMessaging && !loading && <Messaging />}
		</CommonSlideDialog>
	);
}

export default memo(MessageDialog);
