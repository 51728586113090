import { createTheme } from "@mui/material/styles";
import theme from ".";
import { sosPrimary } from "./colors";
//import { themeShape } from "./shape";

export const themeButtons = (themePalette) => ({
	components: {
		MuiButton: {
			defaultProps: {
				disableRipple: true,
				disableElevation: true,
				variant: "contained",
				color: "primary",
			},
			styleOverrides: {
				root: {
					borderRadius: 4,
					padding: "10px 24px",
					textTransform: "none",
					fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
					fontSize: "14px",
					lineHeight: "20px",
					maxWidth: "348px",
					width: "100%",
					height: "40px",
					margin: "auto",
				},
			},
			variants: [
				/// --- DS 1 Buttons --- ///
				// *** BASE BUTTON PRIMARY COLOR *** //
				{
					props: { variant: "DS1" },
					style: {
						padding: "0.75rem 1.25rem",
						gap: "0.5rem",
						display: "inline-flex",
						justifyContent: "center",
						alignItems: "center",
						borderRadius: "6.25rem",
						minWidth: "unset",
						maxWidth: "unset",
						height: "unset",
						width: "auto",
						color: "white",
						backgroundColor: themePalette?.palette?.primary?.main,
						boxShadow: "0px 0px 0px 4px rgba(255,255,255,0)",
						fontFamily: "PoppinsSemiBold",
						fontSize: "1rem",
						fontStyle: "normal",
						lineHeight: "1.5rem",
						margin: "0",
						"&:hover": {
							backgroundColor: themePalette?.palette?.primary?.dark,
						},
						"&:focus": {
							boxShadow: `0px 0px 0px 4px ${themePalette?.palette?.primary?.light}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
						},
						"&:disabled": {
							opacity: ".5",
							color: "white",
						},
						"& > .MuiButton-startIcon, & > .MuiButton-endIcon": {
							"&, & > img, & > svg": {
								height: "1.25rem",
								width: "1.25rem",
								margin: "0 auto",
							},
						},
					},
				},
				// *** BASE BUTTON SMALL VARIANT *** //
				{
					props: { variant: "DS1", fullWidth: true },
					style: {
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						"& > .MuiButton-startIcon, & > .MuiButton-endIcon": {
							"&, & > img": {
								height: "1.25rem",
								width: "1.25rem",
								margin: "0",
							},
						},
					},
				},
				// *** BASE BUTTON SMALL VARIANT *** //
				{
					props: { variant: "DS1", small: true },
					style: {
						padding: "0.5rem 0.875rem",
						fontSize: "0.875rem",
						lineHeight: "1.25rem",
					},
				},
				// *** BASE BUTTON ICON VARIANT *** //
				{
					props: { variant: "DS1", iconButton: true },
					style: {
						padding: "14px",
						borderRadius: "100px",
					},
				},
				// *** BASE BUTTON SMALL ICON VARIANT *** //
				{
					props: { variant: "DS1", iconButton: true, small: true },
					style: {
						padding: "8px",
						borderRadius: "100px",
					},
				},
				// *** SECONDARY COLOR *** //
				{
					props: { variant: "DS1", color: "secondary" },
					style: {
						backgroundColor: themePalette?.palette?.secondary?.main,
						"&:hover": {
							backgroundColor: themePalette?.palette?.secondary?.dark,
						},
						"&:focus": {
							boxShadow: `0px 0px 0px 4px ${themePalette?.palette?.secondary?.light}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
						},
					},
				},
				// *** TERTIARY COLOR *** //
				{
					props: { variant: "DS1", color: "tertiary" },
					style: {
						backgroundColor: themePalette?.palette?.tertiary?.main,
						"&:hover": {
							backgroundColor: themePalette?.palette?.tertiary?.dark,
						},
						"&:focus": {
							boxShadow: `0px 0px 0px 4px ${themePalette?.palette?.tertiary?.light}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
						},
					},
				},
				// *** DESTRUCTIVE COLOR *** //
				{
					props: { variant: "DS1", color: "destructive" },
					style: {
						backgroundColor: themePalette?.palette?.error?.main,
						color: "white",
						"&:hover": {
							backgroundColor: themePalette?.palette?.error?.dark,
						},
						"&:focus": {
							boxShadow: `0px 0px 0px 4px ${themePalette?.palette?.error?.light}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
						},
						"&:disabled": {
							opacity: ".5",
							color: "white",
						},
					},
				},
				// *** "GRAY" COLOR *** //
				{
					props: { variant: "DS1", color: "gray" },
					style: {
						backgroundColor: themePalette?.palette?.gray?.main,
						color: "#344054",
						"&:hover": {
							backgroundColor: themePalette?.palette?.gray?.dark,
						},
						"&:focus": {
							boxShadow: `0px 0px 0px 4px ${themePalette?.palette?.gray?.light}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
						},
						"&:disabled": {
							opacity: ".5",
							color: "#344054",
						},
					},
				},
				// *** "CLEAR" COLOR *** //
				{
					props: { variant: "DS1", color: "clear" },
					style: {
						backgroundColor: "transparent",
						color: "#667085",
						boxShadow: "none",
						"&:hover": {
							backgroundColor: "#FCFCFD",
						},
						"&:focus": {
							backgroundColor: "#FCFCFD",
							boxShadow: "none",
						},
						"&:disabled": {
							opacity: ".5",
							color: "#667085",
							backgroundColor: "transparent",
						},
					},
				},

				// *** "Salmon" COLOR *** //
				{
					props: { variant: "DS1", color: "salmon" },
					style: {
						backgroundColor: "#EC6C67",
						color: "white",
						"&:hover": {
							backgroundColor: themePalette?.palette?.error?.main,
						},
						"&:focus": {
							boxShadow: `0px 0px 0px 4px ${themePalette?.palette?.error?.light}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
						},
						"&:disabled": {
							opacity: ".5",
							color: "white",
						},
					},
				},

				// --- SUN-SETTING VALUES --- //

				// *** BLACK *** //
				{
					props: { variant: "DS1", color: "black" },
					style: {
						backgroundColor: "black",
						color: "white",
						"&:hover": {
							backgroundColor: "#7B61FF",
						},
						"&:focus": {
							boxShadow: "0px 1px 2px 0px #EAECF0, 0px 0px 0px 4px rgba(16, 24, 40, 0.05)",
						},
						"&:disabled": {
							opacity: ".2",
							color: "white",
						},
					},
				},
				/// --- END DS1 Buttons --- ///

				{
					props: { variant: "contained", color: "primary" },
					style: {
						backgroundColor: themePalette?.palette?.primary?.main,
						"&:hover": {
							backgroundColor: themePalette?.palette?.primary?.dark,
							boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
						},
						"&:focus": {
							backgroundColor: themePalette?.palette?.primary?.main,
							boxShadow: `0px 0px 0px 4px ${themePalette?.palette?.primary?.light}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
						},
						"&:disabled": {
							backgroundColor: themePalette?.palette?.primary?.light,
						},
						"&:active": {
							backgroundColor: themePalette?.palette?.primary?.main,
							boxShadow: "unset",
						},
					},
				},
				{
					props: { variant: "outlined", color: "primary" },
					style: {
						borderColor: themePalette.palette.secondary.light,
						"&:hover": {
							backgroundColor: sosPrimary[25],
						},
						"&:focus": {
							backgroundColor: (theme) => theme.palette.secondary.greyBg,
							borderColor: themePalette.palette.primary.main,
						},
						"&:disabled": {
							backgroundColor: "none",
							borderColor: "#1F1F1F1F",
						},
						"&:active": {
							color: themePalette.palette.secondary.light,
							borderColor: themePalette.palette.secondary.light,
						},
					},
				},
				{
					props: { variant: "nofill", color: "primary" },
					style: {
						color: themePalette.palette.primary.main,
						"&:hover": {
							background: "none",
						},
						"&:focus": {
							backgroundColor: (theme) => theme.palette.secondary.greyBg,
						},
						"&:disabled": {
							backgroundColor: "none",
						},
						"&:active": {
							backgroundColor: (theme) => theme.palette.secondary.greyBg,
						},
					},
				},
				{
					props: { variant: "noOutline" },
					style: {
						color: sosPrimary[500],
						justifyContent: "start",
						boxShadow: "unset",
						"&:hover": {
							background: "none",
							border: "none",
							boxShadow: "unset",
						},
						"&:focus": {
							backgroundColor: "none",
							boxShadow: "unset",
						},
					},
				},
				{
					props: { variant: "text", color: "primary" },
					style: {
						color: themePalette.palette.text.onSurfaceVariant,
						"&:hover": {
							background: "none",
						},
					},
				},

				{
					props: { variant: "containedDark", color: "primary" },
					style: {
						//width: "auto",
						backgroundColor: themePalette.palette.button.main,
						color: themePalette.palette.button.dark,
						"&:hover": {
							backgroundColor: themePalette.palette.button.main,
						},
						"&:focus": {
							backgroundColor: themePalette.palette.button.main,
						},
						"&:disabled": {
							background: "rgba(227, 227, 227, 0.12)",
							color: themePalette.palette.button.light,
						},
						"&:active": {
							backgroundColor: themePalette.palette.button.main,
						},
					},
				},
				{
					props: { variant: "containedLight" },
					style: {
						//width: "auto",
						backgroundColor: sosPrimary[25],
						color: themePalette.palette.text.primary,
						"&:hover": {
							backgroundColor: sosPrimary[25],
						},
						"&:focus": {
							backgroundColor: sosPrimary[25],
						},
						"&:disabled": {
							background: "rgba(227, 227, 227, 0.12)",
							color: themePalette.palette.button.light,
						},
						"&:active": {
							backgroundColor: sosPrimary[25],
						},
					},
				},
				{
					props: { variant: "containedNewStyle" },
					style: {
						//width: "auto",
						backgroundColor: themePalette.palette.secondary.black,
						color: themePalette.palette.secondary.white,
						borderRadius: "50px",
						height: "50px",
						"&:hover": {
							backgroundColor: themePalette.palette.secondary.black,
						},
						"&:focus": {
							backgroundColor: themePalette.palette.secondary.black,
						},
						"&:disabled": {
							backgroundColor: themePalette.palette.secondary.disabledColor,
						},
						"&:active": {
							backgroundColor: themePalette.palette.secondary.black,
						},
					},
				},
				{
					props: { variant: "containedGrayStyle" },
					style: {
						//width: "auto",
						backgroundColor: "#F2F4F7",
						color: "black",
						borderRadius: "50px",
						height: "50px",
						fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
						fontSize: "16px",
						lineHeight: "24px",
					},
				},
				{
					props: { variant: "containedPurpleStyle" },
					style: {
						//width: "auto",

						backgroundColor: themePalette.palette.primary.primaryPurple,
						color: themePalette.palette.secondary.white,
						borderRadius: "50px",
						height: "50px",
						"&:hover": {
							backgroundColor: themePalette.palette.primary.primaryPurple,
						},
						"&:focus": {
							backgroundColor: themePalette.palette.primary.primaryPurple,
						},
						"&:disabled": {
							background: "rgba(227, 227, 227, 0.12)",
							color: themePalette.palette.button.light,
						},
						"&:active": {
							backgroundColor: themePalette.palette.primary.primaryPurple,
						},
						fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
						fontSize: "16px",
						lineHeight: "24px",
					},
				},

				{
					props: { variant: "noOutlineNew" },
					style: {
						color: themePalette.palette.secondary.black,
						justifyContent: "start",
						boxShadow: "unset",
						fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
						fontSize: "16px",
						lineHeight: "24px",
						"&:hover": {
							background: "none",
							border: "none",
							boxShadow: "unset",
						},
						"&:focus": {
							backgroundColor: "none",
							boxShadow: "unset",
						},
					},
				},
				{
					props: { variant: "heroSecondary", color: "primary" },
					style: {
						width: "auto",
						color: sosPrimary[500],
						borderColor: themePalette.palette.button.borderLight,
						borderRadius: (theme) => theme.shape.standard_small.borderRadius,
						borderStyle: "solid",
						borderWidth: "1px",
						backgroundColor: "unset",
						"&:hover": {
							backgroundColor: "rgba(224, 183, 255, 0.08)",
						},
						"&:focus": {
							backgroundColor: "rgba(224, 183, 255, 0.12)",
						},
						"&:active": {
							backgroundColor: "rgba(224, 183, 255, 0.12)",
						},
					},
				},
				{
					props: { variant: "BlackRounded" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "white",
						backgroundColor: "black",
						borderRadius: "100px",
						fontFamily: "PoppinsSemiBold",
						fontSize: "16px",
						lineHeight: "24px",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						border: "4px solid",
						borderColor: "transparent",
						"&:hover": {
							backgroundColor: "white",
							color: "black",
							borderColor: "black",
						},
					},
				},
				{
					props: { variant: "PurpleRounded" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						maxHeight: "48px",
						color: "white",
						backgroundColor: "#7B61FF",
						borderRadius: "100px",
						fontSize: "16px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						border: "4px solid",
						borderColor: "transparent",
						"&:hover": {
							backgroundColor: "white",
							color: "#7B61FF",
							borderColor: "#7B61FF",
						},
					},
				},
				{
					props: { variant: "GreyRounded" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "#344054",
						backgroundColor: "#F2F4F7",
						borderRadius: "100px",
						fontSize: "16px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						border: "4px solid",
						borderColor: "transparent",
						"&:hover": {
							backgroundColor: "#344054",
							color: "#F2F4F7",
							borderColor: "#F2F4F7",
						},
					},
				},
				{
					props: { variant: "PurpleRounded", disabled: true },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "#7B61FF",
						backgroundColor: "#f3f3f3",
						borderRadius: "100px",
						fontSize: "18px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						border: "4px solid",
						borderColor: "transparent",
					},
				},
				{
					props: { variant: "PurpleRoundedOutline" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "#7B61FF",
						backgroundColor: "white",
						borderRadius: "100px",
						fontFamily: "PoppinsSemiBold",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						border: "4px solid",
						borderColor: "#7B61FF",
						fontSize: "18px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
						"&:disabled": {
							backgroundColor: "none",
							borderColor: "rgba(0, 0, 0, 0.09)",
						},
						"&:hover": {
							backgroundColor: "#7B61FF",
							color: "white",
						},
					},
				},
				{
					props: { variant: "RoundedText" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "#7B61FF",
						backgroundColor: "white",
						borderRadius: "100px",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						border: "4px solid",
						borderColor: "transparent",
						"&:hover": {
							backgroundColor: "#7B61FF",
							color: "white",
						},
					},
				},
				{
					props: { variant: "RoundedTextMuted" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "#475467",
						backgroundColor: "white",
						borderRadius: "100px",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						"&:hover": {
							backgroundColor: "#f3f3f3",
						},
						fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
						fontSize: "16px",
						lineHeight: "24px",
						color: "black",
					},
				},

				{
					props: { variant: "RoundedGray" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "#475467",
						backgroundColor: "#f3f3f3",
						borderRadius: "100px",
						padding: "18px 28px",
						height: "auto",
						boxSizing: "border-box",
						fontSize: "18px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
					},
				},
				{
					props: { variant: "RedRounded" },
					style: {
						minWidth: "unset",
						margin: 0,
						width: "auto",
						color: "white",
						backgroundColor: "#D92D20",
						borderRadius: "100px",
						fontSize: "18px",
						lineHeight: "28px",
						fontFamily: "PoppinsSemiBold",
						padding: "12px 24px",
						height: "auto",
						boxSizing: "border-box",
						border: "4px solid",
						borderColor: "transparent",
						"&:hover": {
							backgroundColor: "white",
							color: "#D92D20",
							borderColor: "#D92D20",
						},
					},
				},
				{
					props: { variant: "link", color: "primary" },
					style: {
						borderColor: "none",
						color: themePalette.palette.button.link,
						"&:hover": {
							background: "none",
						},
						"&:focus": {
							backgroundColor: "none",
							borderColor: "none",
						},
						"&:disabled": {
							backgroundColor: "none",
							borderColor: "none",
						},
						"&:active": {
							color: "none",
							borderColor: "none",
						},
					},
				},
				{
					props: { variant: "updatedPrimary" },
					style: {
						//width: "auto",
						backgroundColor: themePalette.palette.text.darkBlue,
						color: themePalette.palette.secondary.white,
						"&:hover": {
							backgroundColor: themePalette.palette.secondary.white,
							color: themePalette.palette.text.darkBlue,
							border: `1px solid ${themePalette.palette.text.darkBlue}`,
						},
						"&:focus": {
							backgroundColor: themePalette.palette.secondary.white,
							color: themePalette.palette.text.darkBlue,
							border: `1px solid ${themePalette.palette.text.darkBlue}`,
						},
						"&:disabled": {
							background: "rgba(227, 227, 227, 0.12)",
							color: themePalette.palette.button.light,
						},
						"&:active": {
							backgroundColor: themePalette.palette.text.darkBlue,
						},
					},
				},
				{
					props: { variant: "styledOutlined" },
					style: {
						backgroundColor: themePalette.palette.secondary.white,
						color: themePalette.palette.secondary.black,
						borderRadius: "50px",
						fontSize: "18px",
						fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',

						height: "60px",

						"&:hover": {
							backgroundColor: themePalette.palette.secondary.white,
							color: themePalette.palette.text.black,
							// border: `1px solid ${themePalette.palette.text.darkBlue}`,
						},
						"&:focus": {
							backgroundColor: themePalette.palette.secondary.white,
							color: themePalette.palette.text.black,
							// border: `1px solid ${themePalette.palette.text.darkBlue}`,
						},
						"&:disabled": {
							background: "rgba(227, 227, 227, 0.12)",
							color: themePalette.palette.button.light,
						},
						"&:active": {
							backgroundColor: themePalette.palette.text.white,
						},
					},
				},
			],
		},
		MuiIconButton: {
			defaultProps: {
				//disableRipple: true,
			},
			styleOverrides: {
				root: {
					textTransform: "none",
				},
			},
			variants: [
				{
					props: { variant: "outlined", color: "secondary" },
					style: {
						border: "2px solid",
						borderColor: sosPrimary[500],
						color: sosPrimary[500],
						"&:hover": {
							backgroundColor: (theme) => theme.palette.secondary.greyBg,
							borderColor: sosPrimary[200],
						},
						"&:focus": {
							backgroundColor: (theme) => theme.palette.secondary.greyBg,
						},
						"&:disabled": {
							backgroundColor: "#1F1F1F1F",
						},
						"&:active": {
							borderColor: sosPrimary[300],
							color: (theme) => theme.palette.secondary.greyBg,
						},
					},
				},
				{
					props: { variant: "contained", color: "secondary" },
					style: {
						"&:hover": {
							backgroundColor: themePalette.palette.secondary.light,
						},
						"&:focus": {
							backgroundColor: themePalette.palette.secondary.light,
						},
						"&:disabled": {
							backgroundColor: themePalette.palette.secondary.light,
						},
						"&:active": {
							backgroundColor: themePalette.palette.secondary.light,
							color: themePalette.palette.secondary.light,
						},
					},
				},
			],
		},
		MuiToggleButtonGroup: {
			variants: [
				{
					props: { variant: "with-gap" },
					style: {
						".MuiToggleButtonGroup-grouped:not(:first-of-type)": {
							borderLeft: `1px solid ${themePalette.palette.secondary.dark}`,
							borderTopRightRadius: "8px",
							borderBottomRightRadius: "8px",
						},
						".MuiToggleButtonGroup-grouped:not(:last-of-type).MuiToggleButtonGroup-grouped:not(:first-of-type)": {
							borderRadius: (theme) => theme.shape.standard_small.borderRadius,
						},
						".MuiToggleButtonGroup-grouped:not(:last-of-type)": {
							borderTopLeftRadius: "8px",
							borderBottomLeftRadius: "8px",
						},
					},
				},
				/// --- DS 1 Buttons --- ///
				{
					props: { variant: "DS1" },
					style: {
						borderRadius: "100px",
						background: "rgba(18, 17, 39, 0.04)",
						padding: "4px",

						".MuiToggleButtonGroup-grouped": {
							border: "0px",
						},
						"& > .MuiToggleButton-root": {
							transition: "all .2s",
							borderRadius: "100px !important",
							fontFamily: "PoppinsSemiBold",
							padding: "6px 16px",
							lineHeight: "180%",
							"&.Mui-selected": {
								backgroundColor: "#FFF",
								color: "black",
								boxShadow: "0px 8px 20px 0px rgba(18, 17, 39, 0.10)",
								"&:hover": {
									backgroundColor: "#FFF",
								},
							},
						},

						//
						//background: var(--black-white-white, #FFF);

						///* Drop Shadow / Low */
						//
						//"& > .MuiToggleButton-root": {
						//	transition: "all .2s",

						//padding: "0.75rem 1.25rem",
						//gap: "0.5rem",
						//display: "inline-flex",
						//justifyContent: "center",
						//alignItems: "center",
						//borderRadius: "6.25rem",
						//minWidth: "unset",
						//maxWidth: "unset",
						//height: "unset",
						//width: "auto",
						//backgroundColor: "#F2F4F7",
						//color: "#344054",
						//fontFamily: "PoppinsSemiBold",
						//fontSize: "1rem",
						//fontStyle: "normal",
						//lineHeight: "1.5rem",
						//margin: "0",
						//"&:hover": {
						//	backgroundColor: "rgba(29, 27, 30, 0.12)",
						//},
						//"&:disabled": {
						//	opacity: ".5",
						//	color: "white",
						//},
						//"& > .MuiSvgIcon-root": {
						//	height: "1.25rem",
						//	width: "1.25rem",
						//	margin: "0 auto",
						//},
						//"&.Mui-selected": {
						//	backgroundColor: "#7B61FF",
						//	color: "white",
						//	"&:hover": {
						//		backgroundColor: "#5644B2",
						//	},
						//},
						//},
					},
				},
				{
					props: { variant: "DS1", small: true },
					style: {
						"& > .MuiToggleButton-root": {
							padding: "0.5rem 0.875rem",
							fontSize: "0.875rem",
							lineHeight: "1.25rem",
						},
					},
				},
				{
					props: { variant: "DS1", colorStyle: "black" },
					style: {
						"& > .MuiToggleButton-root": {
							"&:disabled": {
								opacity: ".2",
								color: "white",
							},
							"&.Mui-selected": {
								backgroundColor: "black",
								color: "white",
								"&:hover": {
									backgroundColor: "#7B61FF",
								},
							},
						},
					},
				},
			],
		},
		MuiToggleButton: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					textTransform: "none",
					borderRadius: (theme) => theme.shape.standard.borderRadius,
				},
				sizeSmall: {
					fontSize: "11px",
					lineHeight: "16px",
					fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
				},
			},
			variants: [
				{
					props: { color: "primary" },
					style: {
						"&.Mui-selected": {
							color: `${sosPrimary[500]}`,
							background: `${sosPrimary[25]}`,
							fontWeight: 500,
							border: `1px solid ${sosPrimary[500]}`,

							"&:hover": {
								backgroundColor: sosPrimary[25],
							},
						},

						"&.Mui-focusVisible": {
							backgroundColor: sosPrimary[25],
						},
						border: `1px solid ${themePalette.palette.secondary.dark}`,
						color: sosPrimary[500],
					},
				},
			],
		},
	},
});
